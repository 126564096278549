import { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";

interface Role {
  id: number;
  name: string;
  users: number;
}

const DeleteRoleModal = (props: any) => {
  const {
    deleteModalOpen = false,
    setDeleteModalOpen = () => {},
    deleteRoleHandler = () => {},
  } = props;
  const role: Role = props.role;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMediumScreen = useMemo<boolean>(() => {
    return windowWidth <= 900;
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Modal
      show={deleteModalOpen}
      className="deactivate-modal"
      centered
      onHide={() => {
        setDeleteModalOpen(!deleteModalOpen);
      }}
    >

      <div className="p-3">
        <div className="flex d-flex justify-content-center mt-2">
          <div style={{backgroundColor: "#FEF3F2", borderRadius:"100%", height:"98px", width:"98px", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <img src="/assets/Icon/Users Delete.svg" alt="user" style={{height:"50px"}} />
          </div>
        </div>

        <div className="flex d-flex justify-content-center mt-3">
          <h6 style={{fontSize:"20px", fontWeight:"500", marginBottom:"0px", textAlign:"center", paddingLeft: isMediumScreen ? 0 : 16, paddingRight: isMediumScreen ? 0 : 16}}>Are you sure you want to delete the <span> {role?.name}?</span>
          </h6>
        </div>
      </div>
      <div className="d-flex justify-content-end generic-modal-footer" style={{gap:"6px"}}>
        <Button
          className="me-2 generic_cancel_button no_button border_radius_8"
          variant="light"
          style={{fontWeight:"450", fontSize:"14px"}}
          onClick={() => {
            setDeleteModalOpen(false);
          }}
        >
          No
        </Button>
        <Button
          variant="primary"
          className="yes_button border_radius_8"
          style={{fontWeight:"450", fontSize:"14px"}}
          onClick={deleteRoleHandler}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteRoleModal;
