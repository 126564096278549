import React, { useEffect, useState } from 'react';
import LogTable from '@/pages/dashboard/logs/(logsList)/components/LogTable/LogTable';
import { useGetActivityLogsMutation } from '@/state/slices/logs/logsApiSlice';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { clearState, updatePage } from '@/state/slices/logs/logsSlice';
import Log from '@/types/Log';
import { useLogsListState } from '../layout';
import useDebounce from '@/utils/hooks/debounce';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

export default function Activity() {
  const location = useLocation();
  const {
    setDateStart,
    setFromDate,
    setToDate,
    setSearchTxt,
    searchTxt,
    fromDate,
    toDate,
    sortingStates,
    setSortingStates,
    triggerApply,
    activityControllerRef: controllerRef,
  } = useLogsListState();
  const debouncedSearchTerm = useDebounce(searchTxt, 400);
  const [getActivityLogs, { data, isError, isLoading, error }] =
    useGetActivityLogsMutation();
  const [loadMoreTrigger, setLoadMoreTrigger] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const dispatch = useAppDispatch();
  const logsState = useAppSelector((state) => state.logs);
  const { logs: logsData, page } = logsState;
  const navigate = useNavigate();
  const [logData, setLogData] = useState<any>([]);

  // clear search when refresh
  useEffect(() => {
    return () => {
      setSearchTxt('');
    }
  }, [])

  // handle get logs data when first load page
  useEffect(() => {
    if (firstLoad && controllerRef?.current && !isLoading) {
      autoPopulateFilter();
    }
  }, [location.search, controllerRef?.current]);

  // handle clear data when change screen
  useEffect(() => {
    dispatch(clearState());

    // clear data when screen is closed
    return () => {
      dispatch(clearState());
      setLogData([]);
    };
  }, [location.pathname]);

  // handle filter
  useEffect(() => {
    if (!firstLoad && !isLoading) {
      const payload = {
        page: 1,
        keyword: searchTxt,
        from: fromDate ? moment(fromDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        to: toDate ? moment(toDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        sort_by: sortingStates.sortBy,
        sort_order: sortingStates.isAsc === null ? '' : sortingStates.isAsc ? 'ASC' : 'DESC',
      }
      dispatch(clearState());
      setLogData([]);
      updateURL({
        ...payload,
        from: fromDate,
        to: toDate,
      }, true);
    }
  }, [fromDate, toDate, triggerApply, debouncedSearchTerm]);

  // handle load data when scrolling
  useEffect(() => {
    if (!logsState.lastPageReached && loadMoreTrigger && !isLoading && !firstLoad) {
      const payload = {
        page: Number(getQueryParam('page', page)) + 1,
        keyword: searchTxt,
        from: fromDate ? moment(fromDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        to: toDate ? moment(toDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        sort_by: sortingStates.sortBy,
        sort_order: sortingStates.isAsc === null ? '' : sortingStates.isAsc ? 'ASC' : 'DESC',
      }
      getActivityLogs({...payload, signal: controllerRef?.current?.signal}).then((result) => {
        if ('data' in result) {
          updateURL({
            ...payload,
            from: fromDate,
            to: toDate,
          }, false);

          setLoadMoreTrigger(false)
        }
      })
    }
  }, [loadMoreTrigger]);

  // handle get logs
  const getLogsData = () => {
    let from = getQueryParam('from', '') as string;
    let to  = getQueryParam('to', '') as string;
    let startDate, endDate, startDateUtc, endDateUtc;

    if (from !== '' && to !== '') {
      startDate = new Date(decodeURIComponent(from));
      endDate = new Date(decodeURIComponent(to));
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      startDateUtc = moment(startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      endDateUtc = moment(endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
    } else {
      startDateUtc = '';
      endDateUtc = '';
    }

    const pageParams = Number(getQueryParam('page', 1));

    const payload = {
      page: 1,
      keyword: getQueryParam('keyword', '') as string,
      from: startDateUtc,
      to: endDateUtc,
      sort_by: getQueryParam('sort_by', '') as string,
      sort_order: getQueryParam('sort_order', '') as string,
      per_page: 50 * pageParams,
      signal: controllerRef?.current?.signal,
    };

    dispatch(clearState());
    setLogData([]);

    getActivityLogs(payload).then((result) => {
      if ('data' in result) {
        dispatch(updatePage({page: pageParams}));
      }
    }).finally(() => {
      setFirstLoad(false);
    });
  };

  // handle sort data
  const getLogsSortBy = (stateData: { sortBy: string; isAsc: boolean }) => {
    if (!firstLoad) {
      dispatch(clearState());
      setLogData([]);
      setSortingStates(stateData);
      const payload = {
        page: 1,
        keyword: debouncedSearchTerm,
        from: fromDate ? moment(fromDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        to: toDate ? moment(toDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        sort_by: stateData.sortBy,
        sort_order: stateData.isAsc ? 'ASC' : 'DESC',
      };
      updateURL({
        ...payload,
        from: fromDate,
        to: toDate,
      }, true);
    }
  };

  // when data is loaded, then pass to variable
  useEffect(() => {
    if (logsData.length > 0) {
      setLogData([...logsData]);
    }
  }, [logsData]);

  // get params
  const getQueryParam = (param: string, defaultValue: string | number): string | number => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.has(param) ? urlParams.get(param)! : defaultValue;
  }

  // autoPopulate filter
  const autoPopulateFilter = () => {
    let queryParams;

    // check if have filter data on session
    if (getFilterData() !== null) {
      const queryString = getFilterData();
      const params = new URLSearchParams(queryString);
    
      const paramsObject: { [key: string]: any } = {};
      params.forEach((value, key) => {
        if (key === 'page') {
          paramsObject[key] = Number(value);
        } else {
          paramsObject[key] = value;
        }
      });
      queryParams = paramsObject;

      // remove filter data from session
      sessionStorage.removeItem('logActivitiesFilterData');

      // update url then refresh page with filter
      return updateURL(queryParams, true);
    }

    queryParams = {
      page: Number(getQueryParam('page', 1)),
      keyword: getQueryParam('keyword', '') as string,
      from: getQueryParam('from', '') as string,
      to: getQueryParam('to', '') as string,
      sort_by: getQueryParam('sort_by', '') as string,
      sort_order: getQueryParam('sort_order', '') as string,
    };

    if (queryParams.keyword !== '') {
      setSearchTxt(queryParams.keyword)
    }

    if (queryParams.sort_by !== '') {
      setSortingStates({
        sortBy: queryParams.sort_by, 
        isAsc: queryParams.sort_order === '' ? null : queryParams.sort_order === 'ASC' ? true : false
      })
    }
    if (queryParams.to !== '' && queryParams.from !== '') {
      let startDate = new Date(decodeURIComponent(queryParams.from));
      let endDate  = new Date(decodeURIComponent(queryParams.to));

      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(0, 0, 0, 0)

      setDateStart([{
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      }])

      setFromDate(queryParams.from);
      setToDate(queryParams.to);
    }

    setTimeout(() => {
      getLogsData();
    }, 200)
  }

  // update url
  const updateURL = (queryParams: any, isReload: boolean) => {
    const newUrl = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        newUrl.set(key, value.toString());
      }
    });

    setFirstLoad(isReload);
    navigate(`/dashboard/logs/activity?${newUrl.toString()}`, { replace: true });
  }

  // save filter data to sessionStorage
  const saveFilterData = (key:string) => {
    const queryParams = {
      page: page,
      keyword: debouncedSearchTerm,
      from: fromDate ? fromDate : '',
      to: toDate ? toDate : '',
      sort_by: sortingStates.sortBy,
      sort_order: sortingStates.isAsc === null ? '' : sortingStates.isAsc ? 'ASC' : 'DESC',
    };

    const newUrl = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        newUrl.set(key, value.toString());
      }
    });

    sessionStorage.setItem(`log${key}FilterData`, JSON.stringify(newUrl.toString()));
  }

  // get filter data from sessionStorage
  const getFilterData = () => {
    const filterData = sessionStorage.getItem('logActivitiesFilterData');
    return filterData ? JSON.parse(filterData) : null;
  }

  return (
    <>
      <div>
        <LogTable
          logs={logData ?? []}
          loading={isLoading}
          loadMoreLogs={setLoadMoreTrigger}
          getLogsSortBy={getLogsSortBy}
        />
      </div> 
    </>
  );
}
