import React, {useEffect, useState} from "react";
import "./settings-wrapper.scss";
import {NavLink, Outlet, useLocation, useOutletContext} from "react-router-dom";
import {useSetOpenNav} from "../..";
import {ReactComponent as BurgerSvg} from "@/assets/svgs/burger.svg";
import {useGetApiKey} from "@/api/fetchers/useGetApiKey";
import {useGetVivwCredits} from "@/api/fetchers/useGetVivwCredits";

export type SettingsContextType = {
    isAuthenticated: boolean;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    dataApi: any;
    errorApi: any | null;
    isLoadingApi: boolean;
    fetchApiKey: (email: string, password: string) => Promise<any>;
    resetApiKey: () => Promise<any>;
    countCredit: number;
};

export default function SettingsWrapper() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const {dataApi, errorApi, isLoadingApi, fetchApiKey, resetApiKey} = useGetApiKey();
    const {credit} = useGetVivwCredits();
    const location = useLocation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isApi = location.pathname === "/dashboard/settings/api_key";

    const countCredit = credit?.sms_credit;

    const tabs = [
        {
            name: location.pathname === "/dashboard/settings/api_key" && (!isAuthenticated ? "API Authentication" : "API Key"),
            link: "/dashboard/settings/api_key"
        },
        {
            name: location.pathname === "/dashboard/settings/sms" && "SMS Notification Settings",
            link: "/dashboard/settings/sms"
        },
    ];

    const {setOpenNav} = useSetOpenNav();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="settings-wrapper-component">
            <div className="nav-header">
                <div className="right-bar bg-pure-white">
                    <div className="mobileBarSettings">
                        <BurgerSvg className="burger" onClick={() => setOpenNav(true)}/>
                        {tabs.map((tab, index) => (
                            <NavLink
                                to={tab.link}
                                key={index}
                                className={"text-decoration-none nav-link-cont"}
                            >
                                <li className={`navbar-link`}>
                                    <span className="nav-name">{tab.name}</span>
                                </li>
                            </NavLink>
                        ))}
                    </div>
                </div>
                {location.pathname === "/dashboard/settings/sms" && (
                    <div className="employee-right-bar desktop-only">
                        <div className="d-flex align-items-center">
                            <span
                                className="input-field-ti"
                                style={{color: credit?.sms_credit === 0 ? 'red' : '#667085'}}
                            >
                                {credit?.sms_credit} Credits Remaining
                            </span>
                        </div>
                    </div>
                )}
            </div>
            <div className="settings-wrapper-content bg-pure-white">
                <Outlet
                    context={{
                        isAuthenticated,
                        setIsAuthenticated,
                        dataApi,
                        errorApi,
                        isLoadingApi,
                        fetchApiKey,
                        resetApiKey,
                        countCredit
                    }}
                />
            </div>
        </div>
    );
}

export function useSettingsWrapperState() {
    return useOutletContext<SettingsContextType>();
}
