// import Invoice from "@/types/Invoice";
// import InvoiceTable from "@/pages/dashboard/invoices/(invoicesList)/components/invoiceTable/InvoiceTable";
// import React, { useState } from "react";

// const initialData: Invoice[] = [
//   {
//     id: 1,
//     invoice_number: "AA",
//     invoice_date: "20 Jan, 2023",
//     buyer_name: "AMoonstone Ventures LLP",
//     buyer_address: "test",
//     contact_number: "00",
//     tally_company: "SS Sales",
//     invoice_amount: "8729.00",
//     created_at: "",
//     updated_at: "",
//     items: [],
//     company_name:"",
//     due: ""
//   },
//   {
//     id: 1,
//     invoice_number: "AB",
//     invoice_date: "20 Jan, 2023",
//     buyer_name: "BMoonstone Ventures LLP",
//     buyer_address: "test",
//     contact_number: "00",
//     tally_company: "SS Sales",
//     invoice_amount: "7729.00",
//     created_at: "",
//     updated_at: "",
//     items: [],
//     company_name:"",
//     due: ""
//   },
//   {
//     id: 1,
//     invoice_number: "AB",
//     invoice_date: "20 Jan, 2023",
//     buyer_name: "CMoonstone Ventures LLP",
//     buyer_address: "test",
//     contact_number: "00",
//     tally_company: "SS Sales",
//     invoice_amount: "6729.00",
//     created_at: "",
//     updated_at: "",
//     items: [],
//     company_name:"",
//     due: ""
//   },
//   {
//     id: 1,
//     invoice_number: "AA",
//     invoice_date: "20 Jan, 2023",
//     buyer_name: "Moonstone Ventures LLP",
//     buyer_address: "test",
//     contact_number: "00",
//     tally_company: "SS Sales",
//     invoice_amount: "8729.00",
//     created_at: "",
//     updated_at: "",
//     items: [],
//     company_name:"",
//     due: ""
//   },
// ];

// export default function Completed() {
//   const [invoices, setInvoices] = useState<Invoice[]>(initialData);
//   return (
//     <>
//       <div className="">
//         <InvoiceTable
//           invoices={invoices}
//           setInvoices={setInvoices}
//           showCheckbox={false}
//         />
//       </div>
//     </>
//   );
// }

















import { useLogoutMutation } from "@/state/slices/authApiSlice";
import { useGetInvoicesMutation } from "@/state/slices/invoices/invoicesApiSlice";
import Invoice from "@/types/Invoice";
import InvoiceTable from "@/pages/dashboard/invoices/(invoicesList)/components/invoiceTable/InvoiceTable";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useInvoicesListState } from "../layout";
import useDebounce from "@/utils/hooks/debounce";
import { clearState, setInvoices, updatePage } from "@/state/slices/invoices/invoicesSlice";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { set } from "react-hook-form";
import moment from "moment";

export default function Completed() {
  const { 
    searchTxt, 
    fromDate, 
    toDate,
    setSearchTxt,
    setDateStart,
    setFromDate,
    setToDate,
    completedControllerRef: controllerRef,
   } = useInvoicesListState();

  const debouncedSearchTerm = useDebounce(searchTxt, 400);
  const invoicesState = useAppSelector((state) => state.invoices);
  const invoices = invoicesState.invoices;
  const page = invoicesState.page;

  const [getInvoices, { data, isError, isLoading, error }] =
    useGetInvoicesMutation();
  const [loadMoreTrigger, setLoadMoreTrigger] = useState(false);
  const [completedInvoices, setCompletedInvoices] = useState<any>([])
  const [checkedData, setCheckedData] = useState<Invoice[]>([]);
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [firstLoad, setFirstLoad] = useState(true);
  const location = useLocation();
  const [stateDataSorting, setStateDataSorting] = useState<{sortBy: string, isAsc: boolean | null}>({ sortBy: "invoice_number", isAsc: null })

  // clear search when refresh
  useEffect(() => {
    return () => {
      setSearchTxt('');
    }
  }, [])

  // handle get invoices data when first load page
  useEffect(() => {
    if (firstLoad && controllerRef?.current && !isLoading) {
      autoPopulateFilter();
    }
  }, [location.search, controllerRef?.current]);

  // handle clear data when change screen
  useEffect(() => {
    dispatch(clearState());

    // clear data when screen is closed
    return () => {
      dispatch(clearState());
      setCompletedInvoices([]);
    };
  }, [location.pathname]);

  // handle filter
  useEffect(() => {
    if (!firstLoad) {
      const payload = {
        page: 1,
        status: 4,
        keyword: searchTxt,
        from: fromDate ? moment(fromDate).startOf('day').format('YYYY-MM-DD') : '',
        to: toDate ? moment(toDate).endOf('day').format('YYYY-MM-DD') : '',
        sort_by: stateDataSorting.sortBy,
        sort_order: stateDataSorting.isAsc === null ? '' : stateDataSorting.isAsc ? 'ASC' : 'DESC',
      }
      dispatch(clearState());
      setCompletedInvoices([]);
      updateURL({
        ...payload,
        from: fromDate,
        to: toDate,
      }, true);
    }
  }, [fromDate, toDate, debouncedSearchTerm]);

  // handle load data when scrolling
  useEffect(() => {
    if (!invoicesState.lastPageReached && loadMoreTrigger && !isLoading && !firstLoad) {
      const payload = {
        page: Number(getQueryParam('page', page)) + 1,
        status: 4,
        keyword: searchTxt,
        from: fromDate ? moment(fromDate).startOf('day').format('YYYY-MM-DD') : '',
        to: toDate ? moment(toDate).endOf('day').format('YYYY-MM-DD') : '',
        sort_by: stateDataSorting.sortBy,
        sort_order: stateDataSorting.isAsc == null ? '' : stateDataSorting.isAsc ? "ASC" : 'DESC',
      }
      getInvoices({...payload, signal: controllerRef?.current?.signal}).then((result) => {
        if ('data' in result) {
          updateURL({
            ...payload,
            from: fromDate,
            to: toDate,
          }, false);

          setLoadMoreTrigger(false);
        }
      })
    }
  }, [loadMoreTrigger]);

  // handle get invoices
  const getInvoicesData = () => {
    let fromDate = getQueryParam('from', '') as string;
    let toDate  = getQueryParam('to', '') as string;
    let sortBy = getQueryParam('sort_by', 'invoice_number') as string;
    let startDate, endDate, startDateUtc, endDateUtc;

    if (fromDate !== '' && toDate !== '') {
      startDate = new Date(decodeURIComponent(fromDate));
      endDate = new Date(decodeURIComponent(toDate));
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      startDateUtc = sortBy === 'invoice_date' ? moment(startDate).startOf('day').format('YYYY-MM-DD') : moment(startDate).startOf('day').format('YYYY-MM-DD');
      endDateUtc = sortBy === 'invoice_date' ? moment(endDate).endOf('day').format('YYYY-MM-DD') : moment(endDate).endOf('day').format('YYYY-MM-DD');
    } else {
      startDateUtc = '';
      endDateUtc = '';
    }
    
    const pageParams = Number(getQueryParam('page', 1));

    const payload = {
      page: 1,
      status: 4,
      keyword: getQueryParam('keyword', '') as string,
      from: startDateUtc,
      to: endDateUtc,
      sort_by: getQueryParam('sort_by', 'invoice_number') as string,
      sort_order: getQueryParam('sort_order', '') as string,
      per_page: 20 * pageParams,
      signal: controllerRef?.current?.signal,
    };
    
    dispatch(clearState());
    setCompletedInvoices([]);

    getInvoices(payload).then((result) => {
      if ('data' in result) {
        dispatch(updatePage({page: pageParams}));
      }
    }).finally(() => {
      setFirstLoad(false);
    });
  }

  // handle sort data
  const getinvoicesSortBy = (stateData: { sortBy: string; isAsc: boolean }) => {
    if (!firstLoad) {
      dispatch(clearState());
      setCompletedInvoices([]);
      setStateDataSorting(stateData);
      const payload = {
        page: 1,
        status: 4,
        keyword: debouncedSearchTerm,
        from: fromDate ? moment(fromDate).startOf('day').format('YYYY-MM-DD') : '',
        to: toDate ? moment(toDate).endOf('day').format('YYYY-MM-DD') : '',
        sort_by: stateData.sortBy,
        sort_order: stateData.isAsc ? 'ASC' : 'DESC',
      };
      updateURL({
        ...payload,
        from: fromDate,
        to: toDate,
      }, true);
    }
  };

  // when data is loaded, then pass to variable
  useEffect(() => {
    if (invoices.length > 0) {
      setCompletedInvoices([...invoices]);
    }
  }, [invoices]);

  // get params
  const getQueryParam = (param: string, defaultValue: string | number): string | number => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.has(param) ? urlParams.get(param)! : defaultValue;
  }

  // autoPopulate filter
  const autoPopulateFilter = () => {
    let queryParams;

    // check if have filter data on session
    if (getFilterData() !== null) {
      const queryString = getFilterData();
      const params = new URLSearchParams(queryString);
    
      const paramsObject: { [key: string]: any } = {};
      params.forEach((value, key) => {
      if (key === 'page' || key === 'status') {
        paramsObject[key] = Number(value);
      } else {
        paramsObject[key] = value;
      }
    });
      queryParams = paramsObject;

      // remove filter data from session
      sessionStorage.removeItem('invoiceCompletedFilterData');

      // update url then refresh page with filter
      setTimeout(() => {
        return updateURL(paramsObject, true);
      }, 100)
    }

    queryParams = {
      page: Number(getQueryParam('page', 1)),
      keyword: getQueryParam('keyword', '') as string,
      from: getQueryParam('from', '') as string,
      to: getQueryParam('to', '') as string,
      sort_by: getQueryParam('sort_by', '') as string,
      sort_order: getQueryParam('sort_order', '') as string,
    };
    
    if (queryParams.keyword !== '') {
      setSearchTxt(queryParams.keyword)
    }
    if (queryParams.sort_by !== '') {
      setStateDataSorting({
        sortBy: queryParams.sort_by, 
        isAsc: queryParams.sort_order === '' ? null : queryParams.sort_order === 'ASC' ? true : false
      })
    }
    if (queryParams.to !== '' && queryParams.from !== '') {
      let startDate = new Date(decodeURIComponent(queryParams.from));
      let endDate  = new Date(decodeURIComponent(queryParams.to));

      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(0, 0, 0, 0)

      setDateStart([{
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      }])

      setFromDate(queryParams.from);
      setToDate(queryParams.to);
    }

    getInvoicesData();
  }

  // update url
  const updateURL = (queryParams: any, isReload: boolean) => {
    const newUrl = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        newUrl.set(key, value.toString());
      }
    });

    setFirstLoad(isReload);
    navigate(`/dashboard/invoices/completed?${newUrl.toString()}`, { replace: true });
  }

  // save filter data to sessionStorage
  const saveFilterData = (invoiceKey:string) => {
    const queryParams = {
      page: Number(getQueryParam('page', page)),
      keyword: debouncedSearchTerm,
      from: fromDate ? fromDate : '',
      to: toDate ? toDate : '',
      sort_by: stateDataSorting.sortBy,
      sort_order: stateDataSorting.isAsc === null ? '' : stateDataSorting.isAsc ? 'ASC' : 'DESC',
    };

    const newUrl = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        newUrl.set(key, value.toString());
      }
    });
    
    // clear current list
    dispatch(clearState());
    setCompletedInvoices([]);
    controllerRef?.current?.abort();
    
    sessionStorage.setItem(`invoice${invoiceKey}FilterData`, JSON.stringify(newUrl.toString()));
  }

  // get filter data from sessionStorage
  const getFilterData = () => {
    const filterData = sessionStorage.getItem('invoiceCompletedFilterData');
    return filterData ? JSON.parse(filterData) : null;
  }

  useEffect(() => {
    if (isError && error && "data" in error && error?.status === 403) {
      logout();
      navigate("/");
    }
  }, [isError]);

  useEffect(() => {
    if (invoices.length) {
      const scrollPosition = localStorage.getItem('scrollPosition');
      // // console.log(scrollPosition);
      if (scrollPosition) {
        window.scrollTo({ top: parseInt(scrollPosition) });
        //localStorage.removeItem('scrollPosition');
      }
    }
  }, [invoices]);

  return (
    <>
      <div className="">
        <InvoiceTable
          invoices={[...checkedData, ...(completedInvoices ?? [])].filter((obj, index, self) =>
            index === self.findIndex((t) => (
              t.id === obj.id
            ))
          )}
          setCheckedData={setCheckedData}
          checkedData={checkedData}
          setInvoices={(i) => dispatch(setInvoices(i))}
          showCheckbox={true}
          loading={isLoading}
          loadMoreInvoices={setLoadMoreTrigger}
          getinvoicesSortBy={getinvoicesSortBy}
          handleDetail={saveFilterData}
        />
      </div>
    </>
  );
}
