import { useLogoutMutation } from "@/state/slices/authApiSlice";
import { useDeleteInvoicesMutation, useGetInvoicesMutation } from "@/state/slices/invoices/invoicesApiSlice";
import Invoice from "@/types/Invoice";
import InvoiceTable from "@/pages/dashboard/invoices/(invoicesList)/components/invoiceTable/InvoiceTable";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useInvoicesListState } from "../layout";
import useDebounce from "@/utils/hooks/debounce";
import { clearState, setInvoices, updatePage } from "@/state/slices/invoices/invoicesSlice";
import { ReactComponent as CircleRed } from "@/assets/svgs/delete-circle.svg";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { set } from "react-hook-form";
import { boolean } from "yup";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";

export default function InvoiceDeliveries() {
  const { searchTxt, 
    setSearchTxt, 
    fromDate, 
    toDate, 
    deleteInvoice, 
    setDeleteInvoice, 
    setShowDelete, 
    showDelete,
    setDateStart,
    setFromDate,
    setToDate,
    deliveriesControllerRef: controllerRef,
  } = useInvoicesListState();
  const [deleteInvoices, { data: invoiceData, error: invoiceError }]: any = useDeleteInvoicesMutation()

  const debouncedSearchTerm = useDebounce(searchTxt, 400);
  const handleCloseDelete = () => { setShowDelete(false); setDeleteInvoice(false);};
  const invoicesState = useAppSelector((state) => state.invoices);
  const invoices = invoicesState.invoices;
  const page = invoicesState.page;

  const [getInvoices, { data, isError, isLoading, error }] =
    useGetInvoicesMutation();
  const [loadMoreTrigger, setLoadMoreTrigger] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [checkedData, setCheckedData] = useState<Invoice[]>([]);
  const [deliveryInvoice, setDeliveryInvoice] = useState<any>([])
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [firstLoad, setFirstLoad] = useState(true);
  const [stateDataSorting, setStateDataSorting] = useState<{sortBy: string, isAsc: boolean | null}>({ sortBy: "invoice_number", isAsc: null })

  // clear search when refresh
  useEffect(() => {
    return () => {
      setSearchTxt('');
    }
  }, [])
  
  // handle get invoices data when first load page
  useEffect(() => {
      // console.log('autoo', {
      //   'firstLoad': firstLoad,
      //   'controllerRef?.current': controllerRef?.current,
      //   'isLoading': isLoading
      // })
    if (firstLoad && controllerRef?.current && !isLoading) {
      autoPopulateFilter();
    }
  }, [location.search, controllerRef?.current]); 

  // handle clear data when change screen
  useEffect(() => {
    dispatch(clearState());

    // clear data when screen is closed
    return () => {
      dispatch(clearState());
      setDeliveryInvoice([]);
    };
  }, [location.pathname]);

  // handle filter
  useEffect(() => {
    if (!firstLoad && !isLoading) {
      const payload = {
        page: 1,
        status: 1,
        keyword: searchTxt,
        from: fromDate ? moment(fromDate).startOf('day').format('YYYY-MM-DD') : '',
        to: toDate ? moment(toDate).endOf('day').format('YYYY-MM-DD') : '',
        sort_by: stateDataSorting.sortBy,
        sort_order: stateDataSorting.isAsc === null ? '' : stateDataSorting.isAsc ? 'ASC' : 'DESC',
      }
      dispatch(clearState());
      setDeliveryInvoice([]);
      updateURL({
        ...payload,
        from: fromDate,
        to: toDate,
      }, true);
    }
  }, [fromDate, toDate, debouncedSearchTerm]);

  // handle load data when scrolling
  useEffect(() => {
    if (!invoicesState.lastPageReached && loadMoreTrigger && !isLoading && !firstLoad) {
      const payload = {
        page: Number(getQueryParam('page', page)) + 1,
        status: 1,
        keyword: searchTxt,
        from: fromDate ? moment(fromDate).startOf('day').format('YYYY-MM-DD') : '',
        to: toDate ? moment(toDate).endOf('day').format('YYYY-MM-DD') : '',
        sort_by: stateDataSorting.sortBy,
        sort_order: stateDataSorting.isAsc == null ? '' : stateDataSorting.isAsc ? "ASC" : 'DESC',
      }
      getInvoices({...payload, signal: controllerRef?.current?.signal}).then((result) => {
        if ('data' in result) {
          updateURL({
            ...payload,
            from: fromDate,
            to: toDate,
          }, false);

          setLoadMoreTrigger(false);
        }
      })
    }
  }, [loadMoreTrigger]);

  // handle get invoices
  const getInvoicesData = () => {
    let fromDate = getQueryParam('from', '') as string;
    let toDate  = getQueryParam('to', '') as string;
    let sortBy = getQueryParam('sort_by', 'invoice_number') as string;
    let startDate, endDate, startDateUtc, endDateUtc;

    if (fromDate !== '' && toDate !== '') {
      startDate = new Date(decodeURIComponent(fromDate));
      endDate = new Date(decodeURIComponent(toDate));
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      startDateUtc = sortBy === 'invoice_date' ? moment(startDate).startOf('day').format('YYYY-MM-DD') : moment(startDate).startOf('day').format('YYYY-MM-DD');
      endDateUtc = sortBy === 'invoice_date' ? moment(endDate).endOf('day').format('YYYY-MM-DD') : moment(endDate).endOf('day').format('YYYY-MM-DD');
    } else {
      startDateUtc = '';
      endDateUtc = '';
    }
    
    const pageParams = Number(getQueryParam('page', 1));

    const payload = {
      page: 1,
      status: 1,
      keyword: getQueryParam('keyword', '') as string,
      from: startDateUtc,
      to: endDateUtc,
      sort_by: getQueryParam('sort_by', 'invoice_number') as string,
      sort_order: getQueryParam('sort_order', '') as string,
      per_page: 20 * pageParams,
      signal: controllerRef?.current?.signal,
    };

    dispatch(clearState());
    setDeliveryInvoice([]);
    
    getInvoices(payload).then((result) => {
      if ('data' in result) {
        dispatch(updatePage({page: pageParams}));
      }
    }).finally(() => {
      setFirstLoad(false);
    });
  }

  // handle sort data
  const getinvoicesSortBy = (stateData: { sortBy: string; isAsc: boolean }) => {
    if (!firstLoad) {
      dispatch(clearState());
      setDeliveryInvoice([]);
      setStateDataSorting(stateData);
      const payload = {
        page: 1,
        status: 1,
        keyword: debouncedSearchTerm,
        from: fromDate ? moment(fromDate).startOf('day').format('YYYY-MM-DD') : '',
        to: toDate ? moment(toDate).endOf('day').format('YYYY-MM-DD') : '',
        sort_by: stateData.sortBy,
        sort_order: stateData.isAsc ? 'ASC' : 'DESC',
      };
      updateURL({
        ...payload,
        from: fromDate,
        to: toDate,
      }, true);
    }
  };

  // when data is loaded, then pass to variable
  useEffect(() => {
    if (invoices.length > 0) {
      setDeliveryInvoice([...invoices]);
    }
  }, [invoices]);

  // get params
  const getQueryParam = (param: string, defaultValue: string | number): string | number => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.has(param) ? urlParams.get(param)! : defaultValue;
  }

  // autoPopulate filter
  const autoPopulateFilter = () => {
    let queryParams;

    // check if have filter data on session
    if (getFilterData() !== null) {
      const queryString = getFilterData();
      const params = new URLSearchParams(queryString);
    
      const paramsObject: { [key: string]: any } = {};
      params.forEach((value, key) => {
      if (key === 'page' || key === 'status') {
        paramsObject[key] = Number(value);
      } else {
        paramsObject[key] = value;
      }
      });
      queryParams = paramsObject;

      // remove filter data from session
      sessionStorage.removeItem('invoiceDeliveriesFilterData');

      // update url then refresh page with filter
      setTimeout(() => {
        return updateURL(paramsObject, true);
      }, 100)
    }

    queryParams = {
      page: Number(getQueryParam('page', 1)),
      keyword: getQueryParam('keyword', '') as string,
      from: getQueryParam('from', '') as string,
      to: getQueryParam('to', '') as string,
      sort_by: getQueryParam('sort_by', '') as string,
      sort_order: getQueryParam('sort_order', '') as string,
    };

    if (queryParams.keyword !== '') {
      setSearchTxt(queryParams.keyword)
    }
    if (queryParams.sort_by !== '') {
      setStateDataSorting({
        sortBy: queryParams.sort_by, 
        isAsc: queryParams.sort_order === '' ? null : queryParams.sort_order === 'ASC' ? true : false
      })
    }
    if (queryParams.to !== '' && queryParams.from !== '') {
      let startDate = new Date(decodeURIComponent(queryParams.from));
      let endDate  = new Date(decodeURIComponent(queryParams.to));

      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(0, 0, 0, 0)

      setDateStart([{
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      }])

      setFromDate(queryParams.from);
      setToDate(queryParams.to);
    }

    setTimeout(() => {
      getInvoicesData();
    }, 300)
  }

  // update url
  const updateURL = (queryParams: any, isReload: boolean) => {
    const newUrl = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        newUrl.set(key, value.toString());
      }
    });

    setFirstLoad(isReload);
    navigate(`/dashboard/invoices/deliveries?${newUrl.toString()}`, { replace: true });
  }

  // save filter data to sessionStorage
  const saveFilterData = (invoiceKey:string) => {
    const queryParams = {
      page: Number(getQueryParam('page', page)),
      keyword: debouncedSearchTerm,
      from: fromDate ? fromDate : '',
      to: toDate ? toDate : '',
      sort_by: stateDataSorting.sortBy,
      sort_order: stateDataSorting.isAsc === null ? '' : stateDataSorting.isAsc ? 'ASC' : 'DESC',
    };

    const newUrl = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        newUrl.set(key, value.toString());
      }
    });

    // clear current list
    dispatch(clearState());
    setDeliveryInvoice([]);
    controllerRef?.current?.abort();
    
    sessionStorage.setItem(`invoice${invoiceKey}FilterData`, JSON.stringify(newUrl.toString()));
  }

  // get filter data from sessionStorage
  const getFilterData = () => {
    const filterData = sessionStorage.getItem('invoiceDeliveriesFilterData');
    return filterData ? JSON.parse(filterData) : null;
  }

  useEffect(() => {
    if (isError && error && "data" in error && error?.status === 403) {
      logout();
      navigate("/");
    }
  }, [isError]);

  useEffect(() => {
    if (invoices.length) {
      const scrollPosition = localStorage.getItem('scrollPosition');
      // console.log(scrollPosition);
      if (scrollPosition) {
        window.scrollTo({ top: parseInt(scrollPosition) });
        //localStorage.removeItem('scrollPosition');
      }
    }
  }, [invoices]);

  const { setBatchType } = useInvoicesListState();

  useEffect(() => {
    setBatchType(1)
  }, [setBatchType])

  // const handleDelete = async () => {
  //   console.log('checkedData', checkedData)
  //   if(!checkedData?.length) {
  //     alert('Please select at least one invoice')
  //     return setShowDelete(false);
  //   }
  //   const { data, error }: any = await deleteInvoices(checkedData?.map((item) => item?.id));
  
  //   if (data?.message) {
  //     setCheckedData([]);
  //     dispatch(clearState());
  //     getInvoices({
  //       keyword: debouncedSearchTerm,
  //       page: 1,
  //       from: fromDate,
  //       to: toDate
  //     });
  //     setShowDelete(false);
  //   }
  //   if (error?.data?.message) {
  //     alert(error?.data?.message);
  //     setShowDelete(false);
  //   }
  
  //   setDeleteInvoice(false);
  // };
  
  // useEffect(() => {
  //   if (deleteInvoice) {
  //     handleDelete();
  //   }
  // }, [deleteInvoice]);

  useEffect(() => {
    if(deleteInvoice) {
      if(!checkedData?.length) {
        alert('Please select at least one invoice')
        setDeleteInvoice(false)
        return setShowDelete(false);
      } else {
        setShowDelete(true)
      }
    }
  },[deleteInvoice])
  
  // handleDeleteInvoices()
  const handleDeleteInvoices = async () => {
    if(checkedData?.length) {
      const { data, error }: any = await deleteInvoices(checkedData?.map((item) => item?.id));
        if (data?.message) {
          setSearchTxt("")
          dispatch(clearState());
          const { data : { data } }: any = await getInvoices({
            keyword: debouncedSearchTerm,
            page: 1,
            from: fromDate,
            to: toDate
          });
          setDeliveryInvoice(data)
          setShowDelete(false);
          
        }
        if (error?.data?.message) {
          alert(error?.data?.message);
          setShowDelete(false);
        }
        setSelectAllChecked(false)
        setCheckedData([]);          
        setDeleteInvoice(false);
    }
  }

  return (
    <>
      <div className="">
        <InvoiceTable
          invoices={[...checkedData, ...(deliveryInvoice ?? [])].filter((obj, index, self) =>
            index === self.findIndex((t) => (
              t.id === obj.id
            ))
          )}
          setCheckedData={setCheckedData}
          checkedData={checkedData}
          setInvoices={(i) => dispatch(setInvoices(i))}
          showCheckbox={true}
          setSearchTxt={setSearchTxt}
          setSelectAllChecked={setSelectAllChecked}
          selectAllChecked={selectAllChecked}
          loading={isLoading}
          loadMoreInvoices={setLoadMoreTrigger}
          getinvoicesSortBy={getinvoicesSortBy}
          handleDetail={saveFilterData}
        />
      </div>
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Header style={{ borderBottom: "0px" }}>
          <Modal.Title
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 10px"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <CircleRed />
              <span style={{ marginLeft: "10px", marginTop: 24 }}>
                {`Are you sure you want to delete ${checkedData?.length} invoice${checkedData?.length > 1 ? 's' : ''}?`}
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer className={"delete-modal-footer"}>
          <button
            className={"btn btn-secondary ms-2 px-4 cancel-delete-button"}
            onClick={handleCloseDelete}
          >
            <span style={{ fontWeight: 600, fontSize: 14 }}>No</span>
          </button>
          <Button
            variant="primary"
            className={"conform-delete-button"}
            onClick={handleDeleteInvoices}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
