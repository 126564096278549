import { Link } from "react-router-dom";
import "./sidebar.scss";
import { NavLink, useLocation } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { LoginUserContext } from "@/App";
import './sidebar.css'



interface SidebarProps {
  setOpenNav?: any;
}


export default function SideBar({ setOpenNav, }: SidebarProps) {
  const context = useContext(LoginUserContext);
  const [userData, setUserData] = useState<any>({});
  const [selectedEmployee, setSelectedEmployee] = useState<boolean>(false);
  const [selectedSettings, setSelectedSettings] = useState<boolean>(false);
  useEffect(() => {
    // console.log("data changed", data);
    // console.log(context?.loginUserData, 'auth')
    if (context?.loginUserData && context?.loginUserData?.role_name) {
      setUserData({ ...context?.loginUserData }); // Update state when data is available
    }
  }, [context]);
  const hasAccess = useCallback(
    (module: string) => !!userData?.role?.permissions?.find((e: any) => e?.name?.includes(module)),
    [userData?.role?.permissions]
  );
  const location = useLocation();
  const menus = [
    hasAccess("batches") && {
      name: "Batches",
      icon: (active: boolean) => {
        return active
          ? "/assets/Icon/Batches-white.svg"
          : "/assets/Icon/Batches.svg";
      },
      link: "/dashboard/batches",
      key: "/dashboard/batches",
    },
    hasAccess("invoice") && {
      name: "Invoices",
      icon: (active: boolean) => {
        return active
          ? "/assets/Icon/Invoices-white.svg"
          : "/assets/Icon/Invoices.svg";
      },
      link: "/dashboard/invoices",
      key: "/dashboard/invoices",
    },
    hasAccess("payments") && {
      name: "Payments",
      icon: (active: boolean) => {
        return active
          ? "/assets/Icon/Payments-white.svg"
          : "/assets/Icon/Payments.svg";
      },
      link: "/dashboard/payments",
      key: "/dashboard/payments",
    },
    hasAccess("employees") && {
      name: "Employees",
      icon: (active: boolean) => {
        return active
          ? "/assets/Icon/Employees-white.svg"
          : "/assets/Icon/Employees.svg";
      },
      rightIcon: (active: boolean) => {
        return active
          ? "/assets/Icon/arrow-down-white.svg"
          : "/assets/Icon/arrow-down-gray.svg";
      },
      screens: [
        {
          id: "empScreen1",
          name: "Users",
          link: "/dashboard/employees/users",
          hasAccess: hasAccess("employees_users_view"),
        },
        {
          id: "empScreen2",
          name: "Roles",
          link: "/dashboard/employees/roles",
          hasAccess: hasAccess("employees_roles_view"),
        },
      ],
      link: hasAccess("employees_users_view") ? "/dashboard/employees/users" : "/dashboard/employees/roles",
      key: "/dashboard/employees",
    },
    hasAccess("vehicle") && {
      name: "Vehicles",
      icon: (active: boolean) => {
        return active
          ? "/assets/Icon/Vehicles-white.svg"
          : "/assets/Icon/Vehicles.svg";
      },
      link: "/dashboard/vehicles",
      key: "/dashboard/vehicles",
    },
    (hasAccess("audit_log_view") || hasAccess("sms_log_view")) && {
      name: "Logs",
      icon: (active: boolean) => {
        return active
          ? "/assets/Icon/Audit-white.svg"
          : "/assets/Icon/Audit.svg";
      },
      link: hasAccess("audit_log_view") ? "/dashboard/logs/activity" : "/dashboard/logs/sms",
      key: "/dashboard/logs",
    },
    hasAccess("delivery") && !hasAccess("batches_view") && {
      name: "Deliveries",
      icon: (active: boolean) => {
        return active
          ? "/assets/Icon/Batches-white.svg"
          : "/assets/Icon/Batches.svg";
      },
      link: "/dashboard/delivery/associates",
      key: "/dashboard/delivery/associates",
    },
    hasAccess("collection") && !hasAccess("batches_view") && {
      name: "Collection",
      icon: (active: boolean) => {
        return active
          ? "/assets/Icon/Batches-white.svg"
          : "/assets/Icon/Batches.svg";
      },
      link: "/dashboard/collection/associates",
      key: "/dashboard/collection/associates",
    },
    (hasAccess("sms_settings_view") || hasAccess("apikey_view")) && {
      name: "Settings",
      icon: (active: boolean) => {
        return active
            ? "/assets/Icon/setting-white.svg"
            : "/assets/Icon/setting-gray.svg";
      },
      rightIcon: (active: boolean) => {
        return active
            ? "/assets/Icon/arrow-down-white.svg"
            : "/assets/Icon/arrow-down-gray.svg";
      },
      screens: [
        {
          id: "settingScreen",
          name: "SMS Settings",
          link: "/dashboard/settings/sms",
          hasAccess: hasAccess("sms_settings_view"),
        },
        {
          id: "settingScreen",
          name: "API Key",
          link: "/dashboard/settings/api_key",
          hasAccess: hasAccess("apikey_view"),
        },
      ],
      link: "/dashboard/settings/",
      key: "/dashboard/settings/",
    },
  ].filter(Boolean);
  return (
    <>
      {/* #router  */}
      {/* <div className="d-flex flex-column sidebar  menu-container flex-shrink-0  ">
        <ul className="nav nav-pills flex-column mb-auto">
          {menus.map((menu, index) => (
            <li key={index} className="nav-item" onClick={() => setOpenNav?.(false)}>
              <a className={`nav-link d-flex sidebar-link ${location.pathname.startsWith(menu.link) ? 'active' : menu.name == "Batches" ? location.pathname.startsWith('/dashboard/batch') ? 'active' : '' : '' }`} href={'#'+menu.link}>
                <img
                  src={menu.icon(location.pathname.startsWith(menu.link) ? true : menu.name == "Batches" ? location.pathname.startsWith('/dashboard/batch') ? true : false : false)}
                  alt={menu.name}
                />
                <span>{menu.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div> */}
      <div className="d-flex flex-column sidebar  menu-container flex-shrink-0  " >
        <ul className="nav nav-pills flex-column mb-auto">
          {menus?.map((menu: any, index: number) => (
            <li key={`${index}`} className="nav-item" onClick={() => {
              setOpenNav?.(false);
              if (menu?.name === "Employees") {
                setSelectedEmployee(!selectedEmployee);
                setSelectedSettings(false);
              } else if (menu?.name === "Settings") {
                setSelectedSettings(!selectedSettings);
                setSelectedEmployee(false);
              } else {
                setSelectedEmployee(false);
                setSelectedSettings(false);
              }
            }}>
              <div>
                {(menu?.name === "Employees" || menu?.name === "Settings") ? (
                  <>
                    <Link
                          className={`nav-link justify-content-between d-flex sidebar-link ${location.pathname.startsWith(menu?.link) || (menu?.name === "Settings" && location.pathname.startsWith('/dashboard/settings')) || (menu?.name === "Employees" && location.pathname.startsWith('/dashboard/employees')) ? 'active' : ''}`}
                          to={menu?.link}
                      >
                        <div className="d-flex gap-2">
                          <img
                              src={menu?.icon(location.pathname.startsWith(menu?.link) || (menu?.name === "Settings" && location.pathname.startsWith('/dashboard/settings')) || (menu?.name === "Employees" && location.pathname.startsWith('/dashboard/employees')) ? true : false)}
                              alt={menu?.name}
                          />
                          <span>{menu?.name}</span>
                        </div>
                        <img
                            style={{transform: (!selectedEmployee && !location.pathname.startsWith(menu.link) || !selectedSettings && !location.pathname.startsWith(menu.link))
                                  ? 'rotate(0deg)' : (selectedEmployee && location.pathname.startsWith(menu.link) || selectedSettings && location.pathname.startsWith(menu.link))
                                      ? 'rotate(0deg)' : 'rotate(180deg)'}}
                            src={menu?.rightIcon(location.pathname.startsWith(menu.link)
                                ? true : menu?.name === "Settings" ? location.pathname.startsWith('/dashboard/settings') : menu?.name === "Employees" ? location.pathname.startsWith('/dashboard/employees') : false)}
                            alt={menu.name}
                        />
                      </Link>

                      <div className="d-flex align-items-start justify-content-end svgMenu">
                        {(selectedEmployee && menu.name === "Employees" && location.pathname.includes('/dashboard/employees')) && (menu?.screens.filter((m: any) => m.hasAccess).length > 1 ? <img  src='/assets/Icon/Union.svg' alt='union' style={{height:"66px"}} /> : <img  src='/assets/Icon/Union-single.svg' alt='single-union' style={{height:"60px"}}/>)}
                        {(selectedSettings && menu.name === "Settings" && location.pathname.includes('/dashboard/settings')) && (menu?.screens.filter((m: any) => m.hasAccess).length > 1 ? <img  src='/assets/Icon/Union.svg' alt='union' style={{height:"66px"}} /> : <img  src='/assets/Icon/Union-single.svg' alt='single-union' style={{height:"60px"}}/>)}
                        
                        <div style={{width: '71%'}}>
                        {(selectedEmployee && menu.name === "Employees" && location.pathname.includes('/dashboard/employees')) && (
                              Array.isArray(menu?.screens) && menu?.screens?.map((screen: any, screenIDX: number) => (
                                  <NavLink to={screen?.link} style={{textDecoration: "none"}} onClick={(event) => {
                                    event.stopPropagation();
                                  }} key={screen?.id}>
                                    <div style={{
                                      marginTop: menu?.screens.filter((m: any) => m.hasAccess).length > 1 && screenIDX === 1 ? '-7px' : '11px',
                                      fontSize: "14px",
                                      fontWeight: 400,
                                    }} onClick={() => setOpenNav?.(false)}>
                                      <p style={{
                                        backgroundColor: location.pathname.includes(screen?.link) ? "#ECF7FF" : 'transparent',
                                        color: location.pathname.includes(screen?.link) ? "#0080FC" : "#767676",
                                        padding: "5px", borderRadius: "8px", marginBottom: screenIDX === 0 ? 16 : 0
                                      }}>{screen?.name}</p>
                                    </div>
                                  </NavLink>
                              ))
                          )}
                          {(selectedSettings && menu.name === "Settings" && location.pathname.includes('/dashboard/settings')) && (
                              Array.isArray(menu?.screens) && menu?.screens?.filter((m: any) => m.hasAccess)?.map((screen: any, screenIDX: number) => (
                                  <NavLink to={screen?.link} style={{textDecoration: "none"}} onClick={(event) => {
                                    event.stopPropagation();
                                  }} key={screen?.id}>
                                    <div style={{
                                      marginTop: menu?.screens.filter((m: any) => m.hasAccess).length > 1 && screenIDX === 1 ? '-7px' : '11px',
                                      fontSize: "14px",
                                      fontWeight: 400,
                                      color: "#4D4D4D"
                                    }} onClick={() => setOpenNav?.(false)}>
                                      <p style={{
                                        backgroundColor: location.pathname.includes(screen?.link) ? "#ECF7FF" : 'transparent',
                                        color: location.pathname.includes(screen?.link) ? "#0080FC" : "#767676",
                                        padding: "5px", borderRadius: "8px", marginBottom: screenIDX === 0 ? 16 : 0
                                      }}>{screen?.name}</p>
                                    </div>
                                  </NavLink>
                              ))
                          )}
                        </div>
                      </div>
                  </>
                )
                : 
                (

                  <Link className={`nav-link d-flex sidebar-link ${location.pathname.startsWith(menu?.key) ? 'active' : ''}`}
                    to={menu?.link}>
                    <img
                      src={menu?.icon(location.pathname.startsWith(menu?.key) ? true : false)}
                      alt={menu?.name}
                    />
                    <span>{menu?.name}</span>
                  </Link>
                )}
              </div>
            </li>
          ))}

        </ul>
      </div>
      {/* {!location.pathname.includes("details") && (
        <div className="search-bar  px-4 pt-4" style={{ width: "100%" }}>
          <div className="search-bar-input w-100">
            <img
              src={"/assets/image/textalign-left.svg"}
              // onClick={() => setIsShow(true)}
              alt="User Avatar"
            />
            <input
              style={{ height: "50px" }}
              className="w-100 bg-gray"
              type="text"
              placeholder="Search By Name or Invoice Number"
            />
          </div>
        </div>
      )} */}
    </>
  );
}
