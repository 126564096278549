import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { User, UserDetails, UserRole } from "./UserBody";

import "./InformationModal.css";
import "../modal_style.css";
import { TOKEN_STORAGE } from "@/utils/constants";
import { useGetUserMutation } from "@/state/slices/authApiSlice";

interface CountryOption {
  label: string;
  value: string;
  code: string;
}

interface LoggedInUser {
  id?: number;
  name?: string;
  email?: string;
  role_id?: number;
  role_name?: string;
  isLogin?: boolean;
}

const InformationModal = (props: any) => {
  const {
    informationOpen = false,
    setInformationOpen = () => {},
    handleSelect,
    selectedOption,
    setUserDetails = () => {},
    createUpdateUserAPI = () => {},
    setCropOpen = () => {},
    disableInfoModalButton = false,
  } = props;
  const [getUser, { data, isSuccess }] = useGetUserMutation();
  const authToken = localStorage.getItem(TOKEN_STORAGE);
  const user: User = props.selectedUser;
  const cropOpen: boolean = props.cropOpen;
  const userRoles: UserRole[] = props.userRoles;
  const userDetails: UserDetails = props.userDetails;
  const initialProfilePicture: string = "/assets/Icon/User.svg";
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [selectRole, setSelectRole] = useState<string>();
  const [pic, setPic] = useState<string>(initialProfilePicture);
  const [emptyFields, setEmptyFields] = useState<string[]>([]);
  const [selectedUserRole, setSelectedUserRole] = useState<UserRole[]>([]);
  const [countryNamesList, setCountryNamesList] = useState<any[] | []>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("IN");
  const [loginUserData, setLoginUserData] = useState<LoggedInUser>({});
  const [isFocusRoleField, setIsFocusRoleField] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMediumScreen = useMemo<boolean>(() => {
    return windowWidth <= 900;
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (Boolean(authToken) && !Boolean(loginUserData?.role_name)) {
      getUser();
    }
  }, [authToken]);

  useEffect(() => {
    if (data && isSuccess) {
      // console.log("@@@ LOGGED IN USER: ", data?.id, JSON.stringify(data, null, 4));
      setLoginUserData({ ...data });
    }
  }, [data]);

  useEffect(() => {
    // console.log("@@@ COUNTRY LIST: ", countryList().getData());
    if (informationOpen) {
      let list = countryList()
        .getData()
        .filter((country) => country.value === "IN" || country.value === "US");
      list = list.map((item) => {
        return {
          ...item,
          code: item.value === "IN" ? "91+" : "1+",
        };
      });
      setCountryNamesList(list);
    }
  }, [informationOpen]);

  function selectCountry(data: any) {
    // console.log("### COUNTRY: ", data);
    const findCountry = countryNamesList.find((item) => item.value === data);
    if (findCountry) {
      setSelectedCountry(`${data} ${findCountry?.code}`);
    } else {
      setSelectedCountry(data);
    }
  }

  function rearrangeUserRoles() {
    console.log("^^^^^^^ findUserIndex: ", userRoles);
    const findUserIndex = userRoles.findIndex((item) => {
      return item.name === selectRole;
    });
    if (findUserIndex > -1) {
      const newData = Array.from(userRoles);
      const initialIndexData = newData[0];
      newData[0] = newData[findUserIndex];
      newData[findUserIndex] = initialIndexData;
      setSelectedUserRole(newData);
    }
  }

  const handleCloseModal = () => {
    setInformationOpen(false);
    setPasswordToggle(false);
  };

  useEffect(() => {
    if (informationOpen && user) {
      const findRole = userRoles.find((item) => item.id === user.role_id);
      if (findRole) {
        setSelectRole(findRole.name);
      }
    }
  }, [informationOpen]);

  useEffect(() => {
    if (userDetails?.role_id) {
      const findRole = userRoles.find(
        (item) => item.id === userDetails.role_id
      );
      if (findRole) {
        setSelectRole(findRole.name);
      }
    }
  }, [userDetails]);

  useEffect(() => {
    // console.log("USER DETAILS: ", userDetails.image);
    const keyItems: string[] = Object.keys(userDetails).filter((item) => {
      if (item === "image") {
        return false;
      } else if (user && item === "email") {
        return false;
      } else if (item === "emailError") {
        return false;
      } else if (user && item === "password") {
        return false;
      }
      return true;
    });
    const filterEmpty = keyItems.filter((item) => {
      const details: any = Object.assign({}, userDetails);
      if (typeof details[item] === "number" && item === "role_id") {
        return false;
      } else if (
        details[item] &&
        item === "email" &&
        !userDetails?.emailError
      ) {
        return false;
      } else if (details[item] && item !== "emailError" && item !== "email") {
        return false;
      }
      return true;
    });
    console.log("@@@ FIND EMPTY: ", filterEmpty);
    setEmptyFields(filterEmpty);
  }, [userDetails]);

  return (
    <Modal
      show={informationOpen}
      onHide={() => setInformationOpen(!informationOpen)}
      centered
      dialog
      className="information-modal addVehicle"
    >
      <>
        <div
          className="dropDownPosition informationModalBorderRadius"
          style={{ padding: isMediumScreen ? '14px 16px 0px 16px' : "24px" }}
        >
          <Modal.Header
            className="modal-header  px-0 pt-1 pb-3 mb-3 generic-modal-header"
            style={{ backgroundColor: "white", borderBottom: '1px solid #EAECF0' }}
          >
            <p
              className="mb-0   mx-0 generic_modal_title"
              style={{ fontSize: "16px", fontWeight: "450" }}
            >
              {user ? "Employees Information" : "Employees Information"}
            </p>
            <img
              src="/assets/Icon/Close.svg"
              alt="close"
              style={{ cursor: "pointer" }}
              onClick={() => setInformationOpen(false)}
            />
          </Modal.Header>

          <div className="container  px-0 information-container">
            <div className="flex d-flex ">
              <div
                className="me-3"
                style={{ cursor: "pointer" }}
                onClick={() => setCropOpen(!cropOpen)}
              >
                <img
                  src={userDetails?.image || pic}
                  className="profile"
                  alt="Upload"
                  style={{ width: "54px", height: "54px" }}
                />
              </div>
              <div
                className="d-flex flex-column pt-2"
                onClick={() => setCropOpen(!cropOpen)}
                style={{ cursor: "pointer" }}
              >
                <p
                  className="text-primary mb-0"
                  style={{ fontWeight: "600", fontSize: "14px" }}
                >
                  Upload Your Profile
                </p>
                <p
                  className="text-secondary"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                  Max. 800x400px
                </p>
              </div>
              <Form.Group controlId="formFile" className="mb-3">
                {/* <Form.Control
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                /> */}
              </Form.Group>
            </div>
            <div className="">
              <Form.Label>Role </Form.Label>

              <Dropdown onSelect={handleSelect} onToggle={setIsFocusRoleField}>
                <Dropdown.Toggle
                  variant="secondary"
                  id="dropdown-basic"
                  className="custom-dropdown-toggle res wspace too"
                  style={{ color: "rgb(102, 112, 133)" }}
                >
                  {selectRole || "Select role"}
                  <svg
                    className={`logout-arrow ${isFocusRoleField ? "rotate" : ""}`}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5999 7.4585L11.1666 12.8918C10.5249 13.5335 9.4749 13.5335 8.83324 12.8918L3.3999 7.4585"
                      stroke="#667085"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </Dropdown.Toggle>

                <Dropdown.Menu className="custom-dropdown-menu">
                  {selectRole && (
                    <Dropdown.Item
                      key={"selectRole1"}
                      className={"bgClass wspace"}
                      eventKey={selectRole}
                    >
                      {selectRole}
                    </Dropdown.Item>
                  )}
                  {userRoles.map((role) => {
                    if (role.name === selectRole) {
                      return null;
                    }
                    return (
                      <Dropdown.Item
                        key={`role${role.id}`}
                        eventKey={role.name}
                      >
                        {role.name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="flex   d-block d-md-flex  justify-content-between mt-3">
              <div>
                <Form.Label>First Name </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  className="inputWidth"
                  style={{
                    fontSize: "14px",
                    width: "212px",
                    border: "1px solid rgb(232, 234, 237)",
                    color: "rgb(102, 112, 133)",
                  }}
                  value={userDetails?.first_name}
                  onChange={(e) => {
                    const regex = /[^A-Za-z0-9.,' ']/g;
                    setUserDetails({
                      ...userDetails,
                      first_name: e.target.value.replace(regex, ""),
                    });
                  }}
                />
              </div>
              <div>
                <Form.Label className="marginnnTOPP">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  className="inputWidth"
                  style={{
                    fontSize: "14px",
                    width: "212px",
                    border: "1px solid rgb(232, 234, 237)",
                    color: "rgb(102, 112, 133)",
                  }}
                  value={userDetails?.last_name}
                  onChange={(e) => {
                    const regex = /[^A-Za-z0-9.,' ']/g;
                    setUserDetails({
                      ...userDetails,
                      last_name: e.target.value.replace(regex, ""),
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex   d-block d-md-flex  justify-content-between mt-3">
              <div>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email address"
                  className="inputWidth"
                  style={{
                    fontSize: "14px",
                    width: "212px",
                    border: "1px solid rgb(232, 234, 237)",
                    color: "rgb(102, 112, 133)",
                  }}
                  disabled={loginUserData.role_id !== 1 && user ? true : false}
                  value={userDetails?.email}
                  onChange={(e) => {
                    const text = e.target.value;
                    const check =
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (check.test(String(text).toLowerCase())) {
                      setUserDetails({
                        ...userDetails,
                        email: text,
                        emailError: false,
                      });
                    } else {
                      setUserDetails({
                        ...userDetails,
                        email: text,
                        emailError: true,
                      });
                    }
                  }}
                />
              </div>
              <div>
                <Form.Label className="marginnnTOPP">Phone Number </Form.Label>
                <PhoneInput
                  disabled={loginUserData.role_id !== 1 && user ? true : false}
                  inputStyle={{
                    width: "212px",
                    height: "35px",
                    fontSize: "14px",
                    border: "1px solid rgb(232, 234, 237)",
                    color: "rgb(102, 112, 133)",
                    borderRadius: "7px",
                  }}
                  enableSearch
                  inputClass="phoneInp inputWidth"
                  country={"in"}
                  value={userDetails?.phone}
                  onChange={(phone) => {
                    setUserDetails({
                      ...userDetails,
                      phone: phone,
                    });
                  }}
                />
                <div className="">
                  {/* <Form.Control
                            type="text"
                            placeholder="Phone"
                            autoFocus
                            maxLength={16}
                            value={userDetails.phone}
                            onChange={(e) => {
                                let value = e.target.value
                                if(value[0] === '+'){
                                    value = '+' + value.slice(1).replace(/\D/g,'');
                                }else{
                                    value = value.replace(/\D/g,'');
                                }
                                if(value.length > 16){
                                    value = value.substring(0, 16)

                                }
                                setUserDetails({
                                    ...userDetails,
                                    phone: value
                                });
                            }}
                        /> */}
                </div>
              </div>
            </div>
            <Form.Label className="mt-3">
              Set Password <span className="imp">{user ? "" : ""}</span>
            </Form.Label>
            <div className="flex d-flex">
              <Form.Control
                type={passwordToggle ? "text" : "password"}
                placeholder="*******"
                style={{
                  fontSize: "14px",
                  border: "1px solid rgb(232, 234, 237)",
                  color: "rgb(102, 112, 133)",
                }}
                value={userDetails.password}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    password: e.target.value,
                  })
                }
              />
              <img
                src={`/assets/Icon/Eye ${
                  passwordToggle ? "Show" : "Off"
                } Pass.svg`}
                onClick={() => {
                  setPasswordToggle(!passwordToggle);
                }}
                className="eye"
              />
            </div>
          </div>
        </div>
        <div style={{ gap: '16px', borderTop: isMediumScreen ? '' : '1px solid #EAECF0', fontWeight: '450' }} className="bg-footer generic-modal-footer23 d-flex justify-content-end modal-footer  generic-modal-footer4 generic-modal-footer2 generic-modal-footer3 generic-modal-footer-padding">
          <Button
            className="custom-btn-1"
            variant="light"
            onClick={() => {
              handleCloseModal();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="custom-btn-2"
            style={{ padding: 0, fontWeight: '450', width: user ? '30%' : 90 }}
            disabled={disableInfoModalButton}
            onClick={() => {
              if (emptyFields?.length > 0) {
                let message = "";
                emptyFields.forEach((item, index) => {
                  message += `${index + 1}. ${item.replace(/^\w/, (c) =>
                    c.toUpperCase()
                  )} is required.\n`;
                });
                alert(message);
              } else {
                createUpdateUserAPI();
              }
            }}
          >
            {user ? "Apply Changes" : "Save"}
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default InformationModal;
