import "./dashboard.scss";
import Sidebar from "@/pages/dashboard/components/sidebar/sidebar";
import Header from "@/pages/dashboard/components/header/header";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { isLoggedIn } from "@/utils/helper";
import { useGetUserMutation, useLogoutMutation } from "@/state/slices/authApiSlice";
import permissions from "@/ennum/permission";
import { TOKEN_STORAGE } from "@/utils/constants";
import { LoginUserContext } from "@/App";
import { ReactComponent as Loading } from "@/assets/svgs/loading.svg";
import { Helmet } from "react-helmet";

interface User {
    id?: number;
    name?: string;
    email?: string;
    role_name?: string;
    role?: any;
}
export default function Dashboard() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const context = useContext(LoginUserContext)
    const [openNav, setOpenNav] = useState<boolean>(false);
    const [loggedIn, setLoggedIn] = useState<boolean>(isLoggedIn());
    const [getUser, { data, isSuccess }] = useGetUserMutation();
    const [userData, setUserData] = useState<User>({});
    const [logout, { isSuccess: logoutSuccess, isLoading, isError }] = useLogoutMutation();
    const isMediumScreen = useMemo<boolean>(() => {
        return windowWidth <= 900;
    }, [windowWidth]);

    const location = useLocation()
    const isApi = location.pathname === "/dashboard/settings/api_key";
    let navigate = useNavigate();
    // console.log(loggedIn, "login---->>>>")
    const authToken = localStorage.getItem(TOKEN_STORAGE);
    const checkAuth = async () => {
        if (!authToken) {
            // console.log("logout");
            await logout();
            context?.setAuthContext("");
            context?.setLoginUserData({});
            navigate("/");
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!Boolean(authToken)) {
            // console.log("runing........", authToken)
            // getUser();
            checkAuth();
        }
    }, [authToken]);

    useEffect(() => {
        if (context?.loginUserData && context?.loginUserData?.role_name) {
            setUserData({ ...context?.loginUserData }); // Update state when data is available
        }
    }, [context]);

  // router---
  useEffect(() => {
    // console.log(context?.loginUserData, location?.pathname, "called")
    if (userData?.role_name && (location?.pathname == "" || location?.pathname == "/dashboard")) {
      // console.log(userData?.role_name, "asff")
      if (userData?.role?.permissions?.every((per: any) => per?.name.includes(permissions?.delivery))) {
        // console.log(userData?.role_name, "batches")
        navigate("/dashboard/delivery/associates");
      }
      else if (userData?.role?.permissions?.every((per: any) => per?.name.includes(permissions?.collection))) {
        // console.log(userData?.role_name, "collcetion")
        navigate("/dashboard/collection/associates");
      }
      else if(userData?.role?.permissions?.some((per: any) => per?.name.includes("batches"))){
        // console.log(userData?.role_name, "invoices")
        navigate("/dashboard/batches");
      }
      else if(userData?.role?.permissions?.some((per: any) => per?.name.includes("invoice"))){
        // console.log(userData?.role_name, "invoices")
        navigate("/dashboard/invoices");
      }
      else if(userData?.role?.permissions?.some((per: any) => per?.name.includes("delivery"))){
        // console.log(userData?.role_name, "invoices")
        navigate("/dashboard/delivery/associates");
      }
      else if(userData?.role?.permissions?.some((per: any) => per?.name.includes("collection"))){
        // console.log(userData?.role_name, "invoices")
        navigate("/dashboard/collection/associates");
      }
      else if(userData?.role?.permissions?.some((per: any) => per?.name.includes("payments_view"))){
        // console.log(userData?.role_name, "invoices")
        navigate("/dashboard/payments/deliveries");
      }
      else if(userData?.role?.permissions?.some((per: any) => per?.name.includes("employees_users_view"))){
        // console.log(userData?.role_name, "invoices")
        navigate("/dashboard/employees/users");
      }
      else if(userData?.role?.permissions?.some((per: any) => per?.name.includes("employees_roles_view"))){
        // console.log(userData?.role_name, "invoices")
        navigate("/dashboard/employees/roles");
      }
      else if(userData?.role?.permissions?.some((per: any) => per?.name.includes("vehicles_view"))){
        // console.log(userData?.role_name, "invoices")
        navigate("/dashboard/vehicles");
      }
      else if(userData?.role?.permissions?.some((per: any) => per?.name.includes("audit_log_view"))){
        // console.log(userData?.role_name, "invoices")
        navigate("/dashboard/logs/activity");
      }
      else if(userData?.role?.permissions?.some((per: any) => per?.name.includes("sms_log_view"))){
        // console.log(userData?.role_name, "invoices")
        navigate("/dashboard/logs/sms");
      }
      else if(userData?.role?.permissions?.some((per: any) => per?.name.includes("sms_settings_view"))){
        // console.log(userData?.role_name, "invoices")
        navigate("/dashboard/settings/sms");
      }
      else if(userData?.role?.permissions?.some((per: any) => per?.name.includes("apikey_view"))){
        // console.log(userData?.role_name, "invoices")
        navigate("/dashboard/settings/api_key");
      }
      else{
        navigate("/dashboard");
      }
    }
  }, [userData?.role_name, location?.pathname]);

    // useEffect(() => {
    //   // console.log(context?.loginUserData, location?.pathname, "called")
    //   if (userData?.role_name && (location?.pathname == "" || location?.pathname == "/dashboard")) {
    //     // console.log(userData?.role_name, "asff")
    //     if (userData?.role?.permissions?.every((per: any) => per?.name.includes(permissions?.delivery))) {
    //       // console.log(userData?.role_name, "batches")
    //       navigate("/dashboard/batches/delivery_associates");
    //     }
    //     else if (userData?.role?.permissions?.every((per: any) => per?.name.includes(permissions?.collection))) {
    //       // console.log(userData?.role_name, "collcetion")
    //       navigate("/dashboard/batches/collections_associates");
    //     }
    //     else {
    //       // console.log(userData?.role_name, "invoices")
    //       navigate("/dashboard/invoices");
    //     }
    //   }
    // }, [userData?.role_name, location?.pathname]);
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    const formattedSubdomain = subdomain.charAt(0).toUpperCase() + subdomain.slice(1);

    // add marginTop
    const withSpace = useMemo<boolean>(() => {
      let result = false;
      switch (location.pathname) {
        case '/dashboard/batches/deliveries':
        case '/dashboard/batches/collections':
        case '/dashboard/invoices/deliveries':
        case '/dashboard/invoices/collections':
        case '/dashboard/invoices/completed':
        case '/dashboard/payments/deliveries':
        case '/dashboard/employees/users':
        case '/dashboard/employees/roles':
        case '/dashboard/vehicles':
        case '/dashboard/logs/activity':
        case '/dashboard/logs/sms':
        case '/dashboard/settings/sms':
        case '/dashboard/settings/api_key':
          result = true;
          break;
        default:
          result = false;
          break;
      }
      return result;
  }, [location.pathname]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ formattedSubdomain }</title>
            </Helmet>
            {
                userData?.role_name ?
                    <div
                        className={isApi ? `dashboard-layout-component bg ${openNav && "open"}` : `dashboard-layout-component ${openNav && "open"}`}>
                        {/* <div className="header"> */}
                        <Header user={userData} setLoggedIn={setLoggedIn} setOpenNav={setOpenNav} />
                        {/* </div> */}
                        <div className="d-flex  " style={{ backgroundColor: isMediumScreen ? '#fafafd' : '' }}>
                            {
                                (Boolean(userData)) &&
                                userData?.role?.permissions?.every((per: any) => per?.name.includes(permissions?.delivery)) ||
                                userData?.role?.permissions?.every((per: any) => per?.name.includes(permissions?.collection))
                                    ?
                                    <></>
                                    :
                                    <div className="side-bar">
                                        <Sidebar setOpenNav={setOpenNav} />
                                    </div>
                            }
                            <div className={`${isApi ? "dashboard-content-container-api" : "flex-grow-1 dashboard-content-container"} ${(withSpace) && 'custom-no-padding'}`}
                                style={{ backgroundColor: isMediumScreen && location.pathname.includes('otp_hold') ? 'white' : '' }}
                                onMouseDown={() => setOpenNav(false)}
                            >
                                {
                                    location?.pathname?.includes("otp_hold") || location?.pathname?.includes("upload_image") || location?.pathname?.includes("payment_collection") ?
                                        <div className="">
                                            <div className="">
                                                <Outlet context={{ setOpenNav } satisfies ContextType} />
                                            </div>
                                        </div> :
                                        <div className={`${location?.pathname?.includes("dashboard/employees/roles") ? "invoice-component border-0 bg-transparent shadow-none" : "invoice-component"}`}>
                                            <div className="content">
                                                <Outlet context={{ setOpenNav } satisfies ContextType} />
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    : <>
                        <div style={{ height: "100vh" }} className="d-flex justify-content-center align-items-center">
                            <div>
                                <Loading className="loadingCircle" />
                            </div>
                        </div>
                    </>
            }
        </>
    );
}

type ContextType = {
    setOpenNav: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useSetOpenNav() {
    return useOutletContext<ContextType>();
}
