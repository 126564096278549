// import React, { useState } from "react";
// import BatchTable from "@/pages/dashboard/batchs/(batchsList)/components/batchTable/BatchTable";
// import Batch from "@/types/Batch";
// import {useAppDispatch, useAppSelector} from "@/state/hooks";
// import {useGetBatchsMutation} from "@/state/slices/batchs/batchsApiSlice";
// import {useLogoutMutation} from "@/state/slices/authApiSlice";
// import {useNavigate} from "react-router-dom";
// import {setBatchs} from "@/state/slices/batchs/batchsSlice";

// export default function Collections() {
//    // const [batchs, setBatchs] = useState<Batch[]>([]);
//     const batchsState = useAppSelector((state) => state.batchs);
//     const batchs = batchsState.batchs;
//     //const [batchs, setBatchs] = useState<Batch[]>(initialData);
//     const page = batchsState.page;

//     const [getBatchs, {data, isError, isLoading, error}] =
//         useGetBatchsMutation();
//     const [loadMoreTrigger, setLoadMoreTrigger] = useState(false);
//     const [logout] = useLogoutMutation();
//     const navigate = useNavigate();
//     const dispatch = useAppDispatch();
//     return (
//         <>
//             <div className="">
//                 <BatchTable
//                     batchs={batchs}
//                     setBatchs={setBatchs}
//                     showCheckbox={true}
//                     excludedHeadings={[]}
//                 />
//             </div>
//         </>
//     );
// }

import { useLogoutMutation } from '@/state/slices/authApiSlice';
import { useGetBatchsMutation } from '@/state/slices/batchs/batchsApiSlice';
import Batch from '@/types/Batch';
import BatchTable from '@/pages/dashboard/batchs/(batchsList)/components/batchTable/BatchTable';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBatchsListState } from '../layout';
import useDebounce from '@/utils/hooks/debounce';
import { clearState, setBatchs, updatePage } from '@/state/slices/batchs/batchsSlice';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { set } from 'react-hook-form';
import moment from 'moment';

export default function Collections() {
  const { 
    searchTxt, 
    fromDate, 
    toDate, 
    setDateStart,
    setFromDate,
    setToDate,
    setSearchTxt,
    collectionControllerRef: controllerRef,
    handleCancelRequest,
  } = useBatchsListState();
  const debouncedSearchTerm = useDebounce(searchTxt, 400);
  const batchsState = useAppSelector((state) => state.batchs);
  const batchs = batchsState.batchs;
  const [collectionBatch, setCollectionBatch] = useState<any>([]);
  //const [batchs, setBatchs] = useState<Batch[]>(initialData);
  const page = batchsState.page;

  const [getBatchs, { data, isError, isLoading, error }] =
    useGetBatchsMutation();
  const [loadMoreTrigger, setLoadMoreTrigger] = useState(false);
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [firstLoad, setFirstLoad] = useState(true);
  const location = useLocation();
  const [stateDataSorting, setStateDataSorting] = useState<{sortBy: string, isAsc: boolean | null}>({ sortBy: "", isAsc: null })

  // clear search when refresh
  useEffect(() => {
    return () => {
      setSearchTxt('');
    }
  }, [])
  
  // handle get batchs data when first load page
  useEffect(() => {
    if (firstLoad && controllerRef?.current && !isLoading) {
      autoPopulateFilter();
    }
  }, [location.search, controllerRef?.current]);

  // handle clear data when change screen
  useEffect(() => {
    setCollectionBatch([]);
    dispatch(clearState());

    // clear data when screen is closed
    return () => {
      dispatch(clearState());
      setCollectionBatch([]);
      handleCancelRequest?.('Deliveries');
      // dispatch(updatePage({page: 1}))
    };
  }, [location.pathname]);

  // handle filter
  useEffect(() => {
    if (!firstLoad) {
      const payload = {
        page: 1,
        batch_type: 2,
        keyword: searchTxt,
        from: fromDate ? moment(fromDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        to: toDate ? moment(toDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        sort_by: stateDataSorting.sortBy,
        sort_order: stateDataSorting.isAsc === null ? '' : stateDataSorting.isAsc ? 'ASC' : 'DESC',
      }
      dispatch(clearState());
      setCollectionBatch([]);
      updateURL({
        ...payload,
        from: fromDate,
        to: toDate,
      }, true);
    }
  }, [fromDate, toDate, debouncedSearchTerm]);

  // handle load data when scrolling
  useEffect(() => {
    if (!batchsState.lastPageReached && loadMoreTrigger && !isLoading && !firstLoad) {
      const payload = {
        page: Number(getQueryParam('page', page)) + 1,
        batch_type: 2,
        keyword: searchTxt,
        from: fromDate ? moment(fromDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        to: toDate ? moment(toDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        sort_by: stateDataSorting.sortBy,
        sort_order: stateDataSorting.isAsc == null ? '' : stateDataSorting.isAsc ? "ASC" : 'DESC',
      }
      getBatchs({...payload, signal: controllerRef?.current?.signal}).then((result) => {
        if('data' in result) {
          updateURL({
            ...payload,
            from: fromDate,
            to: toDate,
          }, false);

          setLoadMoreTrigger(false);
        }
      })
    }
  }, [loadMoreTrigger]);

  // handle get batchs
  const getBatchsData = () => {
    let from = getQueryParam('from', '') as string;
    let to  = getQueryParam('to', '') as string;
    let startDate, endDate, startDateUtc, endDateUtc;

    if (from !== '' && to !== '') {
      startDate = new Date(decodeURIComponent(from));
      endDate = new Date(decodeURIComponent(to));
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      startDateUtc = moment(startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      endDateUtc = moment(endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
    } else {
      startDateUtc = '';
      endDateUtc = '';
    }
    const pageParams = Number(getQueryParam('page', 1));

    const payload = {
      page: 1,
      batch_type: 2,
      keyword: getQueryParam('keyword', '') as string,
      from: startDateUtc,
      to: endDateUtc,
      sort_by: getQueryParam('sort_by', '') as string,
      sort_order: getQueryParam('sort_order', '') as string,
      per_page: 10 * pageParams,
      signal: controllerRef?.current?.signal,
    };

    dispatch(clearState());
    setCollectionBatch([]);

    getBatchs(payload).then((result) => {
      if ('data' in result) {
        dispatch(updatePage({page: pageParams}));
      }
    }).finally(() => {
      setFirstLoad(false);
    });
  }
  
  // handle sort data
  const getBatchSortBy = (stateData: { sortBy: string; isAsc: boolean }) => {
    if (!firstLoad) {
      dispatch(clearState());
      setCollectionBatch([]);
      setStateDataSorting(stateData);
      const payload = {
        page: 1,
        batch_type: 2,
        keyword: debouncedSearchTerm,
        from: fromDate ? moment(fromDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        to: toDate ? moment(toDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss') : '',
        sort_by: stateData.sortBy,
        sort_order: stateData.isAsc ? 'ASC' : 'DESC',
      };
      updateURL({
        ...payload,
        from: fromDate,
        to: toDate,
      }, true);
    }
  };
  
  // when data is loaded, then pass to variable
  useEffect(() => {
    if (batchs.length > 0) {
      const sortedBatchData = [...batchs].sort((a: any, b: any) => a.status - b.status);
      setCollectionBatch([...sortedBatchData]);
    }
  }, [batchs]);

  // get params
  const getQueryParam = (param: string, defaultValue: string | number): string | number => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.has(param) ? urlParams.get(param)! : defaultValue;
  }

  // autoPopulate filter
  const autoPopulateFilter = () => {
    let queryParams;

    // check if have filter data on session
    if (getFilterData() !== null) {
      const queryString = getFilterData();
      const params = new URLSearchParams(queryString);
    
      const paramsObject: { [key: string]: any } = {};
      params.forEach((value, key) => {
      if (key === 'batch_type' || key === 'page') {
        paramsObject[key] = Number(value);
      } else {
        paramsObject[key] = value;
      }
      });
      queryParams = paramsObject;

      // remove filter data from session
      sessionStorage.removeItem('batchCollectionsFilterData');
      // update url then refresh page with filter
      return updateURL(queryParams, true);
    }

    queryParams = {
      page: Number(getQueryParam('page', 1)),
      batch_type: 2,
      keyword: getQueryParam('keyword', '') as string,
      from: getQueryParam('from', '') as string,
      to: getQueryParam('to', '') as string,
      sort_by: getQueryParam('sort_by', '') as string,
      sort_order: getQueryParam('sort_order', '') as string,
    };

    if (queryParams.keyword !== '') {
      setSearchTxt(queryParams.keyword)
    }
    if (queryParams.sort_by !== '') {
      setStateDataSorting({
        sortBy: queryParams.sort_by, 
        isAsc: queryParams.sort_order === '' ? null : queryParams.sort_order === 'ASC' ? true : false
      })
    }
    if (queryParams.to !== '' && queryParams.from !== '') {
      let startDate = new Date(decodeURIComponent(queryParams.from));
      let endDate  = new Date(decodeURIComponent(queryParams.to));

      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(0, 0, 0, 0)

      setDateStart([{
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      }])

      setFromDate(queryParams.from);
      setToDate(queryParams.to);
    }

    setTimeout(() => {
      getBatchsData();
    }, 200)
  }

  // update url
  const updateURL = (queryParams: any, isReload: boolean) => {
    const newUrl = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        newUrl.set(key, value.toString());
      }
    });

    setFirstLoad(isReload);
    navigate(`/dashboard/batches/collections?${newUrl.toString()}`, { replace: true });
  }

  // save filter data to sessionStorage
  const saveFilterData = (key:string) => {
    const queryParams = {
      page: page,
      batch_type: 2, 
      keyword: debouncedSearchTerm,
      from: fromDate ? fromDate : '',
      to: toDate ? toDate : '',
      sort_by: stateDataSorting.sortBy,
      sort_order: stateDataSorting.isAsc === null ? '' : stateDataSorting.isAsc ? 'ASC' : 'DESC',
    };

    const newUrl = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        newUrl.set(key, value.toString());
      }
    });

    // clear current list
    dispatch(clearState());
    setCollectionBatch([]);
    controllerRef?.current?.abort();
    
    sessionStorage.setItem(`batch${key}FilterData`, JSON.stringify(newUrl.toString()));
  }

  // get filter data from sessionStorage
  const getFilterData = () => {
    const filterData = sessionStorage.getItem('batchCollectionsFilterData');
    return filterData ? JSON.parse(filterData) : null;
  }

  useEffect(() => {
    if (isError && error && "data" in error && error?.status === 403) {
      logout();
      navigate("/");
    }
  }, [isError]);

  return (
    <>
      <div className="">
        <BatchTable
          batchs={(collectionBatch ?? []).filter(
            (batch: any) => batch.invoices.length > 0
          )}
          setBatchs={(i) => dispatch(setBatchs(i))}
          //setBatchs={setBatchs}
          showCheckbox={true}
          loading={isLoading}
          loadMoreBatchs={setLoadMoreTrigger}
          getBatchSortBy={getBatchSortBy}
          handleDetail={saveFilterData}
        />
      </div>
    </>
  );
}
