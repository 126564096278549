import { useContext, useEffect, useMemo, useState } from "react";
import "../employees/users/employees-list.scss";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { ReactComponent as BurgerSvg } from "@/assets/svgs/burger.svg";
import { useSetOpenNav } from "../index";
import VehicleModal from "@/components/vehicleModal/VehicleModal";
import ManageVehicleModal from "@/components/vehicleModal/ManageVehicleModal";
import { LoginUserContext } from "@/App";

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  email_verified_at: null | string;
  role_id: number;
  image: null | string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  name: string;
  role_name: string;
  image_path: string;
  loading: boolean;
  role: any;
}

export default function VehicleHeader(props: any) {
  const {
    setAddVehicle = () => {},
    setNewVehicleDetails = () => {},
    setSearchText = (text: string) => {}
  } = props;
  const searchText: string = props.searchText; 
  const location = useLocation();
  const [addEditModal, setAddEditModal] = useState<boolean>(false);
  const [showManageModal, setShowManageModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { setOpenNav } = useSetOpenNav();
  const navigate = useNavigate();
  const context = useContext(LoginUserContext);
  const [loginUserData, setLoginUserData] = useState<User | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMediumScreen = useMemo<boolean>(() => {
    return windowWidth <= 900;
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = async () => {
    setAddVehicle();
  };

  useEffect(() => {
    console.log("@@@ MANAGE MODAL: ", showManageModal);
  }, [showManageModal])

  useEffect(() => {
    if (context?.loginUserData) {
      const data: any = context?.loginUserData;
      setLoginUserData(data);
    }
  }, []);

  return (
    <div className="employees-list-component">
      <div className="nav-header">
        <div
          onClick={() => setSearchText("")}
          className="nav-links d-flex justify-content-between align-items-center w-md-100 g-0  m-0 text-12"
        >
            <span className="navbar-link" style={{fontSize: 16, letterSpacing: 0, fontWeight: 500}}>Vehicles</span>
        </div>
        <div className="employee-right-bar">
            <div className="search-bar-input" style={{ height: isMediumScreen ? 42 : 'auto' }}>
                <BurgerSvg className="burger" onClick={() => setOpenNav(true)} />
                <img src={"/assets/Icon/Search.svg"} alt="User Avatar" />
                <input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                type="search"
                placeholder="Search Vehicles"
                />
            </div>
            {
              loginUserData?.role?.permissions?.some((user: {name: string}) => user.name === 'vehicles_create') &&
              (
                <button className="employee-create-batch d-none d-md-block" disabled={loading} style={{padding: 0, fontWeight: 500, width:"135px" , height: isMediumScreen ? "42px" : '36px'}}>
                <img src={"/assets/Icon/Add.svg"} alt="User Avatar" />
                <span style={{ fontWeight: 500 }} onClick={handleSubmit}>{
                "Add Vehicle"
                }</span>
            </button>
              )
            }
            
        </div>
      </div>
      {
        loginUserData?.role?.permissions?.some((user: {name: string}) => user.name === 'vehicles_create') && 
        (
          <div className="addEmployeee  position-absolute bottom-0 w-100 d-flex align-items-center justify-content-center position-fixed left-0 d-block d-md-none" style={{marginLeft:"-16px", padding:"16px"}}>
        <button onClick={handleSubmit} className="employee-create-batch w-100" style={{padding:"10px 16px", alignItems:"normal"}}>
              <img src={"/assets/Icon/Add.svg"} alt="User Avatar" />
              <span>{
              "Add Vehicle"
              }</span>
          </button>
      </div>
        )
      }
      
      {/* <div className="invoices-list-content">
        <Outlet
          context={{
            setSelectedCount,
            searchTxt,
            setSelectedInvoiceIds,
            selectedInvoiceIds,
            // setSearchTxt,
            setBatchType
          }}
        />
      </div> */}
    </div>
  );
}
