import { API } from "./index";

class ApiKeyUser {
    getUserApiKey(email: string, password: string): Promise<any> {
        return API
            .post('/viewapikey', null, { params: { email: email, password: password } })
            .then((res) => res.data);
    }
    resetUserApiKey(): Promise<any> {
        return API
            .post('/resetapikey') 
            .then((res) => res.data);
    }
}

export const apiKeyUser = new ApiKeyUser();
