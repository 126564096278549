import React from "react";
import {CardApi} from "@components/Settings/CardApi";
import {useOutletContext} from "react-router-dom";
import {SettingsContextType} from "@pages/dashboard/settings/(SettingsWrapper)/layout";

const ApiKey: React.FC = () => {
    const {
        isAuthenticated,
        setIsAuthenticated,
        dataApi,
        errorApi,
        isLoadingApi,
        fetchApiKey,
        resetApiKey
    } = useOutletContext<SettingsContextType>();

    return (
        <div className='flex-grow-1'>
            <CardApi
                resetApiKey={resetApiKey}
                dataApi={dataApi}
                fetchApiKey={fetchApiKey}
                isLoadingApi={isLoadingApi}
                errorApi={errorApi}
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
            />
        </div>
    );
};

export default ApiKey;
