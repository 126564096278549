import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import QuestionIcon from "@/assets/svgs/question.svg";

interface TooltipWithLinkProps {
    description: string | undefined
    link?: string;
    linkText?: string;
}

const TooltipWithLink: React.FC<TooltipWithLinkProps> = ({ description, link, linkText }) => {
    return (
        <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="bottom"
            overlay={
                <Popover style={{
                    border: '#FFFFFF',
                    boxShadow: '0px 14px 18px rgba(0, 0, 0, 0.08)',
                }} id="popover-trigger-hover-focus" className="invoice-tooltip-info-sms">
                    <div
                        dangerouslySetInnerHTML={{ __html: description?.replace(/\n/g, '<br />') || '' }}
                    />
                </Popover>
            }
        >
            <img
                src={QuestionIcon}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault()
                }}
                alt="info"
                height="16"
                width="16"
                className="tooltip-btn"
            />
        </OverlayTrigger>
    );
};

export default TooltipWithLink;
