import { Button, Modal } from "react-bootstrap";
import { User } from "./UserBody";
import { useEffect, useMemo, useState } from "react";

type Props = {}

const ActiveDeactiveModal = (props: any) => {
  const {
    deactivateOpen = false,
    setDeactivateOpen = () => {},
    setSelectedUser = () => {},
    updateUserStatus = () => {}
  } = props; 
  const user: User = props.selectedUser;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMediumScreen = useMemo<boolean>(() => {
    return windowWidth <= 900;
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Modal
      show={deactivateOpen}
      className="deactivate-modal"
      centered
      onHide={() => {
        setDeactivateOpen(!deactivateOpen);
        setSelectedUser();
      }}
    >
      <div className="" style={{ padding: "24px 24px 0 24px" }}>
        <div className="flex d-flex justify-content-center">
          <img
            src={user?.image_path || "/assets/image/no_image.jpeg"}
            alt="Rajat"
            style={{ width: "100px", height: "100px" }}
          />
        </div>

        <div className="flex d-flex justify-content-center my-3">
          <h6
            style={{ fontSize: "20px", fontWeight: "500", marginBottom: "0px" }}
            className="deleteFont"
          >
            {user?.is_active
              ? "Are you sure you want to deactivate"
              : "Are you sure you want to activate"}

            <p
              style={{ textAlign: "center", marginBottom: "0px" }}
              className="deleteFont"
            >
              {user?.name}?
            </p>
          </h6>
        </div>
      </div>
      <div
        className="d-flex justify-content-end generic-modal-footer3 "
        style={{ gap: "6px", padding: 16, borderTop: 0, backgroundColor: '#fafafc', borderRadius: isMediumScreen ? '0 0 16px 16px' : '0 0 24px 24px' }}
      >
        <Button
          className="me-2 generic_cancel_button no_button border_radius_8"
          style={{ fontWeight: "450", fontSize: "14px" }}
          variant="light"
          onClick={() => {
            setDeactivateOpen();
            setSelectedUser();
          }}
        >
          No
        </Button>
        <Button
          variant="primary"
          style={{ fontWeight: "450", fontSize: "14px" }}
          className="yes_button border_radius_8"
          onClick={() => {
            updateUserStatus();
            setDeactivateOpen(false);
          }}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
};

export default ActiveDeactiveModal;