import React, { useEffect, useState } from "react";
import { ReactComponent as Eye } from "@/assets/svgs/open-eye.svg";
import { ReactComponent as EyeOff } from "@/assets/svgs/closed-eye.svg";
import { Toast, ToastContainer } from 'react-bootstrap';
import "./CardApi.scss";
import NewLoader from "@components/NewLoader";
import { useGetUserMutation } from "@/state/slices/authApiSlice";
import { TOKEN_STORAGE } from "@/utils/constants";

interface WrapperProps {
    isProceed?: boolean;
    isOpen?: boolean;
    isAuthenticated?: boolean;
    setIsAuthenticated?: (isAuthenticated: boolean) => void;
    errorApi?: any | null;
    fetchApiKey: (email: string, password: string) => Promise<any>;
    isLoadingApi: boolean;
    dataApi?: any;
    resetApiKey: () => Promise<any>;
}

const CardApi: React.FC<WrapperProps> = ({ resetApiKey, setIsAuthenticated, isAuthenticated, errorApi, fetchApiKey, isLoadingApi, dataApi }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [apiKey, setApiKey] = useState<string | null>(null);
    const apiUrl = dataApi?.url;
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastVariant, setToastVariant] = useState("success");
    const [getUser, { data: user, isLoading }] = useGetUserMutation();
    const [password, setPassword] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem(TOKEN_STORAGE);
        if (token) {
            getUser();
        }
    }, [getUser]);

    useEffect(() => {
        if (dataApi?.api_key) {
            setApiKey(dataApi.api_key);
            setIsAuthenticated?.(true);
        }
    }, [dataApi, setIsAuthenticated]);

    const handleFetchApiKey = async () => {
            setIsSubmitting(true);
           try {
                await fetchApiKey(user?.email || "", password);
           } catch (errorApi) {
                console.error('Error fetching API key:', errorApi);
           } finally {
                setIsSubmitting(false);
           }
    };

    useEffect(() => {
        if (errorApi) {
            setToastMessage(`Failed: ${errorApi.response.data.message}`);
            setToastVariant("danger");
            setShowToast(true);
        }
    }, [errorApi]);


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            setToastMessage('Copied to clipboard');
            setToastVariant("success");
            setShowToast(true);
        }).catch(() => {
            setToastMessage('Failed to copy');
            setToastVariant("danger");
            setShowToast(true);
        });
    };

    const handleResetKey = async () => {
        setIsSubmitting(true);
        try {
            await resetApiKey();
            setToastMessage('API Key has been reset successfully');
            setToastVariant("success");
            setShowToast(true);
        } catch (err) {
            setToastMessage(`Failed to reset API Key: ${err}`);
            setToastVariant("danger");
            setShowToast(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="">
            {!isAuthenticated ? (
                <div className="cardApi">
                    <div className='headerCardApi'>
                        <div>
                            <label htmlFor="password" className="labelPass">
                                Enter Password
                            </label>
                            {errorApi && (
                                <div className="error-msg">
                                    {errorApi.response.data.message}
                                </div>
                            )}
                            <div className="input-pass-group">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className="inputPassword"
                                    id="password"
                                    style={{
                                        height: "38px",
                                        borderTopRightRadius: '0',
                                        borderBottomRightRadius: '0',
                                        borderRight: 'none',
                                        borderColor: '#E8EAED'
                                    }}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <div>
                                    <button
                                        type="button"
                                        className="inputPasswordBtn"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? <Eye/> : <EyeOff/>}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='wrapper-btn-pass-api'>
                        <button disabled={isLoadingApi || isSubmitting} className="btn-pass-api" type="button" onClick={handleFetchApiKey}>
                            Proceed
                        </button>
                    </div>
                </div>
            ) : (
                <div className="cardApiOpen">
                    <div className="cardApiOpenHed">
                        <label className="labelPass">
                            Your API URL
                        </label>
                        <div className="grid-container">
                            <input
                                type="text"
                                className="inputPassword"
                                value={apiUrl}
                                readOnly
                                style={{color: '#667085'}}
                            />
                            <button
                                className="btn-open-api btn-oper-m"
                                onClick={() => handleCopyToClipboard(apiUrl)}
                            >
                                <img src="/assets/Icon/copy.svg" alt="Copy Icon"
                                     style={{width: '15px', height: '15px', marginRight: '6px'}}/>
                                Copy
                            </button>
                        </div>
                    </div>
                    <div className="cardApiOpenButton">
                        <label className="labelPass">
                            Your API Key
                        </label>
                        <div className="grid-container">
                            <input
                                type="text"
                                className="inputPassword"
                                value={apiKey || ""}
                                readOnly
                                style={{color: '#667085'}}
                            />
                            <div className='btn-container'>
                                <button
                                    className="btn-open-api"
                                    onClick={() => handleCopyToClipboard(apiKey || "")}
                                    disabled={!apiKey}
                                >
                                    <img src="/assets/Icon/copy.svg" alt="Copy Icon"
                                         style={{width: '15px', height: '15px', marginRight: '6px'}}/>
                                    Copy
                                </button>
                                <button
                                    className="btn-open-api"
                                    onClick={handleResetKey}
                                    disabled={!apiKey || isLoadingApi}
                                >
                                    {isLoadingApi ? (
                                        <div className="loading-row newLoaderAnimation">
                                        <NewLoader cols={5}/>
                                        </div>
                                    ) : (
                                        <>
                                            <img src="/assets/Icon/reset.svg" alt="Reset Icon"
                                                 style={{width: '15px', height: '15px', marginRight: '6px'}}/>
                                            Reset Key
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <ToastContainer position="bottom-center" className="p-3">
                <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                    bg={toastVariant}
                    className="text-white animate__animated animate__fadeInDown toast-centered"
                >
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
};

export default CardApi;
