import Batch from '@/types/Batch';
import './batchs-list.scss';
import {useContext, useEffect, useMemo, useState, useRef} from 'react';
import {Outlet, useLocation, useNavigate, useOutletContext} from 'react-router-dom';
import {useSetOpenNav} from '../..';
import {NavLink} from 'react-router-dom';
import {ReactComponent as BurgerSvg} from '@/assets/svgs/burger.svg';
// import 'react-date-range/dist/styles.css'; // main css file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import {DateRange, Range} from 'react-date-range';
import {ReactComponent as Calender} from '@/assets/svgs/calendar-2.svg';
import { clearState } from "@/state/slices/batchs/batchsSlice";
import {ChevronDown} from 'lucide-react';
import moment from 'moment';
import { useAppDispatch } from '@/state/hooks';
import { LoginUserContext } from "@/App";

type ContextType = {
  setSearchTxt: React.Dispatch<React.SetStateAction<string>>;
  setDateStart: React.Dispatch<React.SetStateAction<any>>;
  setFromDate: React.Dispatch<React.SetStateAction<string>>;
  setToDate: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCount: React.Dispatch<React.SetStateAction<number>>;
  searchTxt: string;
  fromDate: string;
  toDate: string;
  Batchs: Batch[];
  isLoading: boolean;
  loadMoreBatchs: (page: number) => void;
  deliveriesControllerRef?: React.MutableRefObject<AbortController | null>;
  collectionControllerRef?: React.MutableRefObject<AbortController | null>;
  handleCancelRequest?: (key: string) => void;
};

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  email_verified_at: null | string;
  role_id: number;
  image: null | string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  name: string;
  role_name: string;
  image_path: string;
  loading: boolean;
  role: any;
}

export default function BatchsList() {
  const navigate = useNavigate();
  const tabs = [
    {
      name: 'Deliveries',
      icon: (active: boolean) => {
        return active ? '/assets/Icon/Deliveries-blue.svg' : '/assets/Icon/Deliveries.svg';
      },
      link: '/dashboard/batches/deliveries',
    },
    {
      name: 'Collections',
      icon: (active: boolean) => {
        return active ? '/assets/Icon/Collections-blue.svg' : '/assets/Icon/Collections.svg';
      },
      link: '/dashboard/batches/collections',
    },
  ];

  const [dateStart, setDateStart] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: undefined,
      key: 'selection',
    },
  ]);
  
  const deliveriesControllerRef = useRef<AbortController | null>(null);
  const collectionControllerRef = useRef<AbortController | null>(null);
  const [viewCalender, setViewCalender] = useState(false);
  const dispatch = useAppDispatch();
  
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTxt, setSearchTxt] = useState('');
  const {setOpenNav} = useSetOpenNav();
  const context = useContext(LoginUserContext);
  const [loginUserData, setLoginUserData] = useState<User | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMediumScreen = useMemo<boolean>(() => {
    return windowWidth <= 900;
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (context?.loginUserData) {
      const data: any = context?.loginUserData;
      setLoginUserData(data);
    }
  }, []);

  const onTabChange = (key: string) => {
    handleCancelRequest(key);
    dispatch(clearState())
    setFromDate('')
    setToDate('')
    setDateStart([
      {
        startDate: new Date(),
        endDate: undefined,
        key: 'selection',
      }
    ])
  }

  useEffect(() => {
    const controllers = initializeAbortControllers();
    deliveriesControllerRef.current = controllers.deliveries;
    collectionControllerRef.current = controllers.collections;
  }, []);

  const handleCancelRequest = (key: string) => {
    if (key === 'Deliveries') {
      resetController(collectionControllerRef);
    } else if (key === 'Collections') {
      resetController(deliveriesControllerRef);
    }
  };

  const initializeAbortControllers = () => ({
    deliveries: new AbortController(),
    collections: new AbortController(),
  });

  const resetController = (controllerRef: React.MutableRefObject<AbortController | null>) => {
    controllerRef.current?.abort();
    controllerRef.current = new AbortController();
  };

  return (
    <div className="batchs-list-component">
      <div className="nav-header">
        <div
          onClick={() => setSearchTxt('')}
          className=" bg-pure-white nav-links d-flex justify-content-between align-items-center w-md-100 g-0 m-0 text-12">
          {tabs.map((tab, index) => (
            <NavLink
              key={index}
              to={tab.link}
              onClick={() => onTabChange(tab.name)}
              className={'text-decoration-none lav-link-cont'}>
              <li className={`navbar-link`}>
                <img
                  src={tab.icon(location.pathname === tab.link)}
                  alt={tab.name}
                />
                <span>{tab.name}</span>
              </li>
            </NavLink>
          ))}
        </div>
        <div className="right-bar">
          <div className="search-bar-input">
            <BurgerSvg
              className="burger"
              onClick={() => setOpenNav(true)}
            />
            <img
              src={'/assets/Icon/Search.svg'}
              alt="Search Icon"
              className={'search-icon'}
            />
            <input
              className="search-bar-text"
              value={searchTxt}
              onChange={(e) => setSearchTxt(e.target.value)}
              type="search"
              placeholder="Search by Name or Invoice"
            />
          </div>
          <div className="date-range-picker">
            <button
              type="button"
              className="btn btn-light btn-date-range btn-action"
              onClick={() => setViewCalender(!viewCalender)}>
              <Calender />
              <span>{dateStart[0].startDate ? moment(dateStart[0].startDate).format(moment(dateStart[0].endDate).isSame(dateStart[0].startDate, 'day') ? 'DD MMM, YYYY' : 'DD MMM') : ''}</span>
              {(dateStart[0].endDate && !moment(dateStart[0].endDate).isSame(dateStart[0].startDate, 'day')) ?
                <span style={{ paddingLeft: 0 }}>- {moment(dateStart[0].endDate).format('DD MMM, YYYY')}</span> : null}
              <ChevronDown className="fs-6" />
            </button>
            {viewCalender && (
              <div className="drp_popover">
                <DateRange
                  editableDateInputs={false}
                  onChange={(item) => setDateStart([item.selection])}
                  moveRangeOnFirstSelection={false}
                  dateDisplayFormat="dd MMM, yyyy"
                  showMonthAndYearPickers={true}
                  ranges={dateStart}
                />
                <div className="drp-btn-wrap">
                  <button
                    type="button"
                    onClick={() => setDateStart([
                      {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: 'selection',
                      }
                    ])}
                    className="btn btn-today">
                    Today
                  </button>
                </div>
                <hr className="drp-hr" />
                <div className="btn-row">
                  <button
                    type="button"
                    onClick={() => setViewCalender(false)}
                    className="btn btn-cancel text-dark">
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      const startDate = dateStart[0].startDate ? dateStart[0].startDate : '';
                      const endDate = dateStart[0].endDate ? dateStart[0].endDate: '';
                      setFromDate(moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
                      setToDate(moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
                      setViewCalender(false);
                    }}
                    type="button"
                    className="btn flex-grow-1 btn-apply">
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
          {(!viewCalender && loginUserData?.role?.permissions?.some((user: {name: string}) => user.name === 'batches_create')) && (
            <button
              type="button"
              className="create-batch">
              {/* {selectedCount > 0 ? (
                            <div className="selected-count">
                                <span>{selectedCount}</span>
                            </div>
                        ) : (
                            <img src={"/assets/Icon/Add.svg"} alt="Use Avatar" />
                        )} */}
              <span onClick={() => {
                !location?.pathname?.includes("collections") ? navigate("/dashboard/invoices/deliveries") : navigate("/dashboard/invoices/collections");
              }}>Create Batch</span>
            </button>
          )}
        </div>
      </div>
      <div className="batchs-list-content bg-pure-white" style={{ paddingTop: isMediumScreen ? 0 : 16 }}>
        <Outlet context={{
          searchTxt,
          fromDate,
          toDate,
          setSearchTxt,
          setDateStart,
          setFromDate,
          setToDate,
          deliveriesControllerRef,
          collectionControllerRef,
          handleCancelRequest,
        }}/>
      </div>
    </div>
  );
}

export function useBatchsListState() {
  return useOutletContext<ContextType>();
}
