import {useEffect, useState} from "react";
import {smsSettings} from "@/api/sms-settings";

interface SmsCategory {
    id: number;
    exception_type: string;
    buyes_ids: string[] | null;
    is_active: boolean;
    have_otp: boolean;
    otp_status: boolean;
    created_at: string;
    updated_at: string;
    exception_title: string;
}

export const useGetSmsCategories = () => {
    const [data, setData] = useState<SmsCategory[] | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        setIsLoading(true);
        smsSettings.getSmsCategories()
            .then((res) => setData(res))
            .catch((err) => {
                setError(err);
                console.error(err);
            })
            .finally(() => setIsLoading(false));
    }, []);

    return {
        data,
        isLoading,
        error,
    };
};
