import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import _ from "lodash";
import "./RolesBody.scss";
import DeleteRoleModal from "./DeleteRoleModal";
import { API } from "@/api";
import {
  createRole,
  deleteRole,
  permissionList,
  updateRole,
  viewRole,
} from "@/api/roles";
import { getRoles } from "@/api/users";
import NewLoader from "@/components/LoadingTd";
import LoadingTd from "@/components/LoadingTd";
import { User } from "../users/UserBody";
import { LoginUserContext } from "@/App";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import RolesModalPhone from "./RolesModalPhone";
import OffcanvasMobile from "@/components/OffcanvasMobile";
import { ReactComponent as List } from "@/assets/svgs/list.svg";
import { ReactComponent as MenuIcon } from "@/assets/svgs/menu-small.svg";
import { useSetOpenNav } from "@pages/dashboard";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as BurgerSvg } from "@/assets/svgs/burger.svg";

export interface Role {
  id: number;
  name: string;
  users: number;
}
interface Permission {
  id: number;
  name: string;
}

const roles = [
  {
    id: "1",
    title: "Admin",
    subTitle: "8 Users",
  },
  {
    id: "2",
    title: "Manager",
    subTitle: "8 Users",
  },
  {
    id: "3",
    title: "Sales Associate",
    subTitle: "8 Users",
  },
  {
    id: "4",
    title: "Delivery Associate",
    subTitle: "8 Users",
  },
];

interface RoleData {
  id: number;
  name: string;
  users: number;
  permissions: PermissionsList[];
}

interface PermissionsList {
  id: number;
  name: string;
}

interface EachScreen {
  id: number;
  name: string;
  permissions: number[];
}

const RolesBody = () => {
  const context = useContext(LoginUserContext);
  const [loading, setLoading] = useState<boolean>(true); // State to manage loading
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [roles2, setRoles2] = useState(roles);
  const [inputValue, setInputValue] = useState("");
  const [showInput, setShowInput] = useState<boolean | null>(false);
  const [showEditInput, setShowEditInput] = useState<boolean>(false);
  const [editValue, setEditValue] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [roleModalOpen, setRoleModalOpen] = useState(false);
  const [roleEditModalOpen, setEditRoleModalOpen] = useState(false);
  const [rolesList, setRolesList] = useState<Role[]>([]);
  const [screenList, setScreenList] = useState<Permission[]>([]);
  const [roleData, setRoleData] = useState<RoleData | null>();
  const [reserveRoleData, setReserveRoleData] = useState<RoleData | null>();
  const [permissions, setPermissions] = useState<PermissionsList[]>([]);
  const [rolesScreen, setRolesScreen] = useState<EachScreen[]>([]);
  const [changeDetected, setChangeDetected] = useState<boolean>(false);
  const [saveTitle, setSaveTitle] = useState<string>("Save");
  const [loginUserData, setLoginUserData] = useState<User | null>(null);
  const [canCreate, setCanCreate] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const { setOpenNav } = useSetOpenNav();
  const [canManage, setCanManage] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const isMediumScreen = useMemo<boolean>(() => {
    return windowWidth <= 900;
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (context?.loginUserData) {
      const data = context?.loginUserData?.role?.permissions;
      const filteredData = data.filter((item: any) => item.id === 16);
      if (filteredData.length > 0) {
        setCanDelete(true);
      } else {
        setCanDelete(false);
      }
    }
  }, [context]);

  useEffect(() => {
    if (context?.loginUserData) {
      const data = context?.loginUserData?.role?.permissions;
      const filteredData = data.filter((item: any) => item.id === 15);
      if (filteredData.length > 0) {
        setCanEdit(true);
      } else {
        setCanEdit(false);
      }
    }
  }, [context]);

  useEffect(() => {
    if (context?.loginUserData) {
      const data = context?.loginUserData?.role?.permissions;
      const filteredData = data.filter((item: any) => item.id === 14);
      if (filteredData.length > 0) {
        setCanCreate(true);
      } else {
        setCanCreate(false);
      }
    }
  }, [context]);

  useEffect(() => {
    if (roleData && reserveRoleData) {
      lookForChanges();
    }
  }, [roleData, reserveRoleData]);

  const lookForChanges = () => {
    // console.log("PREV DATA: ", reserveRoleData, "\n\nCURRENT DATA: ", roleData);
    if (!_.isEqual(reserveRoleData, roleData)) {
      // console.log("@@@ CHANGE DETECTED ");
      setChangeDetected(true);
      setSaveTitle("Save");
    }
  };

  // const handleManageButtonClick = () => {
  //   setShowInput(!showInput);
  //   setShowEditInput(false)
  // };

  const handleButtonClick = () => {
    setSelectedRole(null);
    setShowInput(!showInput);
    setShowEditInput(false);
  };

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
    event.preventDefault();
  };

  const handleAddRole = () => {
    if (inputValue.trim() === "") {
      setShowInput(false);
      setShowEditInput(false);
      return;
    }

    const newRole = `name=${inputValue}`; // api permissions check

    createNewRole(newRole);
    setShowInput(false);
    setShowEditInput(false);
    setInputValue("");
  };

  const handleEditClick = () => {
    setShowEditInput(!showEditInput);
    const find = rolesList.find((item) => item.id === selectedRole?.id);
    if (find) {
      setEditValue(find.name);
    }
  };

  const editRole = (index: number) => {
    let new_roles = Array.from(rolesList);
    new_roles[index].name = editValue;
    const editRoleData = Object.assign({}, roleData);
    const editSelectedRole = Object.assign({}, selectedRole);
    setRoleData({
      ...editRoleData,
      name: editValue,
    });
    setSelectedRole({
      ...editSelectedRole,
      name: editValue,
    });
    setRolesList(new_roles);
    setEditValue("");
    setShowEditInput(false);
    let permissions = "";
    roleData?.permissions.forEach((permission, permissionIndex) => {
      permissions += `${permission.id}${
        roleData.permissions.length - 1 > permissionIndex ? "," : ""
      }`;
    });
    const url = `${selectedRole?.id}?name=${editValue}&permissions=${permissions}`;
    updateRoleAPI(url);
  };

  useEffect(() => {
    getPermissions();
    getRolesList();
  }, []);

  useEffect(() => {
    if (permissions.length > 0) {
      let newData: EachScreen[] = [];
      for (let index: number = 0; index < permissions.length; index++) {
        if ((index + 1) % 4 === 0) {
          // console.log("### INDEX: ", index + 1);
          const splitArray: string[] = permissions[index]["name"].split("_");
          if (splitArray.length === 2) {
            const screenName = splitArray[0].replace(/^\w/, (c) =>
              c.toUpperCase()
            );
            let permissionIDs: number[] = [];
            const startIndex: number = index + 1 - 4;
            for (let i: number = startIndex; i < index + 1; i++) {
              const id: number = permissions[i]["id"];
              permissionIDs.push(id);
            }
            const eachScreen: EachScreen = {
              id: startIndex + 1,
              name: screenName,
              permissions: permissionIDs,
            };
            newData.push(eachScreen);
          } else if (splitArray.length > 2) {
            splitArray.pop();
            const nameUpdate: string[] = splitArray.map((itemName) =>
              itemName.replace(/^\w/, (c) => c.toUpperCase())
            );
            const screenName = nameUpdate.join("-");
            let permissionIDs: number[] = [];
            const startIndex: number = index + 1 - 4;
            for (let i: number = startIndex; i < index + 1; i++) {
              const id: number = permissions[i]["id"];
              permissionIDs.push(id);
            }
            const eachScreen: EachScreen = {
              id: startIndex + 1,
              name: screenName,
              permissions: permissionIDs,
            };
            newData.push(eachScreen);
          }
        }
      }
      // console.log("@@@ PERMISSIONS SCREEN: ", newData);
      setRolesScreen(newData);
    }
  }, [permissions]);

  async function getPermissions() {
    try {
      const response = await permissionList();
      const data = response.data;
      setPermissions(data);
    } catch (error) {
      console.log("!!! PERMISSIONS ERROR: ", error);
    }
  }

  async function getRolesList(id: number | null = null) {
    try {
      setLoading(true); // Set loading to true before making API call

      const response = await getRoles();
      let data: Role[] = response.data;
      setRolesList(data);
      if (Array.isArray(data) && data?.length > 0) {
        data = data.sort((a, b) => a.id - b.id);
        if (id) {
          const findRole = data.find((item) => item.id === id);
          if (findRole && findRole?.id) {
            setSelectedRole(findRole);
            viewRoleAPI(findRole.id);
          }
        }

        // else{
        //   setSelectedRole(data[0]);
        //   viewRoleAPI(data[0]['id'])
        // }
        else {
          // Find the role with the maximum number of users
          const roleWithMaxUsers = data.reduce(
            (maxRole, currentRole) =>
              currentRole.users > (maxRole?.users ?? -1)
                ? currentRole
                : maxRole,
            null as Role | null
          );

          if (roleWithMaxUsers) {
            setSelectedRole(roleWithMaxUsers);
            viewRoleAPI(roleWithMaxUsers.id);
          }
        }
      }
    } catch (error) {
      console.log("!!! USERS ERROR: ", error);
    } finally {
      setLoading(false); // Set loading to false after API call completes
    }
  }

  async function createNewRole(newRole: string) {
    try {
      const response = await createRole(newRole);
      // setRolesList(response.data);
      console.log(response);
      getRolesList();
    } catch (error) {
      console.log("!!! USERS ERROR: ", error);
    }
  }

  async function updateRoleAPI(newRole: string) {
    try {
      const response = await updateRole(newRole);
      // setRolesList(response.data);
      console.log(response);
      setSaveTitle("Saved");
      getRolesList(selectedRole?.id);
      setChangeDetected(false);
      setSaveTitle("Saved");
    } catch (error) {
      console.log("!!! UPDATE ROLE ERROR: ", error);
    }
  }

  async function viewRoleAPI(id: number) {
    try {
      const response = await viewRole(id);
      // setRolesList(response.data);
      const data: RoleData = response.data;
      const newData = JSON.stringify(data);
      setRoleData(data);
      setReserveRoleData(JSON.parse(newData));
      // console.log("@@@ VIEW ROLE: ", response.data);
    } catch (error) {
      console.log("!!! VIEW ROLE ERROR: ", error);
    }
  }

  const deleteRoleHandler = async () => {
    try {
      if (selectedRole?.id) {
        setDeleteModalOpen(!deleteModalOpen);
        const response = await deleteRole(selectedRole?.id);
        setSelectedRole(null);
        getRolesList();
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        alert(
          "Users already exist in this role. Please remove them before deleting."
        );
      }
      console.log("Delete role error: ", error);
    }
  };

  // console.log(rolesList)

  return (
    <div
      style={{ marginTop: '-20px', marginBottom: isMediumScreen ? '-8px' : 0 }}
      className="container-fluid px-0"
      onClick={() => {
        if (showEditInput) {
          // setShowEditInput(false)
        }
      }}
    >
      <DeleteRoleModal
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        role={selectedRole}
        deleteRoleHandler={deleteRoleHandler}
      />
      {/* <div className="row d-none d-md-block"> */}
      <div className="  ">
        <div className="row">
          <div className="col-xl-4 d-none d-sm-block">
            {/* webscreen */}
            <div
              className="mb-sm-4 mb-lg-4 mb-xl-0 bg-white border-radius heightWeb "
              style={{ border: "1px solid #EAECF0" }}
            >
              <div
                // border-bottom: 1px solid #EAECF0;
                className="p-3"
                style={{
                  backgroundColor: "#F9FAFB",
                  height: "62px",
                  borderTopRightRadius: "16px",
                  borderTopLeftRadius: "16px",
                  borderBottom: "1px solid #EAECF0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: isMediumScreen ? 5 : 0 }}>
                    {
                      isMediumScreen && (
                          <MenuIcon onClick={() => setOpenNav(true)} />
                      )
                    }
                    <h5 className="head-font" style={{ color: "#667085", margin: 0 }}>
                      Roles
                    </h5>
                  </div>
                  
                  {
                    canCreate && (
                      <button
                    // className="btn btn-primary w-100 mx-auto"
                    className="btn  "
                    style={{
                      color: "#0080FC",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                    onClick={handleButtonClick}
                  >
                    + Create Role
                  </button>
                    )
                  }
                </div>
              </div>

              {/* {loading && (
            <div className="d-flex justify-content-center mt-4">
              <div className="loading-row newLoaderAnimation" >
                
                <NewLoader cols={5} />
              </div>
            </div>
          )} */}

              {/* //webview */}
              <div className="roleBodyLeft   table-main2">
                <div
                  className=""
                  style={{
                    padding: showEditInput ? "0px" : "13px 30px 0px 30px",
                  }}
                >
                  {showInput && (
                    <>
                      <div className="position-relative ">
                        <input
                          className="form-control mb-3"
                          type="text"
                          placeholder="Role Name"
                          value={inputValue}
                          onChange={handleInputChange}
                          style={{ border: "1px solid rgb(0, 128, 252)" }}
                        />
                        {inputValue && (
                          <button
                            className="position-absolute top-50  translate-middle-y btn btn-link p-0 m-0"
                            onClick={() => setInputValue("")}
                            style={{ right: "15px" }}
                          >
                            <img
                              src="/assets/Icon/close-circle.svg"
                              alt="clear"
                            />
                          </button>
                        )}
                      </div>
                    </>
                  )}
                  {canCreate &&
                    (showInput ? (
                      <button
                        className="btn btn-primary w-100 mx-auto"
                        onClick={handleAddRole}
                      >
                        {inputValue.trim() === "" ? "Close" : "Save"}
                      </button>
                    ) : (
                      ""
                    ))}
                </div>

                {rolesList.length > 0 &&
                  rolesList.map((role, index) => {
                    if (selectedRole && selectedRole.id === role.id) {
                      return (
                        <>
                          <div
                            key={`userRole${index}`}
                            style={{ padding: "11px 0px 0px 18px" }}
                          >
                            {showEditInput && (
                              <>
                                <div style={{ padding: "0px 18px 0px 0px " }}>
                                  <div
                                    className="position-relative"
                                    style={{
                                      border: "1px solid rgb(0, 128, 252)",
                                      borderRadius: "7px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <input
                                      className="form-control mb-1 w-75 border-0 mt-1"
                                      type="text"
                                      placeholder={role?.name}
                                      value={editValue}
                                      onChange={(e) =>
                                        setEditValue(e.target.value)
                                      }
                                    />
                                    <button
                                      className="position-absolute top-50  translate-middle-y btn btn-link p-0 m-0"
                                      onClick={() => {
                                        editRole(index);
                                      }}
                                      disabled={!editValue.trimStart()}
                                      style={{ right: "15px" }}
                                    >
                                      <span className="text-primary ">
                                        Save
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                            <div
                              style={{
                                borderRight: "2px solid #0080FC",
                                padding: "0px 18px 0px 0px ",
                              }}
                            >
                              <div
                                style={{
                                  cursor: "pointer",
                                  color: "#0080FC",
                                  background: "rgba(236, 247, 255, 1)",
                                  border: "1px solid rgba(0, 128, 252, 1)",
                                  // height:"44px",
                                  padding: "12px 16px 12px 16px",
                                }}
                                className="d-flex gap-2 justify-content-between align-items-center rounded"
                                key={`role${role.id}`}
                                // onClick={() => {
                                //   setShowInput(false);
                                //   setShowEditInput(!showEditInput);
                                // }}
                              >
                                <div
                                  className=""
                                  key={`role${role.id}`}
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "baseline",
                                    width: 'calc(100% - 60px)',
                                  }}
                                >
                                  <p
                                    className="m-0 title-font text-truncate"
                                    style={{ color: "#0080FC", textOverflow: 'ellipsis', maxWidth: '70%', letterSpacing: '0.1px'}}
                                  >
                                    {/* {role.name.length > 15
                                      ? role.name.slice(0, 15) + "..."
                                      : role.name} */}
                                      {role.name}
                                  </p>
                                  <small
                                    className="m-0 subTitle-font ms-1"
                                    style={{ color: "#0080FC" }}
                                  >
                                    ({role.users}) Users
                                  </small>
                                </div>
                                {role?.id === 1 ||
                                role?.id === 2 ||
                                role?.id === 3 ||
                                role?.id === 4 ? null : (
                                  <div className="">
                                    <ul className="list-unstyled  d-flex align-items-center gap-2 m-0">
                                      {/* <small className="m-0 subTitle-font" style={{color:"#667085"}}>
                              ({role.users}) Users
                            </small> */}
                                      {canEdit && (
                                        <li
                                          onClick={(e) => {
                                            // e.stopPropagation();
                                            handleEditClick();
                                          }}
                                        >
                                          {/* // src="/assets/Icon/Edit-White.svg" */}
                                          <img
                                            src="/assets/Icon/Edit.svg"
                                            className=""
                                            alt="edit"
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                          />
                                        </li>
                                      )}
                                      {canDelete && (
                                        <li
                                          onClick={(e) => {
                                            // e.stopPropagation();
                                            setDeleteModalOpen(
                                              !deleteModalOpen
                                            );
                                          }}
                                        >
                                          {/* <img
                                    src="/assets/Icon/trash-white.svg"
                                    alt="trash"
                                  /> */}

                                          <img
                                            src="/assets/Icon/trash.svg"
                                            alt="trash"
                                            style={{
                                              width: "22px",
                                              height: "22px",
                                            }}
                                          />
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                    return (
                      <>
                        <div style={{ padding: "13px 0px  0px 18px" }}>
                          <div
                            key={`userRole${index}`}
                            style={{
                              cursor: "pointer",
                              padding: "12px 36px 12px 16px",
                            }}
                            onClick={() => {
                              setShowInput(false);
                              setSaveTitle("Save");
                              setChangeDetected(false);
                              setSelectedRole(role);
                              setShowEditInput(false);
                              viewRoleAPI(role.id);
                            }}
                            className="d-flex gap-1 justify-content-between align-items-center   "
                          >
                            <div
                              className=""
                              key={`role${role.id}`}
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "baseline",
                                width: 'calc(100% - 60px)',
                              }}
                            >
                              <p
                                className="m-0 title-font text-truncate"
                                style={{ color: "#667085", textOverflow: 'ellipsis', maxWidth: '70%', letterSpacing: '0.1px'}}
                              >
                                {role.name}
                                {/* {role.name.length > 15
                                  ? role.name.slice(0, 15) + "..."
                                  : role.name} */}
                              </p>
                              <span
                                className="m-0 subTitle-font ms-1"
                                style={{ color: "#667085" }}
                              >
                                ({role.users}) Users
                              </span>
                            </div>
                            {role?.id === 1 ||
                            role?.id === 2 ||
                            role?.id === 3 ||
                            role?.id === 4 ? null : (
                              <div className=" ">
                                <ul className="list-unstyled  d-flex gap-2 m-0 align-items-center ">
                                  {/* <small className="m-0 subTitle-font" style={{color:"#667085"}}>
                        ({role.users}) Users
                      </small> */}
                                  {canEdit && (
                                    <li
                                      onClick={(e) => {
                                        // e.stopPropagation();
                                        handleEditClick();
                                      }}
                                    >
                                      <img
                                        src="/assets/Icon/Edit.svg"
                                        alt="edit"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    </li>
                                  )}
                                  {canDelete && (
                                    <li
                                      onClick={(e) => {
                                        // e.stopPropagation();
                                        setDeleteModalOpen(!deleteModalOpen);
                                      }}
                                    >
                                      <img
                                        src="/assets/Icon/trash.svg"
                                        alt="trash"
                                        style={{
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>

          <div
            className="col-xl-4 d-block d-sm-none px-0"
            style={{ borderBottom: "1px solid #F2F4F7" }}
          >
            {/* phone */}
            <div className="mb-sm-4 mb-lg-4 mb-xl-0  border-radius heightWeb d-block d-sm-none ">
              <div
                // border-bottom: 1px solid #EAECF0;
                style={{
                  borderTopRightRadius: "16px",
                  borderTopLeftRadius: "16px",
                  padding: isMediumScreen ? 0 : 16,
                  marginBottom: isMediumScreen ? 16 : 0
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: '0px 12px'
                  }}
                > 
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: isMediumScreen ? 8 : 0 }}>
                    {
                      isMediumScreen && (
                          <BurgerSvg onClick={() => setOpenNav(true)} />
                      )
                    }
                    <p className="m-0" style={{ color: "#667085", margin: 0, lineHeight: 'normal', fontWeight: 500, fontSize: 16 }}>
                    Roles
                  </p>
                  </div>
                  

                  {(canCreate || canEdit || canDelete) && (
                    <button
                      className="btn  "
                      style={{ color: "#0080FC", fontWeight: "500", padding: 0, textDecoration: isMediumScreen ? 'underline' : 'none', fontSize: 16}}
                      onClick={(e) => {
                        setRoleModalOpen(!roleModalOpen);
                      }}
                    >
                      Manage
                    </button>
                  )}
                  {/* 
                              <button 
                                    className="btn"
                                    style={{color: "#0080FC", fontWeight:"500", fontSize:"14px"}}
                                    onClick={handleButtonClick}
                                  >
                                    + Create Role
                                  </button> */}
                </div>
              </div>

              {/* phone view */}
              <div className=" roleBodyLeft  d-sm-none ">
                {rolesList.length > 0 &&
                  rolesList.map((role, index) => {
                    if (selectedRole && selectedRole.id === role.id) {
                      return (
                        <div key={`userRole${index}`}>
                          <div className="">
                            {showInput && (
                              <>
                                <div className="position-relative ">
                                  <input
                                    className="form-control mb-3"
                                    type="text"
                                    placeholder="Role Name"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                  />
                                  {inputValue && (
                                    <button
                                      className="position-absolute top-50  translate-middle-y btn btn-link p-0 m-0"
                                      onClick={() => setInputValue("")}
                                      style={{ right: "15px" }}
                                    >
                                      <img
                                        src="/assets/Icon/close-circle.svg"
                                        alt="clear"
                                      />
                                    </button>
                                  )}
                                </div>
                              </>
                            )}
                            {canCreate &&
                              (showInput ? (
                                <button
                                  className="btn btn-primary w-100 mx-auto mb-3"
                                  onClick={handleAddRole}
                                >
                                  {inputValue.trim() === "" ? "Close" : "Save"}
                                </button>
                              ) : (
                                ""
                              ))}
                          </div>

                          {showEditInput && (
                            <>
                              <div
                                className="position-relative "
                                style={{
                                  border: "1px solid rgb(0, 128, 252)",
                                  borderRadius: "7px",
                                  marginBottom: "5px",
                                }}
                              >
                                <input
                                  className="form-control mb-1 w-75 border-0 mt-1"
                                  type="text"
                                  placeholder={role?.name}
                                  value={editValue}
                                  onChange={(e) => setEditValue(e.target.value)}
                                />
                                <button
                                  className="position-absolute top-50  translate-middle-y btn btn-link p-0 m-0"
                                  onClick={() => {
                                    editRole(index);
                                  }}
                                  disabled={!editValue.trimStart()}
                                  style={{ right: "15px" }}
                                >
                                  <span className="text-primary ">Save</span>
                                </button>
                              </div>
                            </>
                          )}

                          <div
                            style={{
                              cursor: "pointer",
                              color: "#0080FC",
                              borderBottom: "2px solid",
                              width: "max-content",
                              padding: isMediumScreen ? '0px 6px 9px 6px' : ''
                            }}
                            className="d-flex gap-3 justify-content-between align-items-center ws"
                            key={`role${role.id}`}
                          >
                            <div className="py-2 lh-1" key={`role${role.id}`}>
                              <p
                                className="m-0"
                                style={{ color: "#0080FC", fontSize: isMediumScreen ? 12 : 16, fontWeight: 500 }}
                              >
                                {/* {role.name} */}
                                {role.name.length > 15
                                  ? role.name.slice(0, 15) + "..."
                                  : role.name}
                              </p>
                              <div className="d-flex gap-1 mt-2 justify-content-center">
                                <small
                                  className="m-0"
                                  style={{ color: "#0080FC", fontSize: isMediumScreen ? 10 : 12, fontWeight: isMediumScreen ? 400 : 500 }}
                                >
                                  ({role.users} <small>Users</small>)
                                </small>
                              </div>
                            </div>
                            {role?.id === 1 ||
                            role?.id === 2 ||
                            role?.id === 3 ||
                            role?.id === 4 ? null : (
                              <div className="d-none d-md-block">
                                <ul className="list-unstyled lh-lg d-flex gap-2 m-0">
                                  {canEdit && (
                                    <li
                                      onClick={(e) => {
                                        handleEditClick();
                                      }}
                                    >
                                      <img
                                        src="/assets/Icon/Edit.svg"
                                        className=""
                                        alt="edit"
                                      />
                                    </li>
                                  )}
                                  {canDelete && (
                                    <li
                                      onClick={(e) => {
                                        setDeleteModalOpen(!deleteModalOpen);
                                      }}
                                    >
                                      <img
                                        src="/assets/Icon/trash.svg"
                                        alt="trash"
                                      />
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div
                        key={`userRole${index}`}
                        style={{ cursor: "pointer", minWidth: "fit-content", padding: isMediumScreen ? '0px 6px 9px 6px' : '' }}
                        onClick={() => {
                          setSaveTitle("Save");
                          setChangeDetected(false);
                          setSelectedRole(role);
                          setShowEditInput(false);
                          viewRoleAPI(role.id);
                        }}
                        className="d-flex gap-3 justify-content-between align-items-center ws"
                      >
                        <div className="py-2 lh-1" key={`role${role.id}`}>
                          <p
                            className="m-0"
                            style={{ color: "#667085", fontSize: isMediumScreen ? 12 : 16, fontWeight: 500 }}
                          >
                            {role.name.length > 15
                              ? role.name.slice(0, 15) + "..."
                              : role.name}
                          </p>
                          <div className="d-flex gap-1 mt-2 justify-content-center">
                            <small
                              className="m-0"
                              style={{ color: "#667085", fontSize: isMediumScreen ? 10 : 12, fontWeight: isMediumScreen ? 400 : 500 }}
                            >
                              ({role.users} <small>Users</small>)
                            </small>
                          </div>
                        </div>
                        {role?.id === 1 ||
                        role?.id === 2 ||
                        role?.id === 3 ||
                        role?.id === 4 ? null : (
                          <div className="d-none d-md-block">
                            <ul className="list-unstyled lh-lg d-flex gap-2 m-0 ">
                              {canEdit && (
                                <li
                                  onClick={(e) => {
                                    handleEditClick();
                                  }}
                                >
                                  <img src="/assets/Icon/Edit.svg" alt="edit" />
                                </li>
                              )}
                              {canDelete && (
                                <li
                                  onClick={(e) => {
                                    setDeleteModalOpen(!deleteModalOpen);
                                  }}
                                >
                                  <img
                                    src="/assets/Icon/trash.svg"
                                    alt="trash"
                                  />
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div
            className="col-xl-8 marginTOp"
            onClick={() => setShowEditInput(false)}
          >
            <div
              className="bg-white "
              style={{
                borderRadius: "16px",
                overflow: "hidden",
                border: "1px solid #EFEFEF",
                boxShadow: "2px 0px 4px 0px #0000001A",
              }}
            >
              <div
                className="  "
                style={{
                  backgroundColor: "#F9FAFB",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  padding: isMediumScreen ? '10px 16px' : "10px 20px",
                  height: isMediumScreen ? 52 : 62,
                  borderBottom: "1px solid #EAECF0",
                }}
              >
                <h5
                  className="head-font  d-none d-md-block"
                  style={{ color: "#667085", marginBottom: "unset" }}
                >
                  {selectedRole?.name || "--"}
                </h5>
                <h5
                  className="d-block d-md-none d-flex gap-1 align-items-center"
                  style={{ color: isMediumScreen ? '#0D1820' : "#667085", marginBottom: "unset", fontWeight : 500, fontSize: 16 }}
                >
                  {selectedRole?.name || "--"}

                  <span className="m-0 " style={{fontSize: isMediumScreen ? 12 : 14, fontWeight: isMediumScreen ? 400 : 500, color: isMediumScreen ? '#667085' : '', lineHeight: 'normal'}}>
                    ({selectedRole?.users || "0"} Users) 
                  </span>
                </h5>
              </div>

              <div
                style={{ padding: isMediumScreen ? 0 : "24px 24px 0px", backgroundColor: '#ffffff'}}
              >
                {/* webscreen code */}
                {/* <div className="table-main bg-white d-none d-md-block " */}
                <div
                  className="table-container bg-white d-none d-md-block "
                  style={{
                    outline: "1px solid #eaecf0",
                    width: "100%",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{ borderRadius: "4px" }}>
                    <table className="table position-relative" style={{ margin: 0 }}>
                    {/* <table className="batch-table-component roles-table-component position-relative"> */}
                      <thead
                        style={{
                          backgroundColor: "rgb(249, 250, 251)",
                          // outline: "1px solid #eaecf0",
                          borderRadius: "8px 8px 0 0",
                        }}
                        // className="hideHead  position-sticky top-0 z-1 "
                      >
                        <tr>
                          <th
                            style={{
                              backgroundColor: "#F9FAFB",
                              width: windowWidth > 1400 ? "209px" : "175px",
                              padding: "12px 24px",
                            }}
                            scope="col"
                          >
                            <p
                              className="m-0 py-1"
                              style={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#667085",
                              }}
                            >
                              Module
                            </p>
                          </th>
                          <th
                            className="text-center"
                            style={{
                              backgroundColor: "#F9FAFB",
                              padding: "12px 24px",
                            }}
                            scope="col"
                          >
                            <p
                              className="m-0 py-1"
                              style={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#667085",
                              }}
                            >
                              View
                            </p>
                          </th>
                          <th
                            className="text-center"
                            style={{
                              backgroundColor: "#F9FAFB",
                              padding: "12px 24px",
                            }}
                            scope="col"
                          >
                            <p
                              className="m-0 py-1"
                              style={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#667085",
                              }}
                            >
                              Create
                            </p>
                          </th>
                          <th
                            className="text-center"
                            style={{
                              backgroundColor: "#F9FAFB",
                              padding: "12px 24px",
                            }}
                            scope="col"
                          >
                            <p
                              className="m-0 py-1"
                              style={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#667085",
                              }}
                            >
                              Edit
                            </p>
                          </th>
                          <th
                            className="text-center"
                            style={{
                              backgroundColor: "#F9FAFB",
                              padding: "12px 24px",
                            }}
                            scope="col"
                          >
                            <p
                              className="m-0 py-1"
                              style={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#667085",
                              }}
                            >
                              Delete
                            </p>
                          </th>
                        </tr>
                      </thead>

                      <tbody className={canEdit ? '' : 'without-footer'} style={{ borderRadius: "0 0 8px 8px", }}>
                        {rolesScreen.map((screen) => {
                          <>
                            {!loading && (
                              <td className="d-flex justify-content-center">
                                <td className="loading-row newLoaderAnimation">
                                  <NewLoader cols={5} />
                                </td>
                              </td>
                            )}
                          </>;

                          return (
                            <tr key={`${screen.id}`} className="table-title">
                              <td
                                className="table-title"
                                style={{
                                  fontSize: "14px",
                                  width: windowWidth > 1400 ? "209px" : "175px",
                                  padding: "12px 24px",
                                }}
                              >
                                <p style={{}} className="my-1">
                                  {screen?.name}
                                </p>
                              </td>

                              {screen.permissions.map((checkbox) => {
                                if (!selectedRole) {
                                  return (
                                    <td
                                      key={`${screen.name}${checkbox}`}
                                      className="text-center"
                                    >
                                      <input
                                        disabled={true}
                                        style={{
                                          marginTop: "12px",
                                          width: "19px",
                                          height: "19px",
                                        }}
                                        className="py-1 empty-td"
                                        type="checkbox"
                                        name=""
                                        checked={false}
                                        id={`${checkbox}`}
                                      />
                                    </td>
                                  );
                                }
                                const checked = roleData?.permissions.find(
                                  (subId) => {
                                    return subId.id === checkbox;
                                  }
                                );
                                // {{console.log(checked,"checked")}}
                                if (
                                  (screen.name.toLowerCase() === "invoices" &&
                                    checkbox === 2) ||
                                  checkbox === 3
                                ) {
                                  return (
                                    <td
                                      key={`${screen.name}${checkbox}`}
                                      className="text-center  "
                                    >
                                      <div
                                        className="py-1"
                                        style={{ marginTop: "12px" }}
                                      />
                                    </td>
                                  );
                                }
                                if (
                                  (screen.name.toLowerCase() === "audit-log" &&
                                    checkbox === 38) ||
                                  checkbox === 39 ||
                                  checkbox === 40
                                ) {
                                  return (
                                    <td
                                      key={`${screen.name}${checkbox}`}
                                      className="text-center"
                                    >
                                      <div
                                        className="py-1"
                                        style={{ marginTop: "12px" }}
                                      />
                                    </td>
                                  );
                                }
                                if (
                                  (screen.name.toLowerCase() === "sms-log" &&
                                    checkbox === 42) ||
                                  checkbox === 43 ||
                                  checkbox === 44
                                ) {
                                  return (
                                    <td
                                      key={`${screen.name}${checkbox}`}
                                      className="text-center"
                                    >
                                      <div
                                        className="py-1"
                                        style={{ marginTop: "12px" }}
                                      />
                                    </td>
                                  );
                                }
                                console.log('test', checkbox)
                                if (
                                  (screen.name.toLowerCase() === "apikey_view" &&
                                    checkbox === 62) ||
                                  checkbox === 63 ||
                                  checkbox === 64
                                ) {
                                  return (
                                    <td
                                      key={`${screen.name}${checkbox}`}
                                      className="text-center"
                                    >
                                      <div
                                        className="py-1"
                                        style={{ marginTop: "12px" }}
                                      />
                                    </td>
                                  );
                                }
                                if (
                                  (screen.name.toLowerCase() === "sms_settings_view" &&
                                    checkbox === 56) ||
                                  checkbox === 56 ||
                                  checkbox === 58
                                ) {
                                  return (
                                    <td
                                      key={`${screen.name}${checkbox}`}
                                      className="text-center"
                                    >
                                      <div
                                        className="py-1"
                                        style={{ marginTop: "12px" }}
                                      />
                                    </td>
                                  );
                                }
                                // let disabled = false;
                                if (
                                  selectedRole?.id === 1 ||
                                  selectedRole?.id === 2 ||
                                  selectedRole?.id === 3 ||
                                  selectedRole?.id === 4
                                ) {
                                  // disabled = true;
                                  if (checked?.id === checkbox) {
                                    return (
                                      <td
                                        key={`${screen.name}${checkbox}`}
                                        className="text-center"
                                      >
                                        <img
                                          src="/assets/Icon/graycheckbox.svg"
                                          alt="graycheckbox"
                                          className="py-1"
                                          style={{
                                            width: "26px",
                                            height: "26px",
                                            marginTop: "10px",
                                          }}
                                        />
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <td
                                        key={`${screen.name}${checkbox}`}
                                        className="text-center"
                                      >
                                        <input
                                          disabled={true}
                                          style={{
                                            marginTop: "12px",
                                            width: "19px",
                                            height: "19px",
                                          }}
                                          className="py-1 empty-td"
                                          type="checkbox"
                                          name=""
                                          checked={checked?.id === checkbox}
                                          id={`${checkbox}`}
                                        />
                                      </td>
                                    );
                                  }
                                }
                                return (
                                  <td
                                    key={`${screen.name}${checkbox}`}
                                    className="text-center"
                                  >
                                    <input
                                      disabled={!canEdit}
                                      style={{
                                        marginTop: "12px",
                                        width: "19px",
                                        height: "19px",
                                      }}
                                      className="py-1"
                                      type="checkbox"
                                      name=""
                                      onChange={() => {
                                        const findPermission = permissions.find(
                                          (item) => {
                                            return item.id === checkbox;
                                          }
                                        );
                                        // console.log("@@@ CLICKED: ", findPermission);
                                        if (findPermission) {
                                          let editRoleData: RoleData = Object.assign({}, roleData);
                                          const findRoleDataPermission = editRoleData.permissions.find((findId) => {
                                                return (
                                                  findId.id === findPermission.id
                                                );
                                              }
                                            );

                                          if (!findRoleDataPermission) {
                                            const permissionName = findPermission.name.split("_");
                                            // console.log("^^^ PERMISSION NAME1: ", permissionName)
                                            permissionName.length > 2 ? permissionName.splice(permissionName.length - 1,1)
                                              : permissionName.splice(permissionName.length - 1,1);
                                            const allSimilarPermissions = permissions.filter((item) => {
                                                return item.name.includes(permissionName.join("_"));
                                              });
                                            let editPermissionsData = editRoleData.permissions.map((item) => item);
                                            if (findPermission?.name.includes("delete")) {
                                              editPermissionsData = editPermissionsData.concat(allSimilarPermissions)
                                                  .filter((item, index, self) => index === self.findIndex((t) => t.id === item.id && t.name === item.name));
                                              setRoleData({
                                                ...roleData,
                                                ...editRoleData,
                                                permissions:
                                                  editPermissionsData,
                                              });
                                            } else if (findPermission?.name.includes("view")) {
                                              editRoleData.permissions.push(findPermission);
                                              // console.log("^^^ PERMISSION NAME: ", editRoleData)
                                              setRoleData({
                                                ...roleData,
                                                ...editRoleData,
                                                permissions: editRoleData.permissions.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id && t.name === item.name)),
                                              });
                                            } else {
                                              editRoleData.permissions.push(findPermission);
                                              const newPermissions = allSimilarPermissions.filter((item) => {
                                                    // if (item?.name.includes("delete") || item?.id === findPermission?.id) {
                                                    //   return false;
                                                    // }
                                                    // return true;
                                                    if (item?.name.includes("delete") || item?.name.includes("edit")) {
                                                      return false;
                                                    }
                                                    if (item?.name.includes("view") || item?.name.includes("create")) {
                                                      return true;
                                                    }
                                                    return false;
                                              });
                                              newPermissions.forEach((item) => { editRoleData.permissions.push(item);});
                                              setRoleData({
                                                ...roleData,
                                                ...editRoleData,
                                                permissions: editRoleData.permissions.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id &&t.name === item.name)),
                                              });
                                            }
                                            // editRoleData.permissions = editPermissionsData
                                            // console.log("@@@ SIMILAR PERMISSION: ", editRoleData, "\n\n", editPermissionsData);
                                          } else {
                                            const findPermissionIndex = editRoleData.permissions.findIndex((findId) => {
                                                  return (
                                                    findId.id === findPermission.id
                                                  );
                                                }
                                              );
                                            if (findPermissionIndex > -1) {
                                              const uncheckPermission = editRoleData.permissions[findPermissionIndex];
                                              if (uncheckPermission?.name.includes("view")) {
                                                const permissionName = uncheckPermission.name.split("_");
                                                permissionName.length > 2 ? permissionName.splice(permissionName.length - 1,1)
                                                  : permissionName.splice(permissionName.length - 1,1);
                                                let editPermissionsData = editRoleData.permissions.map((item) => item)
                                                    .filter((item) => !item.name?.includes(permissionName.join("_")));
                                                // console.log("@@@ CHECK ALL: ",editPermissionsData);
                                                setRoleData({
                                                  ...roleData,
                                                  ...editRoleData,
                                                  permissions:editPermissionsData,
                                                });
                                              } else if (uncheckPermission?.name.includes("delete")) {
                                                editRoleData.permissions = editRoleData.permissions.filter((item) => item.id !== uncheckPermission.id);
                                                setRoleData(editRoleData);
                                              } else if(uncheckPermission?.name.includes("edit")) {
                                                const permissionName = uncheckPermission.name.split("_");
                                                permissionName.length > 2 ? permissionName.splice(permissionName.length - 1, 1) 
                                                : permissionName.splice(permissionName.length - 1, 1);
    
                                                // Filter to delete 'edit' n 'delete'
                                                editRoleData.permissions = editRoleData.permissions.filter((item) => 
                                                !(item.name.includes(permissionName.join("_")) && (item.name.includes("edit") 
                                                || item.name.includes("delete")))
                                                );
    
                                                setRoleData({
                                                  ...roleData,
                                                  ...editRoleData,
                                                });
                                              } else {
                                                const permissionName = uncheckPermission.name.split("_");
                                                permissionName.length > 2 ? permissionName.splice(permissionName.length - 1,1)
                                                  : permissionName.splice(permissionName.length - 1,1);
                                                const allSimilarPermissions = permissions.filter((item) => {
                                                    return item.name.includes(permissionName.join("_"));
                                                  });
                                                const onlyViewPermission = allSimilarPermissions.find((item) => item.name.includes("view"));
                                                const newPermissions = editRoleData.permissions.map((item) => item)
                                                    .filter((item) => {
                                                      if (item.name?.includes(permissionName.join("_"))) {
                                                        if (item?.id === onlyViewPermission?.id) {
                                                          return true;
                                                        } else {
                                                          return false;
                                                        }
                                                      } else {
                                                        return item;
                                                      }
                                                    });
                                                setRoleData({
                                                  ...roleData,
                                                  ...editRoleData,
                                                  permissions: newPermissions,
                                                });
                                              }
                                              // editRoleData.permissions.splice(findPermissionIndex, 1)
                                            }
                                          }
                                        }
                                      }}
                                      checked={checked?.id === checkbox}
                                      id={`${checkbox}`}
                                    />
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* mobile screen */}
                <div
                  className="table-responsive d-block d-md-none "
                  style={{ backgroundColor: '#ffffff', padding: 0 }}
                >
                  <table className="table" style={{ padding: isMediumScreen ? 0 : 'auto', margin: isMediumScreen ? 0 : 'auto' }}>
                    <thead
                      style={{ backgroundColor: "#F9FAFB" }}
                      className="hideHead d-none"
                    >
                      <tr>
                        <th style={{ backgroundColor: "#F9FAFB" }} scope="col">
                          <p
                            className="m-0 py-1"
                            style={{ fontSize: "14px", fontWeight: "normal" }}
                          >
                            Module
                          </p>
                        </th>
                        <th
                          className="text-center"
                          style={{ backgroundColor: "#F9FAFB" }}
                          scope="col"
                        >
                          <p
                            className="m-0 py-1"
                            style={{ fontSize: "14px", fontWeight: "normal" }}
                            // style="color: rgb(102, 112, 133);"
                          >
                            View
                          </p>
                        </th>
                        <th
                          className="text-center"
                          style={{ backgroundColor: "#F9FAFB" }}
                          scope="col"
                        >
                          <p
                            className="m-0 py-1"
                            style={{ fontSize: "14px", fontWeight: "normal" }}
                          >
                            Create
                          </p>
                        </th>
                        <th
                          className="text-center"
                          style={{ backgroundColor: "#F9FAFB" }}
                          scope="col"
                        >
                          <p
                            className="m-0 py-1"
                            style={{ fontSize: "14px", fontWeight: "normal" }}
                          >
                            Edit
                          </p>
                        </th>
                        <th
                          className="text-center"
                          style={{ backgroundColor: "#F9FAFB" }}
                          scope="col"
                        >
                          <p
                            className="m-0 py-1"
                            style={{ fontSize: "14px", fontWeight: "normal" }}
                          >
                            Delete
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ padding: isMediumScreen ? '0px 16px' : 0 }}>
                      {/* 
                        {!loading && (
                                      <td className="d-flex justify-content-center">
                                        <td className="loading-row newLoaderAnimation" >
                                          <NewLoader cols={5} />
                                        </td>
                                      </td>
                         )}  */}

                      {rolesScreen.map((screen) => {
                        {
                          !loading && (
                            <td className="d-flex justify-content-center">
                              <td className="loading-row newLoaderAnimation">
                                <NewLoader cols={5} />
                              </td>
                            </td>
                          );
                        }

                        return (
                          <>
                            <tr>
                              <td
                                className=""
                                style={{ borderBottom: "none", padding: isMediumScreen ? 0 : 8}}
                                scope="row"
                                colSpan={2}
                              >
                                <p className="my-1 py-1 px-0" style={{ fontSize: 14 }}>{screen?.name}</p>
                              </td>
                            </tr>
                            <tr key={`${screen.id}`}>
                              {/* <td className="" scope="row">
                            <p style={{}} className="my-1 py-1">
                              {screen?.name}
                            </p>
                          </td> */} 

                              {screen.permissions.map(
                                (checkbox, checkboxIndex) => {
                                  const checked = roleData?.permissions.find(
                                    (subId) => {
                                      return subId.id === checkbox;
                                    }
                                  );
                                  // {{console.log(checked,"checked")}}
                                  if (
                                    (screen.name.toLowerCase() === "invoices" &&
                                      checkbox === 2) ||
                                    checkbox === 3
                                  ) {
                                    return (
                                      <>
                                        <td
                                          key={`${screen.name}${checkbox}`}
                                          className="text-center w-25  "
                                        >
                                          <div
                                            className="py-1"
                                            style={{ marginTop: "12px" }}
                                          />
                                        </td>
                                        {/* <p
                                     className=""
                                     style={{ fontSize: "14px", fontWeight: "normal" }}
                                   >
                                     View
                                   </p> */}
                                      </>
                                    );
                                  }
                                  if (
                                    (screen.name.toLowerCase() ===
                                      "audit-log" &&
                                      checkbox === 38) ||
                                    checkbox === 39 ||
                                    checkbox === 40
                                  ) {
                                    return (
                                      <td
                                        key={`${screen.name}${checkbox}`}
                                        className="text-center w-25"
                                      >
                                        <div
                                          className="py-1"
                                          style={{ marginTop: "12px" }}
                                        />
                                      </td>
                                    );
                                  }
                                  if (
                                    (screen.name.toLowerCase() === "sms-log" &&
                                      checkbox === 42) ||
                                    checkbox === 43 ||
                                    checkbox === 44
                                  ) {
                                    return (
                                      <td
                                        key={`${screen.name}${checkbox}`}
                                        className="text-center w-25"
                                      >
                                        <div
                                          className="py-1"
                                          style={{ marginTop: "12px" }}
                                        />
                                      </td>
                                    );
                                  }
                                  // let disabled = false;
                                  if (
                                    selectedRole?.id === 1 ||
                                    selectedRole?.id === 2 ||
                                    selectedRole?.id === 3 ||
                                    selectedRole?.id === 4
                                  ) {
                                    // disabled = true;
                                    if (checked?.id === checkbox) {
                                      return (
                                        <td
                                          key={`${screen.name}${checkbox}`}
                                          className="text-center w-25"
                                          style={{ padding: isMediumScreen ? '0 0 8px 0' : 8, fontSize: 12, lineHeight: 'normal'}}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: isMediumScreen ? "start" : "center",
                                              gap: "7px",
                                              marginLeft: isMediumScreen ? '-3px' : 0,
                                              marginBottom: isMediumScreen ? 4 : 0,
                                            }}
                                          >
                                            <img
                                              src="/assets/Icon/graycheckbox.svg"
                                              alt="graycheckbox"
                                              className="py-1"
                                              style={{
                                                width: "26px",
                                                height: "26px",
                                              }}
                                            />
                                            {checkboxIndex === 0 ? (
                                              <div
                                                style={{
                                                  color: "rgb(102, 112, 133)",
                                                }}
                                              >
                                                View
                                              </div>
                                            ) : checkboxIndex === 1 ? (
                                              <div
                                                style={{
                                                  color: "rgb(102, 112, 133)",
                                                }}
                                              >
                                                Create
                                              </div>
                                            ) : checkboxIndex === 2 ? (
                                              <div
                                                style={{
                                                  color: "rgb(102, 112, 133)",
                                                }}
                                              >
                                                Edit
                                              </div>
                                            ) : (
                                              <div
                                                style={{
                                                  color: "rgb(102, 112, 133)",
                                                }}
                                              >
                                                Delete
                                              </div>
                                            )}
                                          </div>
                                        </td>
                                      );
                                    } else {
                                      return (
                                        <td
                                          key={`${screen.name}${checkbox}`}
                                          className="text-center w-25"
                                          style={{ padding: isMediumScreen ? '0 0 8px 0' : 8, fontSize: 12, lineHeight: 'normal'}}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: isMediumScreen ? "start" : "center",
                                              gap: "10px",
                                              marginLeft: isMediumScreen ? '-3px' : 0,
                                              marginBottom: isMediumScreen ? 4 : 0,
                                              padding: '4px 0 4px 4px',
                                            }}
                                          >
                                            <input
                                              disabled={true}
                                              style={{width: "17px", height: "17px"}}
                                              className="py-1 empty-td"
                                              type="checkbox"
                                              name=""
                                              checked={checked?.id === checkbox}
                                              id={`${checkbox}`}
                                            />
                                            {checkboxIndex === 0 ? (
                                              <div
                                                style={{
                                                  color: "rgb(102, 112, 133)",
                                                }}
                                              >
                                                View
                                              </div>
                                            ) : checkboxIndex === 1 ? (
                                              <div
                                                style={{
                                                  color: "rgb(102, 112, 133)",
                                                }}
                                              >
                                                Create
                                              </div>
                                            ) : checkboxIndex === 2 ? (
                                              <div
                                                style={{
                                                  color: "rgb(102, 112, 133)",
                                                }}
                                              >
                                                Edit
                                              </div>
                                            ) : (
                                              <div
                                                style={{
                                                  color: "rgb(102, 112, 133)",
                                                }}
                                              >
                                                Delete
                                              </div>
                                            )}
                                          </div>
                                        </td>
                                      );
                                    }
                                  }
                                  return (
                                    <td
                                      key={`${screen.name}${checkbox}`}
                                      className="text-center w-25"
                                      style={{ padding: isMediumScreen ? '0 0 8px 0' : 8, fontSize: 12, lineHeight: 'normal'}}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: isMediumScreen ? "start" : "center",
                                          gap: "10px",
                                          marginLeft: isMediumScreen ? '-3px' : 0,
                                          marginBottom: isMediumScreen ? 4 : 0,
                                          padding: '4px 0 4px 4px',
                                        }}
                                      >
                                        <input
                                          disabled={!canEdit}
                                          style={{width: "17px", height: "17px"}}
                                          className="py-1 empty-td"
                                          type="checkbox"
                                          name=""
                                          onChange={() => {
                                            const findPermission =
                                              permissions.find((item) => {
                                                return item.id === checkbox;
                                              });
                                            // console.log("@@@ CLICKED: ", findPermission);
                                            if (findPermission) {
                                              let editRoleData: RoleData =
                                                Object.assign({}, roleData);
                                              const findRoleDataPermission =
                                                editRoleData.permissions.find(
                                                  (findId) => {
                                                    return (
                                                      findId.id ===
                                                      findPermission.id
                                                    );
                                                  }
                                                );

                                              if (!findRoleDataPermission) {  
                                                // console.log("@@@ CHECKED: ",findPermission);
                                                const permissionName = findPermission.name.split("_");
                                                permissionName.length > 2 ? permissionName.splice(permissionName.length - 1,1)
                                                  : permissionName.splice(permissionName.length - 1,1);
                                                const allSimilarPermissions = permissions.filter((item) => {
                                                    return item.name.includes(permissionName.join("_"));
                                                  });
                                                let editPermissionsData = editRoleData.permissions.map((item) => item);
                                                if (findPermission?.name.includes("delete")) {
                                                  editPermissionsData = editPermissionsData.concat(allSimilarPermissions)
                                                      .filter((item, index, self) => index === self.findIndex((t) => t.id === item.id && t.name === item.name));
                                                  setRoleData({
                                                    ...roleData,
                                                    ...editRoleData,
                                                    permissions:
                                                      editPermissionsData,
                                                  });
                                                } else if (findPermission?.name.includes("view")) {
                                                  editRoleData.permissions.push(findPermission);
                                                  // console.log("^^^ PERMISSION NAME: ", editRoleData)
                                                  setRoleData({
                                                    ...roleData,
                                                    ...editRoleData,
                                                    permissions:
                                                      editRoleData.permissions.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id && t.name === item.name)),
                                                  });
                                                } else {
                                                  editRoleData.permissions.push(findPermission);
                                                  const newPermissions = allSimilarPermissions.filter((item) => {
                                                        // if (item?.name.includes("delete") || item?.name.includes(findPermission?.name)) {
                                                        //   return false;
                                                        // }
                                                        // return true;
                                                        if (item?.name.includes("delete") || item?.name.includes("edit")) {
                                                          return false;
                                                        }
                                                        if (item?.name.includes("view") || item?.name.includes("create")) {
                                                          return true;
                                                        }
                                                        return false;
                                                  });
                                                  newPermissions.forEach((item) => {
                                                      editRoleData.permissions.push(item);
                                                    }
                                                  );
                                                  setRoleData({
                                                    ...roleData,
                                                    ...editRoleData,
                                                    permissions:
                                                      editRoleData.permissions.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id && t.name === item.name)),
                                                  });
                                                }
                                                // editRoleData.permissions = editPermissionsData
                                                // console.log("@@@ SIMILAR PERMISSION: ", editRoleData, "\n\n", editPermissionsData);
                                              } else {
                                                const findPermissionIndex = editRoleData.permissions.findIndex((findId) => {
                                                      return (findId.id === findPermission.id);
                                                    }
                                                  );
                                                if (findPermissionIndex > -1) {
                                                  const uncheckPermission = editRoleData.permissions[findPermissionIndex];
                                                  if (uncheckPermission?.name.includes("view")) {
                                                    const permissionName = uncheckPermission.name.split("_");
                                                    permissionName.length > 2 ? permissionName.splice(permissionName.length -1,1)
                                                      : permissionName.splice(permissionName.length -1,1);
                                                    let editPermissionsData = editRoleData.permissions.map((item) => item)
                                                        .filter((item) => !item.name?.includes(permissionName.join("_")));
                                                    // console.log("@@@ CHECK ALL: ",editPermissionsData);
                                                    setRoleData({
                                                      ...roleData,
                                                      ...editRoleData,
                                                      permissions: editPermissionsData,
                                                    });
                                                  } else if (uncheckPermission?.name.includes("delete")) {
                                                    editRoleData.permissions = editRoleData.permissions.filter((item) => item.id !== uncheckPermission.id);
                                                    setRoleData(editRoleData);
                                                  } else if(uncheckPermission?.name.includes("edit")) {
                                                    const permissionName = uncheckPermission.name.split("_");
                                                    permissionName.length > 2 ? permissionName.splice(permissionName.length - 1, 1) 
                                                    : permissionName.splice(permissionName.length - 1, 1);
        
                                                    // Filter to delete 'edit' n 'delete'
                                                    editRoleData.permissions = editRoleData.permissions.filter((item) => 
                                                    !(item.name.includes(permissionName.join("_")) && (item.name.includes("edit") 
                                                    || item.name.includes("delete")))
                                                    );
        
                                                    setRoleData({
                                                      ...roleData,
                                                      ...editRoleData,
                                                    });
                                                  } else {
                                                    const permissionName = uncheckPermission.name.split("_");
                                                    permissionName.length > 2 ? permissionName.splice(permissionName.length -1,1)
                                                      : permissionName.splice(permissionName.length -1,1);
                                                    const allSimilarPermissions = permissions.filter((item) => {
                                                          return item.name.includes(permissionName.join("_"));
                                                        }
                                                      );
                                                    const onlyViewPermission = allSimilarPermissions.find((item) =>item.name.includes("view"));
                                                    const newPermissions = editRoleData.permissions.map((item) => item)
                                                        .filter((item) => {
                                                          if (item.name?.includes(permissionName.join("_"))) {
                                                            if (item?.id === onlyViewPermission?.id) {
                                                              return true;
                                                            } else {
                                                              return false;
                                                            }
                                                          } else {
                                                            return item;
                                                          }
                                                        });
                                                    setRoleData({
                                                      ...roleData,
                                                      ...editRoleData,
                                                      permissions:
                                                        newPermissions,
                                                    });
                                                  }
                                                  // editRoleData.permissions.splice(findPermissionIndex, 1)
                                                }
                                              }
                                            }
                                          }}
                                          checked={checked?.id === checkbox}
                                          id={`${checkbox}`}
                                        />
                                        {checkboxIndex === 0 ? (
                                          <div
                                            style={{
                                              color: "rgb(102, 112, 133)",
                                            }}
                                          >
                                            View
                                          </div>
                                        ) : checkboxIndex === 1 ? (
                                          <div
                                            style={{
                                              color: "rgb(102, 112, 133)",
                                            }}
                                          >
                                            Create
                                          </div>
                                        ) : checkboxIndex === 2 ? (
                                          <div
                                            style={{
                                              color: "rgb(102, 112, 133)",
                                            }}
                                          >
                                            Edit
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              color: "rgb(102, 112, 133)",
                                            }}
                                          >
                                            Delete
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* web action button cancel and save */}
              {canEdit && (
                <div
                  className="d-none  d-md-flex justify-content-end"
                  style={{
                    padding: "24px",
                    backgroundColor: "#FAFAFC",
                    gap: "12px",
                  }}
                >
                  {selectedRole?.id === 1 ||
                  selectedRole?.id === 2 ||
                  selectedRole?.id === 3 ||
                  selectedRole?.id === 4 ? (
                    <Button
                      variant="light"
                      className="border"
                      style={{
                        height: "42px",
                        width: "133px",
                        fontWeight: "450",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        setRoleData(reserveRoleData);
                        setChangeDetected(false);
                      }}
                      disabled={true}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      variant="light"
                      className="border"
                      style={{
                        height: "42px",
                        width: "133px",
                        fontWeight: "450",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        const stringifyData = JSON.stringify(reserveRoleData);
                        setChangeDetected(false);
                        setRoleData(JSON.parse(stringifyData));
                      }}
                      disabled={!changeDetected}
                    >
                      Cancel
                    </Button>
                  )}
                  {selectedRole?.id === 1 ||
                  selectedRole?.id === 2 ||
                  selectedRole?.id === 3 ||
                  selectedRole?.id === 4 ? (
                    <Button
                      className="primary"
                      style={{
                        height: "42px",
                        width: "133px",
                        fontWeight: "450",
                        fontSize: "14px",
                      }}
                      variant="primary"
                      disabled={true}
                      onClick={() => {
                        let permissionString = "";
                        roleData?.permissions.forEach((item, index) => {
                          permissionString += `${item.id}${
                            index < roleData.permissions.length - 1 ? "," : ""
                          }`;
                        });
                        const createNewData = {
                          name: roleData?.name,
                          permissions: permissionString,
                        };
                        console.log("@@@ PERMISSION: ", createNewData);
                        const url = `${roleData?.id}?name=${createNewData.name}&permissions=${createNewData.permissions}`;
                        updateRoleAPI(url);
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      className="primary"
                      style={{
                        height: "42px",
                        width: "133px",
                        fontWeight: "450",
                        fontSize: "14px",
                      }}
                      variant="primary"
                      disabled={!changeDetected}
                      onClick={() => {
                        let permissionString = "";
                        roleData?.permissions.forEach((item, index) => {
                          permissionString += `${item.id}${
                            index < roleData.permissions.length - 1 ? "," : ""
                          }`;
                        });
                        const createNewData = {
                          name: roleData?.name,
                          permissions: permissionString,
                        };
                        console.log("@@@ PERMISSION: ", createNewData);
                        const url = `${roleData?.id}?name=${createNewData.name}&permissions=${createNewData.permissions}`;
                        updateRoleAPI(url);
                      }}
                    >
                      {saveTitle}
                    </Button>
                  )}
                </div>
              )}

              {/* //mobile buttons */}
              {/* {canEdit && (
                <div
                  className="d-block d-md-none d-flex justify-content-between "
                  style={{
                    padding: "16px",
                    backgroundColor: "#FAFAFC",
                    gap: "12px",
                    borderTop: isMediumScreen ? '1px solid #EAECF0' : 'none'
                  }}
                >
                  {selectedRole?.id === 1 ||
                  selectedRole?.id === 2 ||
                  selectedRole?.id === 3 ||
                  selectedRole?.id === 4 ? (
                    <Button
                      style={{ height: 40, backgroundColor: '#ffffff', color: '#1D2939', fontWeight: 600}}
                      variant="light"
                      className="fs-6 px-5 border w-50"
                      onClick={() => {
                        setRoleData(reserveRoleData);
                        setChangeDetected(false);
                      }}
                      disabled={true}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      style={{ height: 40, backgroundColor: '#ffffff', color: '#1D2939', fontWeight: 600}}
                      variant="light"
                      className="fs-6 px-5 border w-50 "
                      onClick={() => {
                        const stringifyData = JSON.stringify(reserveRoleData);
                        setChangeDetected(false);
                        setRoleData(JSON.parse(stringifyData));
                      }}
                      disabled={!changeDetected}
                    >
                      Cancel
                    </Button>
                  )}
                  {selectedRole?.id === 1 ||
                  selectedRole?.id === 2 ||
                  selectedRole?.id === 3 ||
                  selectedRole?.id === 4 ? (
                    <Button
                      style={{ height: 40, color: '#ffffff', fontWeight: 600}}
                      className="primary fs-6 px-5 w-50"
                      variant="primary"
                      disabled={true}
                      onClick={() => {
                        let permissionString = "";
                        roleData?.permissions.forEach((item, index) => {
                          permissionString += `${item.id}${
                            index < roleData.permissions.length - 1 ? "," : ""
                          }`;
                        });
                        const createNewData = {
                          name: roleData?.name,
                          permissions: permissionString,
                        };
                        console.log("@@@ PERMISSION: ", createNewData);
                        const url = `${roleData?.id}?name=${createNewData.name}&permissions=${createNewData.permissions}`;
                        updateRoleAPI(url);
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      style={{ height: 40, color: '#ffffff', fontWeight: 600}}
                      className="primary fs-6 px-5 w-50"
                      variant="primary"
                      disabled={!changeDetected}
                      onClick={() => {
                        let permissionString = "";
                        roleData?.permissions.forEach((item, index) => {
                          permissionString += `${item.id}${
                            index < roleData.permissions.length - 1 ? "," : ""
                          }`;
                        });
                        const createNewData = {
                          name: roleData?.name,
                          permissions: permissionString,
                        };
                        console.log("@@@ PERMISSION: ", createNewData);
                        const url = `${roleData?.id}?name=${createNewData.name}&permissions=${createNewData.permissions}`;
                        updateRoleAPI(url);
                      }}
                    >
                      {saveTitle}
                    </Button>
                  )}
                </div>
              )} */}
            </div>
          </div>
          {
            canEdit && (
<div
          style={{ left: 0 }}
          className="addEmployeee p-3 py-4 position-absolute bottom-0 w-100 d-flex align-items-center justify-content-center position-fixed left-0 d-block d-md-none gap-3"
          >
            {/* <button onClick={() => navigate('/dashboard/employees/users')} className="employee-create-batch w-100" style={{padding:"9px 16px"}}>
            <img src={"/assets/Icon/Add.svg"} alt="User Avatar" />
            <span className="employeeTop">{"Add Employee"}</span>
            </button> */}
        
            {canEdit && (
                <>
                {selectedRole?.id === 1 ||
                  selectedRole?.id === 2 ||
                  selectedRole?.id === 3 ||
                  selectedRole?.id === 4 ? (
                    <Button
                      style={{ height: 40, backgroundColor: '#ffffff', color: '#1D2939', fontWeight: 600}}
                      variant="light"
                      className="fs-6 px-5 border w-50"
                      onClick={() => {
                        setRoleData(reserveRoleData);
                        setChangeDetected(false);
                      }}
                      disabled={true}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      style={{ height: 40, backgroundColor: '#ffffff', color: '#1D2939', fontWeight: 600}}
                      variant="light"
                      className="fs-6 px-5 border w-50 "
                      onClick={() => {
                        const stringifyData = JSON.stringify(reserveRoleData);
                        setChangeDetected(false);
                        setRoleData(JSON.parse(stringifyData));
                      }}
                      disabled={!changeDetected}
                    >
                      Cancel
                    </Button>
                  )}
                  {selectedRole?.id === 1 ||
                  selectedRole?.id === 2 ||
                  selectedRole?.id === 3 ||
                  selectedRole?.id === 4 ? (
                    <Button
                      style={{ height: 40, color: '#ffffff', fontWeight: 600}}
                      className="primary fs-6 px-5 w-50"
                      variant="primary"
                      disabled={true}
                      onClick={() => {
                        let permissionString = "";
                        roleData?.permissions.forEach((item, index) => {
                          permissionString += `${item.id}${
                            index < roleData.permissions.length - 1 ? "," : ""
                          }`;
                        });
                        const createNewData = {
                          name: roleData?.name,
                          permissions: permissionString,
                        };
                        console.log("@@@ PERMISSION: ", createNewData);
                        const url = `${roleData?.id}?name=${createNewData.name}&permissions=${createNewData.permissions}`;
                        updateRoleAPI(url);
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      style={{ height: 40, color: '#ffffff', fontWeight: 600}}
                      className="primary fs-6 px-5 w-50"
                      variant="primary"
                      disabled={!changeDetected}
                      onClick={() => {
                        let permissionString = "";
                        roleData?.permissions.forEach((item, index) => {
                          permissionString += `${item.id}${
                            index < roleData.permissions.length - 1 ? "," : ""
                          }`;
                        });
                        const createNewData = {
                          name: roleData?.name,
                          permissions: permissionString,
                        };
                        console.log("@@@ PERMISSION: ", createNewData);
                        const url = `${roleData?.id}?name=${createNewData.name}&permissions=${createNewData.permissions}`;
                        updateRoleAPI(url);
                      }}
                    >
                      {saveTitle}
                    </Button>
                  )}
                </>
              )}

      </div>
            )
          }
          
        </div>
      </div>

      <div>
        <RolesModalPhone
          roleModalOpen={roleModalOpen}
          setRoleModalOpen={setRoleModalOpen}
          // role={selectedRole}
          // deleteRoleHandler={deleteRoleHandler}
        />
      </div>
    </div>
  );
};

export default RolesBody;
