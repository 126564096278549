import { useState } from 'react';
import { apiKeyUser } from "@/api/apiKey";

export const useGetApiKey = () => {
    const [dataApi, setDataApi] = useState<any | undefined>();
    const [isLoadingApi, setIsLoadingApi] = useState(false);
    const [errorApi, setErrorApi] = useState<any | null>(null);

    const fetchApiKey = async (email: string, password: string) => {
        setIsLoadingApi(true);
        setErrorApi(null);

        try {
            const res = await apiKeyUser.getUserApiKey(email, password);
            setDataApi(res);
        } catch (err) {
            setErrorApi(err);
            // @ts-ignore
            console.error('Error fetching API key:', err.response.data.message);
        } finally {
            setIsLoadingApi(false);
        }
    };

    const resetApiKey = async () => {
        setIsLoadingApi(true);
        setErrorApi(null);

        try {
            const res = await apiKeyUser.resetUserApiKey();
            setDataApi(res);
        } catch (err) {
            setErrorApi(err);
            console.error('Error resetting API key:', err);
        } finally {
            setIsLoadingApi(false);
        }
    };

    return {
        dataApi,
        isLoadingApi,
        errorApi,
        fetchApiKey,
        resetApiKey
    };
};
