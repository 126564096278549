import { API } from "./index";

class SmsSettings {
    getSmsCategories() {
        return API
            .post('/sms_categories')
            .then((res) => res.data);
    }
    addUserSmsSettings(id: number, users_ids: number) {
        return API
            .post(`/sms_settings/add_users/${id}?users_ids=${users_ids}`)
            .then((res) => res.data);
    }

    removeUserSmsSettings(id: number, user_type: string, users_ids: number) {
        return API
            .post(`/sms_settings/remove_users/${id}?user_type=${user_type}&users_ids=${users_ids}`)
            .then((res) => res.data);
    }
    setOtpStatus(status: 1 | 0, id: number) {
        return API
            .post(`/sms_settings/otp_status?status=${status}&id=${id}`)
            .then((res) => res.data);
    }
    setCategoryStatus(status: 1 | 0, id: number) {
        return API
            .post(`/sms_settings/category_status?status=${status}&id=${id}`)
            .then((res) => res.data);
    }
    getUsersOptions() {
        return API
            .post('/roles/users')
            .then((res) => res.data);
    }
    getVivwCredits() {
        return API
            .post('/view_credit')
            .then((res) => res.data);
    }
    searchBuyers(keyword: string) {
        return API
            .post(`/buyers?keyword=${keyword}`)
            .then((res) => res.data);
    }
    searchUsers(keyword: string) {
        return API
            .post(`/users?keyword=${keyword}`)
            .then((res) => res.data);
    }
}

export const smsSettings = new SmsSettings();
