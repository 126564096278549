import React from "react";
import RolesBody from "./RolesBody";

const Roles: React.FC = () => {
  return (
    <div>
      {/* <EmployeeHeader /> */}
      <RolesBody /> 
    </div> 
  );
};

export default Roles;
