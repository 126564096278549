import {useEffect, useState} from "react";
import {smsSettings} from "@/api/sms-settings";

interface VivwCredit {
    sms_credit: number;
}

export const useGetVivwCredits = () => {
    const [credit, setCredit] = useState<VivwCredit | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        setIsLoading(true);
        smsSettings.getVivwCredits()
            .then((res) => setCredit(res))
            .catch((err) => {
                setError(err);
                console.error(err);
            })
            .finally(() => setIsLoading(false));
    }, []);

    return {
        credit,
        isLoading,
        error,
    };
};
