import React, { useContext, useEffect, useMemo, useState } from "react";
import "./payments-list.scss";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useSetOpenNav } from "../..";
import { ReactComponent as BurgerSvg } from "@/assets/svgs/burger.svg";
import { useGetPaymentsMutation } from "@/state/slices/payments/paymentsApiSlice";
import { useLogoutMutation } from "@/state/slices/authApiSlice";
import Payment from "@/types/Payment";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import useDebounce from "@/utils/hooks/debounce";
import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
import { DateRange, DateRangePicker, Range, defaultStaticRanges } from 'react-date-range';
import { ReactComponent as Calender } from '@/assets/svgs/calendar-2.svg';
import { ReactComponent as DownloadPm } from '@/assets/svgs/download_payment.svg';
import { ChevronDown, CircleFadingPlus } from 'lucide-react';
import moment from "moment";
import { LoginUserContext } from "@/App";

type ContextType = {
  showAddPaymentModal: boolean
  setShowAddPaymentModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCount: React.Dispatch<React.SetStateAction<number>>;
  from: string;
  to: string;
  searchTxt: string;
  payments: Payment[];
  isLoading: boolean;
  loadMorePayments: (page: number) => void;
  setSearchTxt: React.Dispatch<React.SetStateAction<string>>;
  setDateStart: React.Dispatch<React.SetStateAction<any>>;
  setFrom: React.Dispatch<React.SetStateAction<string>>;
  setTo: React.Dispatch<React.SetStateAction<string>>;
  setOpenNav: React.Dispatch<React.SetStateAction<boolean>>;
};

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  email_verified_at: null | string;
  role_id: number;
  image: null | string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  name: string;
  role_name: string;
  image_path: string;
  loading: boolean;
  role: any;
}

export default function PaymentsList() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dateStart, setDateStart] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: undefined,
      key: 'selection',
    },
  ]);
  const [viewCalender, setViewCalender] = useState(false);
  const [to, setTo] = useState('');
  const [from, setFrom] = useState('');
  const tabs = [
    {
      name: "Payments",
      icon: (active: boolean) => {
        return active
          ? "/assets/Icon/Deliveries-blue.svg"
          : "/assets/Icon/Deliveries.svg";
      },
      link: "/dashboard/payments/deliveries",
    },

  ];
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);

  const context = useContext(LoginUserContext);
  const [loginUserData, setLoginUserData] = useState<User | null>(null);

  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];

  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [searchTxt, setSearchTxt] = useState("");
  const { setOpenNav } = useSetOpenNav();
  const isMediumScreen = useMemo<boolean>(() => {
    return windowWidth <= 900;
  }, [windowWidth]);
  const getSearchLabel = useMemo<string>(() => {
    let desktopMessage = 'Search By Name or Payment ID#';
    let mobileMessage = 'Search';
    return isMediumScreen ? mobileMessage : desktopMessage;
  }, [isMediumScreen]);
  // useEffect(() => {
  //   setTo(moment('');
  //   setFrom()
  // }, [dateStart]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (context?.loginUserData) {
      const data: any = context?.loginUserData;
      setLoginUserData(data);
    }
  }, []);

  return (
    <div className="payments-list-component">
      <div className="nav-header" style={{ height: isMediumScreen ? 'auto' : '62.67px' }}>
        <div
          onClick={() => setSearchTxt("")}
          className="nav-links-payment d-flex justify-content-between align-items-center w-md-100 g-0  m-0 text-12"
        >
          {tabs.map((tab, index) => (
            <NavLink
              to={tab.link}
              key={index}
              className={"text-decoration-none nav-link-cont"}
            >
              <li className={`navbar-link`}>
                <span className="nav-name">{tab.name}</span>
              </li>
            </NavLink>
          ))}
        </div>
        <div className="right-bar bg-pure-white">
          <div className="search-bar-input">
            <div>
            <BurgerSvg className="burger" onClick={() => setOpenNav(true)} />
            </div>
            <img src={"/assets/Icon/Search.svg"} alt="User Avatar" className={'search-icon'} />
            <input
              value={searchTxt}
              onChange={(e) => setSearchTxt(e.target.value)}
              type="search"
              className="search-bar-text"
              placeholder={getSearchLabel}
            />
          </div>
          {
            loginUserData?.role?.permissions?.some((user: {name: string}) => user.name === 'payments_create') && (
              <div className="mobile-only">
                <button className="btn btn-light btn-action" style={{ background: "#EAECF0", padding: '10px 12px', borderRadius: '10px' }} onClick={() => setShowAddPaymentModal(true)}>
                  <CircleFadingPlus color="#667085"/>
                </button>
              </div>
            )
          }
          <div className="date-range-picker">
            <button
              type="button"
              className="btn btn-light btn-action"
              onClick={() => setViewCalender(!viewCalender)}>
              <Calender />
              <span>{dateStart[0].startDate ? moment(dateStart[0].startDate).format(moment(dateStart[0].endDate).isSame(dateStart[0].startDate, 'day') ? 'DD MMM, YYYY' : 'DD MMM') : ''}</span>
              {(dateStart[0].endDate && !moment(dateStart[0].endDate).isSame(dateStart[0].startDate, 'day')) ? <span style={{ paddingLeft: 0 }}>- {moment(dateStart[0].endDate).format('DD MMM, YYYY')}</span> : null}
              <ChevronDown className="fs-6" />
            </button>
            {viewCalender && (
              <div className="drp_popover">
                <DateRange
                  editableDateInputs={false}
                  onChange={(item) => {
                    setDateStart([item.selection]);
                    // console.log(item)
                  }}
                  moveRangeOnFirstSelection={false}
                  showMonthAndYearPickers={true}
                  ranges={dateStart}
                />
                <div className="drp-btn-wrap">
                  <button
                    type="button"
                    onClick={() => setDateStart([
                      {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: 'selection',
                      }
                    ])}
                    className="btn btn-today">
                    Today
                  </button>
                </div>
                <hr className="drp-hr" />
                <div className="btn-row">
                  <button
                    type="button"
                    onClick={() => setViewCalender(false)}
                    className="btn btn-cancel">
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      const startDate = dateStart[0].startDate ? dateStart[0].startDate : '';
                      const endDate = dateStart[0].endDate ? dateStart[0].endDate : '';
                      setFrom(moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
                      setTo(moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
                      setViewCalender(false);
                    }}
                    type="button"
                    className="btn flex-grow-1 btn-apply">
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
          {
            loginUserData?.role?.permissions?.some((user: {name: string}) => user.name === 'payments_create') &&
            (
              <div className="desktop-only">
            <button className="add-payment-button desktop-only" onClick={() => setShowAddPaymentModal(true)} >
              <img src={"/assets/Icon/Add.svg"} alt="User Avatar" />
              <span style={{ fontWeight: '500' }}>Add Payment</span>
            </button>
          </div>
            )
          }
          

          {/*{lastSegment !== "completed" && (*/}
          {/*    <button className="create-batch ">*/}
          {/*      {selectedCount > 0 ? (*/}
          {/*          <div className="selected-count">*/}
          {/*            <span>{selectedCount}</span>*/}
          {/*          </div>*/}
          {/*      ) : (*/}
          {/*          <img src={"/assets/Icon/Add.svg"} alt="User Avatar"/>*/}
          {/*      )}*/}
          {/*      <span>Create Batch</span>*/}
          {/*    </button>*/}
          {/*)}*/}
        </div>
      </div>
      <div className="payments-list-content bg-pure-white" style={{ paddingTop: isMediumScreen ? 0 : 16 }}>
        <Outlet
          context={{
            showAddPaymentModal,
            setShowAddPaymentModal,
            setSelectedCount,
            searchTxt,
            from,
            to,
            setSearchTxt,
            setDateStart,
            setFrom,
            setTo,
            setOpenNav,
          }}
        />
      </div>
    </div>
  );
}

export function usePaymentsListState() {
  return useOutletContext<ContextType>();
}
