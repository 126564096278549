import './payment-table.scss';
import React, {
  useState,
  useEffect,
  useMemo,
  FormHTMLAttributes,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { usePaymentsListState } from '../../layout';
import Form from 'react-bootstrap/Form';
import Payment, {
  paymentHead,
  getKeyFromHead,
  paymentHeadings,
  paymentKeyHeadMap,
} from '@/types/Payment';
import LoadingTd from '@/components/LoadingTd';
import { SortingDir } from '@/utils/enums';
import PaymentTableTh from '../PaymentTableTh';
import { numberWithCommas } from '@/utils/helper';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { ReactComponent as Edit } from '@/assets/svgs/edit.svg';
import { ReactComponent as Delete } from '@/assets/svgs/delete.svg';
import { ReactComponent as View } from '@/assets/svgs/view.svg';
import { ReactComponent as CircleRed } from '@/assets/svgs/circle.svg';
import { ReactComponent as LeftArrow } from '@/assets/svgs/left-arrow.svg';
import { ReactComponent as ArrowDown } from '@/assets/svgs/chev-down.svg';
import { ReactComponent as List } from '@/assets/svgs/list.svg';
import { ReactComponent as CamaraChecked } from '@/assets/svgs/camera-checked.svg';
import { ReactComponent as CameraUncheckedBlue } from '@/assets/svgs/camera-blue-unchecked.svg';
import { ReactComponent as Loading } from '@/assets/svgs/loading.svg';
import { ReactComponent as CloseX } from '@/assets/svgs/close-x.svg';
import { ReactComponent as CloseModal } from '@/assets/svgs/closemodal.svg';
import debounce from 'lodash/debounce';
import useGoBackOrRedirect from '@/utils/hooks/goBackOrRedirect';
import HandleModelReturn from '@/pages/dashboard/payment_collection/product_return';
import { LoginUserContext } from '@/App';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select, { components, DropdownIndicatorProps, IndicatorSeparatorProps } from 'react-select';
import {
  paymentsApiSlice,
  useAddPaymentMutation,
  useDeletePaymentMutation,
  useEditPaymentMutation,
  useGetPaymentsMutation,
} from '@/state/slices/payments/paymentsApiSlice';
import {
  useGetInvoiceDetailsQuery,
  useGetUnpaidInvoiceMutation,
} from '@/state/slices/invoices/invoicesApiSlice';
import { clearState } from '@/state/slices/payments/paymentsSlice';
import moment from 'moment';
import { HOST, TOKEN_STORAGE } from '@/utils/constants';
import axios from 'axios';
import UploadImage from '@/pages/dashboard/upload_image';
import { DateRange } from 'react-date-range';
import PreviewImage from '@/components/common/PreviewImage';

export type SortingState = Record<
    paymentHead['head'],
    {
      dir: SortingDir;
      optionsOpen: boolean;
    }
>;

interface PaymentValues {
  cash?: string; // '?' allows this property to be optional
  online?: string; // Add other properties if needed
  cheque?: string; // Add other properties if needed
  credit?: string; // Add other properties if needed
  payment_return?: string; // Add other properties if needed
  credit_image?: File; // Credit image (if required)
  cheque_image?: File;
  online_image?: File;
  return_date?: string;
}
interface ImageFiles {
  credit_image: string;
  cheque_image: string;
  online_image: string;
}
type AnyObject = {
  [key: string]: any;
};

// custom Dropdown Icon
const CustomDropdownIndicator = (
    props: DropdownIndicatorProps<{ value: string; label: string }>
) => {
  return (
      <components.DropdownIndicator {...props}>
        <ArrowDown />
      </components.DropdownIndicator>
  );
};

// custom Separator
const CustomSeparator = (props: IndicatorSeparatorProps<{ value: string; label: string }, false>) => {
  console.log('pop')
  return <span style={{ width: '2px', height: '70%', backgroundColor: '#D0D5DD', margin: '0 4px', borderRadius: 9999 }} />;
};

export default function PaymentTable({
                                       payments,
                                       showCheckbox,
                                       setPayments,
                                       loading = false,
                                       loadMorePayments,
                                       excludedHeadings = ['Due', 'Overdue By'],
                                       getPaymentsSortBy,
                                       showAddPaymentModal,
                                       setShowAddPaymentModal,
                                       handleDetail,
                                       handleModifyData,
                                     }: {
  payments: Payment[];
  showCheckbox?: boolean;
  setPayments: (payments: Payment[]) => void;
  loading?: boolean;
  loadMorePayments?: React.Dispatch<React.SetStateAction<boolean>>;
  excludedHeadings?: paymentHead['head'][];
  getPaymentsSortBy?: (stateData: {
    sortBy: string;
    isAsc: boolean | null;
  }) => void;
  showAddPaymentModal?: boolean | undefined;
  setShowAddPaymentModal?: any;
  handleDetail?: (key: string) => void;
  handleModifyData?: (id: number, imageURL: string) => void;
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const tableRef = useRef<HTMLTableSectionElement>(null);
  const productHead = [
    {
      head: "Damaged Item",
      sortable: false,
      key: "damaged_item",
    },
    {
      head: "Unit",
      sortable: false,
      key: "unit",
    },
    {
      head: "Price",
      sortable: false,
      key: "price",
    },
  ]
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      border: state.isFocused ? '1px solid #86b7fe' : '',
      boxShadow: state.isFocused ? '0 0 0 0.25rem #b9d5ff' : '',
      fontSize: 14,
      '&:hover': {
        border: '1px solid hsl(0, 0%, 80%);',
      },
      borderRadius: 8,
      height: 38,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontSize: 14,
      color: state.isSelected ? 'white' : 'rgb(93, 111, 129)',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      fontSize: 14,
      color: 'rgb(33, 37, 41)',
    }),
  };
  const paymentsState = useAppSelector((state) => state.payments);
  const page = paymentsState.page;
  const navigate = useNavigate();

  const [paymentAmountUpdated, setPaymentAmountUpdated] = useState('');
  const [selectedId, setSelecetedId] = useState(0);
  const [showImage, setShowImage] = useState(false);
  const [selectedAmount, setSelecetedAmount] = useState('');
  const [selectedAmountDue, setSelecetedAmountDue] = useState('');
  const [selectedInvoiceNo, setSelecetedInvoiceNo] = useState('');
  const [step, setStep] = useState<number>(1);
  const { goBackOrRedirect } = useGoBackOrRedirect();
  const [values, setValues] = useState<PaymentValues>({});
  const [valuesDue, setValuesDue] = useState<PaymentValues>({});
  const [fileImage, setFileImage] = useState<ImageFiles>({
    credit_image: '',
    cheque_image: '',
    online_image: '',
  });
  const controllerRef = useRef<AbortController | null>(null);
  const [searchInvoiceNumber, setSearchInvoiceNumber] = useState('');
  const [productValue, setProductValue] = useState<AnyObject>({}); //for product return
  const [imageName, setImageName] = useState<string>('');
  const [priceReturn, setPrice] = useState<string>('');
  const [returnItem, setReturnItem] = useState<number>(0);
  const [isImageUpload, setIsImageUpload] = useState<boolean>(false);
  const [triggerUpload, setTriggerUpload] = useState<boolean>(false);
  const [loadingPay, setLoadingPay] = useState<boolean>(false);
  const [viewCalender, setViewCalender] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateColor, setDateColor] = useState('');
  const [dueClose, setDueClose] = useState(false);
  const [isShowReturnModal, setIsShowReturnModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<any>(null);
  const [invoice_id, setInvoiceId] = useState(selectedPayment?.id);
  const {
    data: invoiceData,
    isLoading: isLoadingInvoice,
    refetch,
  } = useGetInvoiceDetailsQuery(invoice_id, {
    skip: !invoice_id || isNaN(invoice_id),
  });
  const [paymentAmount, setPaymentAmount] = useState<number>(
      Number(invoiceData?.amount_due)
  );
  const [paymentData, setPaymentData] = useState({
    invoiceNumber: '',
    paymentMethod: '',
    amount: '',
    label: '',
  });
  const [totalDue, setTotalDue] = useState<any>(paymentAmount);
  const context = useContext(LoginUserContext);

  const handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentAmountUpdated(event.target.value);
  };

  const [showEdit, setShowEdit] = useState(false);

  const handleCloseEdit = () => setShowEdit(false);
  const [selectedImage, setSelectedImage] = useState<{id: number, invoice_number: string, buyer_name: string} | null>(null)
  const handleCloseImageModal = () => setShowImage(false);
  const [imagePath, setImagePath] = useState('');
  const handleShowEdit = (
      paymentId: number,
      amount: string,
      amount_due: number,
      invoice_number: string
  ) => {
    setShowEdit(true);
    setSelecetedId(paymentId);
    setSelecetedAmount(amount);
    setSelecetedAmountDue(amount_due.toString());
    setSelecetedInvoiceNo(invoice_number);
  };
  const [showDelete, setShowDelete] = useState(false);
  const [unPaidInvoice, setUnPaidInvoice] = useState<any>([]);
  const [sortingStates, setSortingStates] = useState<{
    sortBy: string;
    isAsc: boolean | null;
  }>({
    sortBy: 'invoice_id',
    isAsc: null,
  });
  const [getUnpaidInvoice, { isLoading: isInvoiceLoading }]: any =
      useGetUnpaidInvoiceMutation();
  const [invoicePage, setInvoicePage] = useState(1);
  const isMediumScreen = useMemo<boolean>(() => {
    return windowWidth <= 900;
  }, [windowWidth]);

  useEffect(() => {
    if (Boolean(invoiceData)) {
      setPaymentAmount(Number(invoiceData?.amount_due));
      setTotalDue(Number(invoiceData?.amount_due));
    }
  }, [invoiceData]);

  const resetData = () => {
    setFileImage({
      credit_image: '',
      cheque_image: '',
      online_image: '',
    });
    setValuesDue({});
    setValues({});
    setProductValue({});
    setPrice('');
    setPaymentData({
      invoiceNumber: '',
      paymentMethod: '',
      amount: '',
      label: '',
    });
    setSelectedPayment(null);
    setSearchInvoiceNumber('');
    setSelectedDate(new Date());
    setDateColor('');
  };

  useEffect(() => {
    const invoiceAmount = paymentAmount;
    const items_quantity = Boolean(Number(invoiceData?.items_quantity))
        ? Number(invoiceData?.items_quantity)
        : 0;

    // Calculate the sum of all payments, defaulting to 0 if undefined
    const cash = Number(valuesDue?.cash?.split(',').join('')) || 0;
    const credit = Number(valuesDue?.credit?.split(',').join('')) || 0;
    const cheque = Number(valuesDue?.cheque?.split(',').join('')) || 0;
    const online = Number(valuesDue?.online?.split(',').join('')) || 0;
    const productPrice = parseFloat(priceReturn.replace(/[^\d.-]/g, '')) || 0;
    // Calculate the total paid
    // console.log(cash, credit, cheque, online, productPrice)
    if (
        items_quantity == returnItem &&
        invoiceAmount - productPrice < 1 &&
        invoiceAmount - productPrice > -1 &&
        cash == 0 &&
        credit == 0 &&
        cheque == 0 &&
        online == 0
    ) {
      setTotalDue(0);
    } else {
      const totalPaid = cash + credit + productPrice + cheque + online;
      // Calculate the remaining balance
      const remainingBalance = invoiceAmount - totalPaid;
      setTotalDue(remainingBalance?.toFixed(2));
    }
  }, [valuesDue, priceReturn]);

  const handleStopSearch = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
      controllerRef.current = null;
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Only allow numbers and at most one decimal point
    const cleanedValue = value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except '.'

    // Ensure only one decimal point exists
    const parts = cleanedValue.split('.');
    if (parts.length > 2) {
      // More than one decimal point is invalid
      return;
    }

    let formattedValue = '';
    if (parts[0]) {
      // Format the whole number part with Indian-style commas
      const integerPart = parts[0];
      const length = integerPart.length;

      if (length > 3) {
        const firstPart = integerPart.slice(0, length - 3); // Everything before the last 3 digits
        const lastPart = integerPart.slice(length - 3); // Last 3 digits

        formattedValue =
            firstPart.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ',' + lastPart;
      } else {
        formattedValue = integerPart;
      }
    }

    // If there's a decimal part, add it back and restrict to 2 decimal places
    if (parts.length === 2) {
      const decimalPart = parts[1].slice(0, 2); // Restricting to a maximum of 2 decimal places
      formattedValue += '.' + decimalPart;
    }

    // Update the state with the formatted value
    setValues({
      ...values,
      [name]: formattedValue,
    });
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Only allow numbers and at most one decimal point
    const cleanedValue = value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except '.'

    // Ensure only one decimal point exists
    const parts = cleanedValue.split('.');
    if (parts.length > 2) {
      // More than one decimal point is invalid
      return;
    }

    let formattedValue = '';
    if (parts[0]) {
      // Format the whole number part with Indian-style commas
      const integerPart = parts[0];
      const length = integerPart.length;

      if (length > 3) {
        const firstPart = integerPart.slice(0, length - 3); // Everything before the last 3 digits
        const lastPart = integerPart.slice(length - 3); // Last 3 digits

        formattedValue =
            firstPart.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ',' + lastPart;
      } else {
        formattedValue = integerPart;
      }
    }

    // If there's a decimal part, add it back and restrict to 2 decimal places
    if (parts.length === 2) {
      const decimalPart = parts[1].slice(0, 2); // Restricting to a maximum of 2 decimal places
      formattedValue += '.' + decimalPart;
    }

    // Update the state with the formatted value
    setValuesDue({
      ...valuesDue,
      [name]: formattedValue,
    });
  };

  const handleChange = async (
      e: React.ChangeEvent<HTMLInputElement>,
      name: string
  ) => {
    let file = e?.currentTarget?.files?.[0];
    setValues({
      ...values,
      [name]: e?.currentTarget?.files?.[0], // Convert string to number
    });
    if (file) {
      const base64 = await toBase64(file);
      setFileImage((prevFileImage) => ({
        ...prevFileImage,
        [name]: base64, // Update the specific image property
      }));
    } else {
      setFileImage({
        credit_image: '',
        cheque_image: '',
        online_image: '',
      });
    }
  };

  const toBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to convert file to Base64'));
        }
      };
      reader.onerror = () => {
        reject(new Error('Error occurred while reading the file'));
      };
    });
  };

  const uploadImageClick = (value: boolean, name: string) => {
    setIsImageUpload(value);
    setImageName(name);
    if (name === 'credit_image' && !fileImage?.credit_image) {
      setTriggerUpload(value);
    }
    if (name === 'cheque_image' && !fileImage?.cheque_image) {
      setTriggerUpload(value);
    }
    if (name === 'online_image' && !fileImage?.online_image) {
      setTriggerUpload(value);
    }
  };

  const generateRawQueryParams = (data: AnyObject): string => {
    const queryParts: string[] = [];
    let index = 0;

    for (const key in data) {
      const item = data[key];

      queryParts.push(`item_ids[${index}]=${item.id}`);

      if (item.checked) {
        queryParts.push(
            `quantity[${index}]=${Boolean(item.quantity) ? item.quantity : 0}`
        );
      } else {
        queryParts.push(`quantity[${index}]=${0}`);
      }
      index++;
    }

    return queryParts.join('&');
  };

  // =========product_return =======
  async function productReturnAPi() {
    let success = false; // Добавлено: флаг успешности
    setLoadingPay(true);
    const formData = new FormData();
    const itemArray = Object.values(productValue); // Convert object to array

    // Use index to append images to FormData
    itemArray.forEach((item, index) => {
      if (item.image) {
        formData.append(`image[${index}]`, item.image);
      } else {
        formData.append(`image[${index}]`, 'null');
      }
    });

    const queryParams = generateRawQueryParams(productValue); // Generate query parameters
    const invoiceId = paymentData?.invoiceNumber; // Example invoice ID
    const returnDate = `return_date=${values.return_date ? values?.return_date : moment().utc().format('YYYY-MM-DD HH:mm:ss')}`;
    const apiUrl = `${
        HOST[process.env.NODE_ENV]
    }/api/invoices/add_payment_product_return?invoice_id=${invoiceId}&${queryParams}&${returnDate}`; // Base API with raw query

    const authToken = localStorage.getItem(TOKEN_STORAGE);
    // Simulating POST request
    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`,
        },
      });
      // alert(`Success: ${response.data?.message}`);
      if (values?.cash || values?.cheque || values?.online || values?.credit) {
        const paymentSuccess = await paymentCollectionSubmit(); // Изменено: проверка успешности
        if (paymentSuccess) {
          success = true; // Установка флага успешности
        }
      } else {
        setLoadingPay(false);
        resetData();
        setReturnItem(0);
        dispatch(clearState());
        await getPayments({ page: 1, keyword: '' });
        await refetch();
        // closePaymentModal(); // Удалено: перемещение логики закрытия модала в onSubmitPayment
        setUnPaidInvoice([]);
        setPaymentAmount(
            Number(invoiceData?.invoice_amount) -
            Number(invoiceData?.amount_received)
        );
        setTotalDue(paymentAmount);
        // setProductValue({});
        // await refetch();
        // navigate(-2);
        success = true; // Установка флага успешности
      }
    } catch (err: any) {
      let errorMessage = 'An error occurred.'; // Default message

      if (axios.isAxiosError(err)) {
        // If it's an Axios error, check for response and data
        errorMessage =
            err.response?.data?.message ?? // Custom message from server
            err.response?.statusText ?? // HTTP status text
            err.message; // General error message
      } else {
        // For non-Axios errors, use the generic message
        errorMessage = err.message || 'Unknown error.';
      }

      // Display the error message in an alert
      window.alert(errorMessage);
      success = false; // Установка флага неуспешности
    } finally {
      // setProductValue({})
      setLoadingPay(false);
    }
    return success; // Возврат флага успешности
  }

  async function paymentCollectionSubmit() {
    let success = false; // Добавлено: флаг успешности
    try {
      setLoadingPay(true);
      let formData = new FormData();
      if (fileImage) {
        if (values?.credit_image) {
          formData.append('credit_image', values?.credit_image);
        }
        if (values?.cheque_image) {
          formData.append('cheque_image', values?.cheque_image);
        }
        if (values?.online_image) {
          formData.append('online_image', values?.online_image);
        }
      }

      const authToken = localStorage.getItem(TOKEN_STORAGE);
      let invoiceId = 'invoice_id=' + invoiceData?.id;
      let cash = values?.cash
          ? '&cash=' + Number(values?.cash?.split(',').join('')).toFixed(2)
          : '';
      let online = values?.online
          ? '&online=' + Number(values?.online?.split(',').join('')).toFixed(2)
          : '';
      let cheque = values?.cheque
          ? '&cheque=' + Number(values?.cheque?.split(',').join('')).toFixed(2)
          : '';
      let credit = values?.credit
          ? '&credit=' + Number(values?.credit?.split(',').join('')).toFixed(2)
          : '';
      let payment_date = `&payment_date=${values.return_date ? values?.return_date : moment().utc().format('YYYY-MM-DD HH:mm:ss')}`;
      const response = await axios.post(
          `${
              HOST[process.env.NODE_ENV]
          }/api/payments/add?${invoiceId}${cash}${online}${cheque}${credit}${payment_date}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${authToken}`,
            },
          }
      );
      // alert(response?.data?.message)
      // window.alert(response?.data?.message); // Alert shown
      // After alert is closed, the following code executes
      dispatch(clearState());
      await getPayments({ page: 1, keyword: '' });
      success = true; // Установка флага успешности
    } catch (err: any) {
      let errorMessage = 'An error occurred.'; // Default message

      if (axios.isAxiosError(err)) {
        // If it's an Axios error, check for response and data
        errorMessage =
            err.response?.data?.message ?? // Custom message from server
            err.response?.statusText ?? // HTTP status text
            err.message; // General error message
      } else {
        // For non-Axios errors, use the generic message
        errorMessage = err.message || 'Unknown error.';
      }

      // Display the error message in an alert
      window.alert(errorMessage);
      success = false; // Установка флага неуспешности
    } finally {
      setLoadingPay(false);
      await refetch();
      // closePaymentModal();
      /*setValues({});
      setValuesDue({});
      setFileImage({
        credit_image: '',
        cheque_image: '',
        online_image: '',
      });
      setUnPaidInvoice([]);*/
      setPaymentAmount(
          Number(invoiceData?.invoice_amount) -
          Number(invoiceData?.amount_received)
      );
      setTotalDue(paymentAmount);
    }
    return success; // Возврат флага успешности
  }

  async function onSubmitPayment() {
    if (totalDue < 0) {
      window.scrollTo(0, document.body.scrollHeight);
      setDueClose(true);
    } else if (values?.cheque && !values?.cheque_image) {
      alert('No image uploaded for Cheque.');
    } else if (values?.credit && !values?.credit_image) {
      alert('No image uploaded for Credit.');
    } else if (values?.online && !values?.online_image) {
      alert('No image uploaded for Online.');
    } else {
      if (
          Object.keys(productValue).length > 0 &&
          Object.values(productValue).some(item => item.checked)
      ) {
        const success = await productReturnAPi(); // Изменено: получение флага успешности
        if (success) {
          closePaymentModal(); // Только если успешно, закрываем модалку
        }
      } else {
        if (values?.cash || values?.cheque || values?.online || values?.credit) {
          const success = await paymentCollectionSubmit(); // Изменено: получение флага успешности
          if (success) {
            closePaymentModal(); // Только если успешно, закрываем модалку
          }
        } else {
          // Handle case when no payment method is selected
          alert('Please enter an amount for at least one payment method.');
        }
      }
    }
  }

  useEffect(() => {
    if (triggerUpload) {
      const uploadInput = document.getElementById('upload-image');
      uploadInput?.click(); // Programmatically click the hidden input field
      setTriggerUpload(false); // Reset the trigger
    }
  }, [triggerUpload]);
  const [userData, setUserData] = useState<any>({});
  useEffect(() => {
    // console.log("data changed", data);
    // console.log(context?.loginUserData, 'auth')
    if (context?.loginUserData && context?.loginUserData?.role_name) {
      setUserData({ ...context?.loginUserData }); // Update state when data is available
    }
  }, [context]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // handle load data when scrolling
  useEffect(() => {
    if (isMediumScreen) {
      handleScroll();
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } else {
      const tableElement = tableRef.current;
      if (!tableElement) return;
      tableElement.addEventListener('scroll', handleScrollByTable);
      return () => {
        if (tableElement) {
          tableElement.removeEventListener('scroll', handleScrollByTable);
        }
      };
    }
  }, [page]);

  const handleScrollByTable = () => {
    if (!tableRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
    const offset = 50;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - offset;

    if (isAtBottom && loadMorePayments && !loading) {
      loadMorePayments(true);
    } else {
      loadMorePayments?.(false);
    }
  };

  const handleScroll = () => {
    const scrollTop =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop;
    const scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) ||
        document.body.scrollHeight;
    const clientHeight =
        document.documentElement.clientHeight || window.innerHeight;
    const offset = 100;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - offset;

    if (isAtBottom && loadMorePayments && !loading) {
      loadMorePayments(true);
    } else {
      loadMorePayments?.(false);
    }
  };

  const loadMoreIfNecessary = () => {
    const tableElement = tableRef.current;
    if (!tableElement) return;

    const offset = 75;
    const { scrollHeight, clientHeight } = tableElement;
    // console.log('loadMoreIfNecessary', {
    //   'scrollHeight': scrollHeight-offset,
    //   'clientHeight': clientHeight,
    //   'loadMore': loadMoreBatchs,
    //   'batchs': batchs,
    // })
    if ((scrollHeight - offset) <= clientHeight && loadMorePayments && payments.length) {
      loadMorePayments(true);
    }
  };

  useEffect(() => {
    if(!isMediumScreen) {
      // console.log('table loadmore')
      loadMoreIfNecessary();

      const observer = new ResizeObserver(() => {
        loadMoreIfNecessary();
      });

      const tableElement = tableRef.current;
      if (tableElement) {
        observer.observe(tableElement);
      }

      return () => {
        if (tableElement) {
          observer.unobserve(tableElement);
        }
      };
    }
  }, [payments.length]);

  const getUnpaidInvoices = async () => {
    const {
      data: { data },
    }: any = await getUnpaidInvoice({ page: invoicePage, keyword: '' });
    setUnPaidInvoice(data);
  };

  useEffect(() => {
    getUnpaidInvoices();
  }, []);

  // useEffect(() => {
  //   if(showAddPaymentModal) {
  //     getUnpaidInvoices();
  //   }
  // }, [showAddPaymentModal]);

  const [selectedCompany, setSelectedCompany] = useState(null);

  const companyOptions = unPaidInvoice.map((invoice: any) => ({
    value: invoice.id,
    label: `${invoice.invoice_number} ${invoice.buyer_name}`,
  }));

  const handleScrollInvoice = async () => {
    const {
      data: { data },
    }: any = await getUnpaidInvoice({ page: invoicePage + 1, keyword: '' });
    setUnPaidInvoice([...unPaidInvoice, ...data]);
    setInvoicePage(invoicePage + 1);
  };

  useEffect(() => {
    if (sortingStates.isAsc != null && sortingStates.sortBy) {
      getPaymentsSortBy?.(sortingStates);
    }
  }, [sortingStates]);

  const handleCloseDelete = () => {
    setShowDelete(false);
  };
  const handleShowDelete = (id: any) => {
    setSelecetedId(id);
    setShowDelete(true);
  };
  const [sortStack, setSortStack] = useState<paymentHead['head'][]>([]);
  const moveToTop = (value: paymentHead['head']) => {
    setSortStack((prevStack) => {
      const filteredStack = prevStack.filter((item) => item !== value);
      return [value, ...filteredStack];
    });
  };

  // Getting state coming from parent <Outlet />
  const { searchTxt, setSelectedCount, setOpenNav } = usePaymentsListState();

  // Creating headings from paymentHeadings excluding excludedHeadings
  const headings = useMemo(() => {
    return paymentHeadings.filter(
        (heading) =>
            !excludedHeadings.includes(heading.head) && heading.head !== ''
    );
  }, [excludedHeadings]);
  const [deletePayment, { isLoading: isDeleting }] = useDeletePaymentMutation();
  const [editPayment, { isLoading: isEditing }] = useEditPaymentMutation();
  const [addPayment, { isLoading: isAdding, isError: paymentError }] =
      useAddPaymentMutation();

  const handleDelete = async () => {
    try {
      await deletePayment({ payment: selectedId }).unwrap();
      const response = await getPayments({
        page: 1,
        keyword: searchTxt ? searchTxt : '',
      }).unwrap();
      // Assuming the response is in the expected format for setPayments
      setPayments(response.data);
      // Optionally show a success message
    } catch (error) {
      // Handle or display the error
    }
  };
  const [getPayments, { data, isError, isLoading, error }] =
      useGetPaymentsMutation();
  const dispatch = useAppDispatch();
  const handleEdit = async (paymentId: number | string, amount: string) => {
    if (parseInt(amount) > parseInt(selectedAmountDue)) {
      alert(
          `Amount entered is more than balance due in the invoice ${selectedInvoiceNo}`
      );
    } else {
      try {
        await editPayment({ payment_id: paymentId, amount: amount }).unwrap();
        const response = await getPayments({
          page: 1,
          keyword: searchTxt ? searchTxt : '',
        }).unwrap();
        // Assuming the response is in the expected format for setPayments
        setPayments(response.data);
        // Optionally show a success message
      } catch (error) {
        // Handle or display the error
      }
    }
  };

  const handleChangeInvoice = (e: any) => {
    if (e.value) {
      setInvoiceId(e.value);
      setPaymentData({
        ...paymentData,
        invoiceNumber: e.value,
        label: e.label,
      });
    } else {
      const { name, value } = e.target;
      setPaymentData({ ...paymentData, [name]: value });
    }
    setShowAddPaymentModal(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { invoiceNumber, amount, paymentMethod } = paymentData;

    if (parseInt(amount) <= 0) {
      alert(`Amount entered can not be zero.`);
    } else {
      try {
        const { data, error }: any = await addPayment({
          invoice_id: invoiceNumber,
          amount: amount,
          method: paymentMethod,
        });
        if (data?.message?.amount) {
          return alert(data?.message?.amount[0]);
        }
        if (error) {
          return alert(error?.data?.message);
        } else {
          dispatch(clearState());
          closePaymentModal();
          await getPayments({ page: 1, keyword: '' });
        }
      } catch (error) {
        // Handle or display the error
      }
    }
  };

  const closePaymentModal = () => {
    setPaymentData({
      invoiceNumber: '',
      paymentMethod: '',
      amount: '',
      label: '',
    });
    resetData();
    setShowAddPaymentModal(false);
  };

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [updatedPayment, setUpdatedPayment] = useState([]);
  const handleSelectAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setSelectAllChecked(checked);
    const updatedPayments = payments.map((payment) => ({
      ...payment,
      checked: checked,
    }));

    setPayments(updatedPayments);
    // setSortedPayments(updatedSortedPayments);
  };


  const searchUnpaidInvoice = useCallback(
      debounce(async (e: any) => {

        if (controllerRef.current) {
          controllerRef.current.abort();
        }

        try {
          const controller = new AbortController();
          const signal = controller.signal;
          controllerRef.current = controller;

          const result = await getUnpaidInvoice({
            page: 1,
            keyword: e?.target?.value || '',
            signal,
          }).unwrap();

          return setUnPaidInvoice(result.data);
        } catch (error) {
          return false; // Return false to exclude the option in case of error
        }
      }, 1000), // Delay of 1 second (1000 milliseconds)
      []
  );

  // function display date by local timezone
  const formatDate = (dateString: string) => {
    const utcMoment = moment.utc(dateString);
    const localMoment = utcMoment.local();

    // check if the year is same with current year
    const currentYear = moment().year();
    const dateYear = localMoment.year();

    let formattedDate =
        dateYear === currentYear
            ? localMoment.format('DD MMM')
            : localMoment.format('DD MMM, YYYY');

    return formattedDate;
  };

  // handle detail item
  const handleDetailItem = () => {
    if (handleDetail) {
      handleDetail('payment');
    }
  };

  // handle step after choose invoice
  useEffect(() => {
    if (paymentData.invoiceNumber !== '') {
      setStep(2);
      if (unPaidInvoice.length) {
        const data = unPaidInvoice.find(
            (option: any) => option.id === paymentData.invoiceNumber
        );
        if (data) {
          setSelectedPayment(data);
        }
      }
    } else {
      setStep(1);
    }
  }, [paymentData.invoiceNumber]);

  useEffect(() => {
    if(selectedDate) {
      const newDate = new Date(selectedDate);

      const currentTime = new Date();
      newDate.setHours(currentTime.getHours());
      newDate.setMinutes(currentTime.getMinutes());
      newDate.setSeconds(currentTime.getSeconds());
      newDate.setMilliseconds(currentTime.getMilliseconds());

      const dateTemp = moment(newDate).utc().format('YYYY-MM-DD HH:mm:ss');
      setValues((prev) => ({ ...prev, return_date: dateTemp }));
    }
  }, [selectedDate]);

  const onInputChange = (inputValue: any, event: any) => {
    if (event.action==='input-change'){
      setSearchInvoiceNumber(inputValue)
    }
  }
  return (
      <>
        <table className="payment-table-component">
          <thead>
          <tr>
            {headings.map((heading) => (
                <PaymentTableTh
                    key={heading.head}
                    heading={heading}
                    setSortingStates={setSortingStates}
                    moveToTop={moveToTop}
                    withSorting={showCheckbox}
                />
            ))}
            <th className="action">
              <span className="unsorted">Action</span>
            </th>
          </tr>
          </thead>
          <tbody ref={tableRef}>
          {payments.map((payment: any, index: any) => (
              <tr
                  key={index}
                  className={`bg-white  ${!showCheckbox && 'no-checkbox'}`}
              >
                {/* {showCheckbox && (

              )} */}

                {/* <div className="link-wrapper mobile-only payment-row">
                  <div className="title-area">
                    <div className="info">
                      <Link
                        to={`/dashboard/invoices/${payment?.invoice_id}`}
                        className="buyer-name"
                        style={{ color: '#0080FC !important' }}
                      >
                        {'ID# '} {payment.id}
                      </Link>
                      <span className={`payment-amount`}>
                        ₹{numberWithCommas(payment.amount)}
                        <span>({payment?.method || 'Cash'})</span> */}
                <div className="link-wrapper mobile-only payment-row">
                  <div className="title-area">
                    <div className="info">
                      <Link
                          to={`/dashboard/invoices/${payment?.invoice_id}`}
                          className="buyer-name"
                          style={{ color: '#0080FC !important' }}
                      >
                        {'ID# '} {payment.id}
                      </Link>
                      <span className={`payment-amount`}>
                      ₹{numberWithCommas(payment.amount)}
                        <span>({payment?.method || 'Cash'})</span>
                    </span>
                    </div>
                    <div className="btns">
                    <span className="payments_btns mobile">
                      {payment?.payment?.image_path ? (
                          <span className="edit-btn">
                          <View
                              onClick={() => {
                                setImagePath(payment?.payment?.image_path);
                                setShowImage(true);
                                setSelectedImage({id: payment?.id, invoice_number: payment?.invoice?.invoice_number, buyer_name: payment?.invoice?.buyer?.name});
                              }}
                          />
                        </span>
                      ) : (
                          ''
                      )}
                      <span className="edit-btn">
                        <Edit
                            onClick={() =>
                                handleShowEdit(
                                    payment.id,
                                    payment.amount,
                                    payment?.invoice?.amount_due,
                                    payment.invoice.invoice_number
                                )
                            }
                        />
                      </span>
                      <span className="delete-btn">
                        <Delete
                            onClick={() =>
                                handleShowDelete({
                                  id:
                                      payment.method === 'Product Return'
                                          ? payment.invoice_id
                                          : payment.id,
                                  method: payment.method,
                                })
                            }
                        />
                      </span>
                    </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="buyer-name2">{payment?.buyer_name}</p>
                    {/* <a className="buyer-name" href="">{payment?.invoice?.invoice_number}</a> */}
                  </div>

                  <div className="payment-dcc">
                    <p>
                      <span className="block-title">Date</span>
                      <span className="block-value">
                      {formatDate(payment?.created_at)}
                    </span>
                    </p>
                    <hr />
                    <p>
                      <span className="block-title">Collected By</span>
                      <span className="block-value">
                      {payment?.user_name || 'JohnDoe'}
                    </span>
                    </p>
                    <hr />
                    <p>
                      <span className="block-title">Company</span>
                      <span className="block-value">{payment?.company_name}</span>
                    </p>
                  </div>
                </div>
                <Link
                    to={`/dashboard/invoices/${payment?.invoice_id}`}
                    className="link-wrapper desktop-only"
                    // onClick={() => navigate(detailLink(payment))}
                    onClick={() => handleDetailItem()}
                >
                  <td className={`payment-no align-middle id`}>
                  <span
                      className="buyer-name"
                      style={{ color: '#0080FC !important' }}
                  >
                    {payment.id}
                  </span>
                  </td>
                  <td className={`payment-no align-middle date`}>
                    <span>{formatDate(payment?.created_at)}</span>
                    {/*<Link to={detailLink(payment)} className="view-detail">*/}
                    {/*  View Detail*/}
                    {/*</Link>*/}
                  </td>
                  {/*<td className={`date other ${!payment.overdueBy && "last"}`}>*/}
                  <td className={`companyname other last align-middle`}>
                    {payment?.company_name}
                  </td>
                  <td className="collectedby align-middle">
                    {payment?.user_name || 'Jane Doe'}
                  </td>
                  <td className="invoiceno other align-middle">
                    {payment?.invoice?.invoice_number}
                  </td>
                  <td className="buyer align-middle">{payment?.buyer_name}</td>
                  <td className="paymentmethod other align-middle">
                    {payment?.method
                        ? payment?.method === 'Return'
                            ? 'Product Return'
                            : payment?.method
                        : 'Cash'}
                  </td>
                  <td className="amount other align-middle">
                    ₹{numberWithCommas(payment.amount)}
                  </td>
                </Link>
                <td
                    className="action align-middle desktop-only"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                >
                  <div className="d-flex justify-content-around">
                  <span
                      style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                  >
                    {payment?.method !== 'Product Return' &&
                    payment?.payment?.image_path ? (
                        <View
                            onClick={() => {
                              setImagePath(payment?.payment?.image_path);
                              setShowImage(true);
                              setSelectedImage({id: payment?.id, invoice_number: payment?.invoice?.invoice_number, buyer_name: payment?.invoice?.buyer?.name});
                            }}
                        />
                    ) : (
                        ''
                    )}
                  </span>
                    <span
                        style={{
                          flex: 1,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                    >
                    <Edit
                        onClick={() =>
                            handleShowEdit(
                                payment.id,
                                payment.amount,
                                payment.invoice.amount_due,
                                payment.invoice.invoice_number
                            )
                        }
                    />
                  </span>
                    <span
                        style={{
                          flex: 1,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                    >
                    <Delete
                        onClick={() =>
                            handleShowDelete({
                              id:
                                  payment.method === 'Product Return'
                                      ? payment.invoice_id
                                      : payment.id,
                              method: payment.method,
                            })
                        }
                    />
                  </span>
                  </div>
                </td>
              </tr>
          ))}
          {loading && (
              <tr className="loading-row">
                <LoadingTd cols={headings.length + (showCheckbox ? 1 : 0)} />
              </tr>
          )}
          {!loading && (
              <span className="batchlist-table-footer">End of the list</span>
          )}
          </tbody>
        </table>

        <Modal
            className="custom-modal payment-modal"
            show={showEdit}
            onHide={handleCloseEdit}
            centered
        >
          <Modal.Header
              style={{
                margin: 0,
                padding: '20px 20px 16px 20px',
                borderRadius: '12px 12px 0 0',
                backgroundColor: '#ffffff',
              }}
          >
            <Modal.Title style={{ color: '#000000', fontSize: '20px', fontWeight: 600, letterSpacing: '-0.1px' }}>Edit Payment Amount</Modal.Title>
            <button style={{ backgroundColor: 'transparent', margin: '-8px 0 -8px -8px' }} className="Close_btn" onClick={()=> setShowEdit(false)}>
              <CloseModal/>
            </button>
          </Modal.Header>
          <Modal.Body style={{ padding: '0px 20px 20px 20px' }}>
            <div style={{ display: 'block', height: 1, width: '100%', backgroundColor: '#EAECF0', marginBottom: 20 }}></div>
            <p style={{ margin: '0px 0px 10px', lineHeight: 'normal' }}>Payment Amount</p>
            <Form.Control
                type="text"
                placeholder={`₹${numberWithCommas(selectedAmount)}`}
                onChange={handleChangeAmount}
            />
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: 'rgb(250, 250, 252)', borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTop: 'none' }}>
            <Button
                variant="primary"
                onClick={() => {
                  handleEdit(selectedId, paymentAmountUpdated);
                  setShowEdit(false);
                }}
            >
              Change
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDelete} onHide={handleCloseDelete} centered>
          <Modal.Header>
            <Modal.Title
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
            >
              <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                  }}
              >
                <CircleRed />
                <span style={{ marginLeft: '10px', marginTop: 24 }}>
                Are you sure you want to delete this Payment?
              </span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>
          <Modal.Footer className={'delete-modal-footer'}>
            <button
                className={'btn btn-secondary ms-2 px-4 cancel-delete-button'}
                onClick={handleCloseDelete}
            >
              <span style={{ fontWeight: 600, fontSize: 14 }}>No</span>
            </button>
            <Button
                variant="primary"
                className="conform-delete-button"
                onClick={() => {
                  handleDelete();
                  setShowDelete(false);
                }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
            className="custom-modal"
            show={showAddPaymentModal}
            onHide={closePaymentModal}
            centered
        >
          <Modal.Header
              style={{
                margin: 0,
                padding: '20px',
                borderRadius: '12px 12px 0 0',
                backgroundColor: '#ffffff',
              }}
          >
            <Modal.Title style={{ color: '#667085', fontSize: '16px', fontWeight: 500 }}>
              Add Payment
            </Modal.Title>
            <button style={{ backgroundColor: 'transparent', margin: '-8px 0 -8px -8px' }} className="Close_btn" onClick={()=> setShowAddPaymentModal(false)}>
              <CloseModal/>
            </button>
          </Modal.Header>
          <Modal.Body style={{ padding: '0px 20px 10px 20px' }}>
            <Form>
              {step === 1 && (
                  <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                  >
                    <Select

                        options={companyOptions}
                        onChange={(event) => {
                          handleChangeInvoice(event);
                          // if (searchInvoiceNumber !== '') {
                          //   getUnpaidInvoices();
                          // }
                        }}
                        value={companyOptions.find(
                            (option: any) => option.value === paymentData.invoiceNumber
                        )}
                        inputValue={searchInvoiceNumber}
                        onInputChange={onInputChange}
                        placeholder="Select Invoice"
                        onMenuScrollToBottom={handleScrollInvoice}
                        isLoading={isInvoiceLoading}
                        styles={customStyles}
                        onKeyDown={(e) => {
                          setUnPaidInvoice([]);
                          searchUnpaidInvoice(e as any);
                        }}
                        components={{ DropdownIndicator: CustomDropdownIndicator as any, IndicatorSeparator: CustomSeparator }}
                    />
                  </Form.Group>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer
              className="modal-footer"
              style={{ border: 'none', padding: '12px 20px 12px 20px', backgroundColor: '#FAFAFC'}}
          >
            <Button
                variant={
                  selectedPayment?.invoice_number === '' ? 'secondary' : 'primary'
                }
                style={{ padding: '9px 16px 9px 16px', margin: 0, borderRadius: 8, width: 88, fontWeight: 500, fontSize: 14, opacity: 1}}
                disabled={selectedPayment === null || selectedPayment?.invoice_number === ''}
                onClick={() => {
                  if (!loadingPay) {
                    setStep(2);
                    setShowAddPaymentModal(false);
                  }
                }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal className={`custom-add-payment-modal ${isShowReturnModal ? 'bg-transparent' : ''}`} show={step === 2} fullscreen>
          <Modal.Body style={{ padding: 15}} className={`${isShowReturnModal ? 'bg-transparent' : ''}`}>
            {isShowReturnModal ? (
                <HandleModelReturn
                    setPrice={setPrice}
                    setReturnItem={setReturnItem}
                    setProductValue={setProductValue}
                    productValue={productValue}
                    isShowModal={isShowReturnModal}
                    setIsShowModal={setIsShowReturnModal}
                    customInvoiceId={invoiceData?.id}
                    productHead={productHead}
                    modalSize={'custom-modal-md'}
                />
            ) : isImageUpload ? (
                <>
                  <UploadImage
                      setisImageUpload={setIsImageUpload}
                      handleChange={handleChange}
                      fileImage={fileImage}
                      setFileImage={setFileImage}
                      imageName={imageName}
                  />
                </>
            ) : (
                <div className="d-flex justify-content-center align-items-center scroll-bottom-delivery">
                  <div className="payment-main-component mb-1 mb-md-0 pb-5 pb-md-0">
                    <ul className="list-unstyled overflow-hidden">
                      <li className="payment-head" style={{  backgroundColor: 'white', padding: '12px 40px' }}>
                        <div className="d-flex flex-row justify-content-between pt-md-0">
                          <div className="fw-bold align-self-center">
                            <p className="text-start p-0 m-0" style={{ fontWeight: 500, fontSize: 18, color: '#667085' }}>
                              {/* {paymentData?.invoiceNumber} -  */}
                              {paymentData?.label ? paymentData?.label : 'abcd ltd'}
                            </p>
                          </div>
                          <div className='mobile-only'>
                            <button style={{ backgroundColor: 'transparent', margin: 0 }} className="Close_btn" onClick={()=> {setShowAddPaymentModal(true);setStep(1);}}>
                              <CloseModal/>
                            </button>
                          </div>
                        </div>
                      </li>
                      <li className="p-0 px-md-4">
                        <div className="payment-body">
                          <div
                              className="to-be-collected d-flex justify-content-between payment-body-head p-3"
                              style={{ color: '#0080FC' }}
                          >
                            <div className="">
                          <span
                              className="align-self-center"
                              style={{ fontWeight: '500' }}
                          >
                            To be collected
                          </span>
                            </div>
                            <div>
                          <span style={{ fontWeight: '400' }}>
                            ₹
                            <span style={{ fontWeight: '500' }}>
                            {paymentAmount
                                ? numberWithCommas(paymentAmount).trim()
                                : '00.00'}
                            </span>
                          </span>
                            </div>
                          </div>
                          {/* ----for desktop */}
                          <div className="pb-1 d-none d-md-block">
                            <div
                                className="d-flex justify-content-between px-3"
                                style={{
                                  color: '#1D2939',
                                  background: '#F3F4F7',
                                  paddingTop: '10px',
                                  paddingBottom: '10px',
                                }}
                            >
                              <div
                                  className=""
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    color: '#667085',
                                  }}
                              >
                                Due Amount
                              </div>
                              <div style={{ color: '#667085', fontWeight: '400' }}>
                                ₹
                                {isNaN(totalDue) ? (
                                    <span
                                        style={{ fontSize: '16px', fontWeight: '500', color: '#667085' }}
                                    >
                                {paymentAmount
                                    ? numberWithCommas(paymentAmount).trim()
                                    : '00.00'}
                              </span>
                                ) : (
                                    <span
                                        style={{ fontSize: '16px', fontWeight: '500', color: '#667085' }}
                                    >
                                {totalDue && parseInt(totalDue) !== 0
                                    ? numberWithCommas(totalDue).trim()
                                    : '00.00'}
                              </span>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* ---for mobile */}
                          <div
                              className="to-be-collected d-block d-md-none payment-body-head px-3"
                              style={{
                                color: '#1D2939',
                                padding: '10px 12px',
                                backgroundColor: '#F3F4F7',
                              }}
                          >
                            <div
                                className="d-flex justify-content-between rounded-3"
                                style={{
                                  color: '#1D2939',
                                }}
                            >
                              <div
                                  className=""
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    color: '#667085',
                                  }}
                              >
                                Due Amount
                              </div>
                              <div style={{ color: '#667085', fontWeight: '400'}}>
                                ₹
                                {isNaN(totalDue) ? (
                                    <span
                                        style={{ fontSize: '16px', fontWeight: '500', color: '#667085' }}
                                    >
                                {paymentAmount
                                    ? numberWithCommas(paymentAmount).trim()
                                    : '00.00'}
                              </span>
                                ) : (
                                    <span
                                        style={{ fontSize: '16px', fontWeight: '500 ',color: '#667085' }}
                                    >
                                {totalDue && parseInt(totalDue) !== 0
                                    ? numberWithCommas(totalDue).trim()
                                    : '00.00'}
                              </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="px-3 py-2 pb-md-0">
                            <div className="pb-2">
                              <label htmlFor="datepicker" className="form-label mb-1">
                                Payment Date
                              </label>
                              <div className="date-range-picker">
                                <button
                                    id="datepicker"
                                    type="button"
                                    className="btn btn-light btn-date-range btn-action"
                                    onClick={() => setViewCalender(!viewCalender)}
                                    style={{
                                      width: '100%',
                                      backgroundColor: '#FCFCFD',
                                      height: '38px',
                                      textAlign: 'left',
                                      padding: '9px 10px',
                                      border: '1px solid #EAECF0',
                                      borderRadius: 8,
                                    }}
                                >
                              <span style={{ color: '#475467', opacity: dateColor === '' ? 0.5 : 1, fontWeight: dateColor === '' ? 400 : 500 }}>
                                {selectedDate
                                    ? moment(selectedDate).format('DD/MM/YYYY')
                                    : 'DD/MM/YYYY'}
                              </span>
                                </button>
                                {viewCalender && (
                                    <div className={`drp_popover ${moment(selectedDate).isSame(moment(), 'day') ? '' : 'custom-current-date'} custom-addpayment-date`}>
                                      <DateRange
                                          editableDateInputs={false}
                                          onChange={(item) => {
                                            setSelectedDate(
                                                item.selection.startDate as any
                                            );
                                            setDateColor('#000000');
                                            setViewCalender(false);
                                          }}
                                          showDateDisplay={false}
                                          moveRangeOnFirstSelection={false}
                                          dateDisplayFormat="DD/MM/YYYY"
                                          showMonthAndYearPickers={true}
                                          weekdayDisplayFormat={'EEEEEE'}
                                          ranges={[
                                            {
                                              startDate: selectedDate,
                                              endDate: selectedDate,
                                              key: 'selection',
                                            },
                                          ]}
                                      />
                                    </div>
                                )}
                              </div>
                            </div>
                            <div className="pb-2 custom-payment-input" style={{ position: 'relative' }}>
                              <label htmlFor="cash" className="form-label mb-1">
                                Cash
                              </label>
                              <span className="currency-symbol" style={{ fontWeight: '400', fontSize: 14, lineHeight: 'normal', position: 'absolute', top: 38, left: 12, color: `${values.cash ? '#475467' : '#98A2B3'}` }}>₹</span>
                              <input
                                  type="text"
                                  inputMode="decimal"
                                  className="form-control payment-input"
                                  id="cash"
                                  placeholder={`00.00`}
                                  name="cash"
                                  value={
                                    values.cash !== undefined ? values.cash : ''
                                  }
                                  onChange={handleInputChange}
                                  onBlur={handleBlur}
                                  style={{
                                    border: '1px solid #EAECF0',
                                    borderRadius: 8,
                                    height: 38,
                                  }}
                              />
                            </div>
                            <div className="pb-2 custom-payment-input">
                              <label htmlFor="online" className="form-label  mb-1">
                                Online
                              </label>
                              <div className="input-div">
                                <span className="currency-symbol" style={{ fontWeight: '400', fontSize: 14, lineHeight: 'normal', position: 'absolute', top: 10.6, left: 12, color: `${values.online ? '#475467' : '#98A2B3'}` }}>₹</span>
                                <input
                                    type="text"
                                    inputMode="decimal"
                                    className="form-control payment-input"
                                    id="online"
                                    placeholder={`00.00`}
                                    name="online"
                                    value={
                                      values.online !== undefined
                                          ? values.online
                                          : ''
                                    }
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    style={{
                                      border: '1px solid #EAECF0',
                                      borderRadius: 8,
                                      height: 38,
                                    }}
                                />
                                <span
                                    className="input-camara-image"
                                    style={{
                                      cursor: 'pointer',
                                      height: '31px',
                                      width: '31px',
                                      top: '3.5px',
                                    }}
                                >
                              {fileImage?.online_image ? (
                                  <CamaraChecked
                                      style={{ height: '100%', width: '100%' }}
                                      onClick={() =>
                                          uploadImageClick(true, 'online_image')
                                      }
                                  />
                              ) : (
                                  <CameraUncheckedBlue
                                      style={{ height: '100%', width: '100%' }}
                                      onClick={() =>
                                          uploadImageClick(true, 'online_image')
                                      }
                                  />
                              )}
                            </span>
                              </div>
                            </div>
                            <div className="pb-2 custom-payment-input">
                              <label htmlFor="cheque" className="form-label  mb-1">
                                Cheque
                              </label>
                              <div className="input-div">
                                <span className="currency-symbol" style={{ fontWeight: '400', fontSize: 14, lineHeight: 'normal', position: 'absolute', top: 10.6, left: 12, color: `${values.cheque ? '#475467' : '#98A2B3'}` }}>₹</span>
                                <input
                                    type="text"
                                    inputMode="decimal"
                                    className="form-control payment-input"
                                    id="cheque"
                                    placeholder="00.00"
                                    name="cheque"
                                    value={
                                      values.cheque !== undefined
                                          ? values.cheque
                                          : ''
                                    }
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    style={{
                                      border: '1px solid #EAECF0',
                                      borderRadius: 8,
                                      height: 38,
                                    }}
                                />
                                <span
                                    className="input-camara-image"
                                    style={{
                                      cursor: 'pointer',
                                      height: '31px',
                                      width: '31px',
                                      top: '3.5px',
                                    }}
                                >
                              {fileImage?.cheque_image ? (
                                  <CamaraChecked
                                      style={{ height: '100%', width: '100%' }}
                                      onClick={() =>
                                          uploadImageClick(true, 'cheque_image')
                                      }
                                  />
                              ) : (
                                  <CameraUncheckedBlue
                                      style={{ height: '100%', width: '100%' }}
                                      onClick={() =>
                                          uploadImageClick(true, 'cheque_image')
                                      }
                                  />
                              )}
                            </span>
                              </div>
                            </div>
                            <div className="pb-2 custom-payment-input">
                              <label htmlFor="credit" className="form-label mb-1">
                                Credit
                              </label>
                              <div className="input-div">
                                <span className="currency-symbol" style={{ fontWeight: '400', fontSize: 14, lineHeight: 'normal', position: 'absolute', top: 10.6, left: 12, color: `${values.credit ? '#475467' : '#98A2B3'}` }}>₹</span>
                                <input
                                    type="text"
                                    inputMode="decimal"
                                    className="form-control payment-input"
                                    id="credit"
                                    placeholder="00.00"
                                    name="credit"
                                    value={
                                      values.credit !== undefined
                                          ? values.credit
                                          : ''
                                    }
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    style={{
                                      border: '1px solid #EAECF0',
                                      borderRadius: 8,
                                      height: 38,
                                    }}
                                />
                                <span
                                    className="input-camara-image"
                                    style={{
                                      cursor: 'pointer',
                                      height: '31px',
                                      width: '31px',
                                      top: '3.5px',
                                    }}
                                >
                              {fileImage?.credit_image ? (
                                  <CamaraChecked
                                      style={{ height: '100%', width: '100%' }}
                                      onClick={() =>
                                          uploadImageClick(true, 'credit_image')
                                      }
                                  />
                              ) : (
                                  <CameraUncheckedBlue
                                      style={{ height: '100%', width: '100%' }}
                                      onClick={() =>
                                          uploadImageClick(true, 'credit_image')
                                      }
                                  />
                              )}
                            </span>
                              </div>
                            </div>
                            <div className="pb-md-2">
                              <label
                                  htmlFor="payment_return"
                                  className="form-label mb-1"
                              >
                                Product Returns
                              </label>
                              <div className="row custom-payment-input" style={{ position: 'relative' }}>
                                <span className="currency-symbol" style={{width: '20%', fontWeight: '400', fontSize: 14, lineHeight: 'normal', position: 'absolute', top: 10.6, left: 12, color: `${priceReturn !== '' ? '#475467' : '#98A2B3'}` }}>₹</span>
                                <div className="col-10 col-md-10" >
                                  <input
                                      type="text"
                                      className="form-control payment-input"
                                      id="payment_return"
                                      name="payment_return"
                                      placeholder="00.00"
                                      value={parseInt(priceReturn?.replace('₹', '')) === 0 ? '00.00' : priceReturn?.replace('₹', '')}
                                      onChange={handleInputChange}
                                      disabled
                                      style={{
                                        border: '1px solid #EAECF0',
                                        borderRadius: 8,
                                        height: 38,
                                      }}
                                  />
                                </div>
                                <span
                                    className="col-2 text-decoration-underline align-self-center AddLink"
                                    onClick={() => setIsShowReturnModal(true)}
                                >
                              Add
                            </span>
                              </div>
                            </div>

                            {totalDue < 0 && dueClose && (
                                <div className="d-none d-md-block">
                                  <div className="mx-3 mx-md-5 text-center py-2 due_amount_error">
                                    Amount entered is more than the balance due.
                                    <CloseX
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setDueClose(false)}
                                    />
                                  </div>
                                </div>
                            )}
                          </div>
                          <div className="payment-footer d-none d-md-block" style={{ padding: '12px 16px' }}>
                            <div className="text-end d-flex justify-content-end gap-3">
                              <button
                                  className={`d-flex flex-row justify-content-center btn-payment-cancel`} style={{ width: 130, fontWeight: '400' }}
                                  onClick={() => {
                                    if (!loadingPay) {
                                      resetData();
                                      setStep(1);
                                      setShowAddPaymentModal(true);
                                    }
                                  }}
                                  disabled={loadingPay}
                              >
                                <div>Cancel</div>
                              </button>
                              <button style={{ width: 130, fontWeight: '400' }}
                                      className={`d-flex flex-row justify-content-center ${
                                          totalDue >= 0
                                              ? 'btn-payment-save-blue'
                                              : 'btn-payment-save'
                                      }`}
                                      onClick={loadingPay ? undefined : onSubmitPayment}
                                      disabled={loadingPay}
                              >
                                <div>
                                  {loadingPay ? (
                                      <Loading
                                          className="loadingCircle me-2"
                                          style={{ height: '20px', width: '20px' }}
                                      />
                                  ) : (
                                      ''
                                  )}
                                </div>
                                <div>Save</div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className=" payment-footer-mob d-block d-md-none w-100">
                    {totalDue != 0 && dueClose && (
                        <div className="d-block d-md-none">
                          <div className="mx-0 px-2 mb-1 mx-md-5 text-center py-2 due_amount_error">
                            Due amount must be ₹0 to save.
                            <CloseX
                                style={{ cursor: 'pointer' }}
                                onClick={() => setDueClose(false)}
                            />
                          </div>
                        </div>
                    )}
                    <div className="p-3 payment-footer-mob-btn">
                      <button
                          className={`w-100 ${
                              totalDue == 0 ? 'btn-payment-save-blue' : 'btn-payment-save'
                          }`}
                          onClick={loadingPay ? undefined : onSubmitPayment}
                          disabled={loadingPay}
                      >
                        <div>
                          {loadingPay ? (
                              <Loading
                                  className="loadingCircle me-2"
                                  style={{ height: '20px', width: '20px' }}
                              />
                          ) : (
                              ''
                          )}
                        </div>
                        <div>Save</div>
                      </button>
                    </div>
                  </div>
                </div>
            )}
          </Modal.Body>
        </Modal>

        {/* <Modal
        className="custom-modal"
        show={showAddPaymentModal}
        onHide={closePaymentModal}
        centered
      >
        <Modal.Header
          closeButton
          style={{
            margin: '20px 16px 24px 16px',
            padding: 0,
            paddingBottom: 2,
          }}
        >
          <Modal.Title>Add Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Invoice Number</Form.Label>
              <Select
                options={companyOptions}
                onChange={handleChange}
                value={companyOptions.find(
                  (option: any) => option.value === paymentData.invoiceNumber
                )}
                placeholder="Select Invoice"
                onMenuScrollToBottom={handleScrollInvoice}
                isLoading={isInvoiceLoading}
                styles={customStyles}
                onKeyDown={searchUnpaidInvoice as any}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">
              <Form.Label>Payment Method</Form.Label>
              <Form.Select
                name="paymentMethod"
                value={paymentData.paymentMethod}
                onChange={handleChange}
                style={{
                  fontSize: '14px',
                  color: !paymentData.paymentMethod ? '#5d6f81' : '#212529',
                }}
              >
                <option key={-1} disabled value={''}>
                  Select Payment Method
                </option>
                {['Cash', 'Online', 'Cheque'].map((type, index) => (
                  <option style={{ color: '#5d6f81' }} key={index} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                name="amount"
                value={paymentData.amount}
                onChange={handleChange}
                placeholder={`₹00.00`}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer
          className="modal-footer"
          style={{ border: 'none', paddingTop: '0' }}
        >
          <Button
            variant={
              Object.values(paymentData).some((value) => value === '')
                ? 'secondary'
                : 'primary'
            }
            style={{ padding: '6px 16px !important' }}
            disabled={Object.values(paymentData).some((value) => value === '')}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal> */}
      <PreviewImage 
      src={imagePath} 
      show={showImage} 
      handleClose={handleCloseImageModal} 
      updateURL={(val: string)=> handleModifyData?.(selectedImage?.id as number, val)} 
      id={selectedImage?.invoice_number} 
      title={selectedImage?.buyer_name} />
        {/* <Modal
            show={showImage}
            onHide={handleCloseImageModal}
            size="lg"
            style={{ background: '#02020278', height: '100%' }}
        >
          <Modal.Header
              closeButton
              style={{ padding: '15px 20px' }}
          ></Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center align-content-center">
              <img
                  src={imagePath}
                  alt="image"
                  className="img-fluid"
                  style={{ maxWidth: '100%', height: 'auto' }}
              />
            </div>
          </Modal.Body>
        </Modal> */}
      </>
  );
}
