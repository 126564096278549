import { useContext, useEffect, useMemo, useState } from "react";
import "./employees-list.scss";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useSetOpenNav } from "../../index";
import { ReactComponent as BurgerSvg } from "@/assets/svgs/burger.svg";
import { LoginUserContext } from "@/App";

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  email_verified_at: null | string;
  role_id: number;
  image: null | string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  is_deleted: boolean;
  name: string;
  role_name: string;
  image_path: string;
  loading: boolean;
  role: any;
}

export default function EmployeeHeader(props: any) {
  const {
    setInformationOpen = () => {},
    setSearchText = (text: string) => {},
  } = props;
  const searchText: string = props.searchText;
  const [loading, setLoading] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const context = useContext(LoginUserContext);
  const [loginUserData, setLoginUserData] = useState<User | null>(null);

  const { setOpenNav } = useSetOpenNav();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMediumScreen = useMemo<boolean>(() => {
    return windowWidth <= 900;
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = async () => {
    setInformationOpen();
  };

  useEffect(() => {
    if (context?.loginUserData) {
      const data = context?.loginUserData?.role?.permissions;
      const filteredData = data.filter((item: any) => item.id === 20);
      if (filteredData.length > 0) {
        setCanCreate(true);
      } else {
        setCanCreate(false);
      }
    }
  }, [context]);

  useEffect(() => {
    if (context?.loginUserData) {
      const data: any = context?.loginUserData;
      setLoginUserData(data);
    }
  }, []);
  
  return (
    <div className="employees-list-component">
      <div className="nav-header" style={{ borderRadius: isMediumScreen ? 0 : '8PX 8PX 0 0' }}>
        <div
          onClick={() => setSearchText("")}
          className="nav-links d-flex justify-content-between align-items-center w-md-100 g-0  m-0 text-12"
        >
          <span className="navbar-link" style={{ fontSize: 16, letterSpacing: 0, fontWeight: 500 }}>Employees</span>
        </div>
        <div className="employee-right-bar">
          <div className="search-bar-input" style={{ height: isMediumScreen ? 42 : 36 }}>
            <BurgerSvg className="burger" onClick={() => setOpenNav(true)} />
            <img src={"/assets/Icon/Search.svg"} alt="User Avatar" />
            <input
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              type="search"
              placeholder="Search Employee or Department"
            />
          </div>

          {loginUserData?.role?.permissions?.some((user: {name: string}) => user.name === 'employees_users_create') && (
            <>
              {/* <div className="bg-dark p-2 position-absolute bottom-0 w-100 d-flex align-items-center justify-content-center position-fixed ">
                <button onClick={handleSubmit} className="employee-create-batch">
                      <img src={"/assets/Icon/Add.svg"} alt="User Avatar" />
                      <span>{
                      "Add Employee"
                      }</span>
                  </button>
              </div> */}
              <button
                onClick={handleSubmit}
                className="employee-create-batch d-none d-md-block"
                style={{ width: "152px", height: "36px", padding: 0 }}
              >
                <img src={"/assets/Icon/Add.svg"} alt="User Avatar" />
                <span style={{ fontWeight: 500 }}>{"Add Employee"}</span>
              </button>
            </>
          )}
        </div>
      </div>
      {
        loginUserData?.role?.permissions?.some((user: {name: string}) => user.name === 'employees_users_create') && 
        (
          <div
        className="addEmployeee  p-4 position-absolute bottom-0 w-100 d-flex align-items-center justify-content-center position-fixed left-0 d-block d-md-none"
        style={{ marginLeft: "-16px" }}
      >
        <button onClick={handleSubmit} className="employee-create-batch w-100" style={{padding:"9px 16px"}}>
          <img src={"/assets/Icon/Add.svg"} alt="User Avatar" />
          <span className="employeeTop">{"Add Employee"}</span>
        </button>
      </div>
        )
      }
      
      {/* <div className="invoices-list-content">
        <Outlet
          context={{
            setSelectedCount,
            searchTxt,
            setSelectedInvoiceIds,
            selectedInvoiceIds,
            // setSearchTxt,
            setBatchType
          }}
        />
      </div> */}
    </div>
  );
}
