import React, {useState, useEffect, useRef, useCallback, ChangeEvent} from "react";
import './SMSNotificationSettingsCard.scss';
import {Modal, Form, ToastContainer, Toast} from "react-bootstrap";
import {TooltipWithLink} from "@components/ui/TooltipWithLink";
import Select, {SingleValue} from "react-select";
import {components} from "react-select";
import {useAddUserSmsSettings} from "@/api/fetchers/useAddUserSmsSettings";
import {useRemoveUserSmsSettings} from "@/api/fetchers/useRemoveUserSmsSettings";
import {useSetOtpStatus} from "@/api/fetchers/useSetOtpStatus";
import {useSetCategoryStatus} from "@/api/fetchers/useSetCategoryStatus";
import {smsSettings} from "@/api/sms-settings";
import debounce from "lodash/debounce";
import NewLoader from "@components/NewLoader";
import {BuyerList} from "@components/Settings/BuyerList";

interface WrapperProps {
    isOtp: boolean,
    statusOtp: boolean,
    title: string,
    description: string,
    users_detail?: { id: number; name: string }[],
    isActive?: boolean,
    cardId?: number,
    onUpdate?: () => void,
    exception_type?: string,
    user_type?: string | null,
    key?: any
    countCredit: number
    help_text?: string | undefined
}

const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        height: 10,
        border: state.isFocused ? '1px solid #E8EAED' : '1px solid #E8EAED',
        boxShadow: state.isFocused ? '0 0 0 0.25rem #b9d5ff' : '',
        fontSize: 14,
        '&:hover': {
            border: '1px solid #E8EAED;',
        },
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        fontSize: 14,
        color: state.isSelected ? 'white' : '#667085',
        borderTop: '1px solid #E8EAED',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),

};

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/assets/Icon/arrow-down-gray.svg' alt="arrow" style={{
                width: '16px',
                height: '16px',
                filter: 'invert(49%) sepia(10%) saturate(294%) hue-rotate(178deg) brightness(90%) contrast(88%)'
            }}/>
        </components.DropdownIndicator>
    );
};

interface Buyer {
    id: number;
    label: string;
    company_name: string;
}

const SMSNotificationSettingsCard: React.FC<WrapperProps> = ({
                                                                 user_type,
                                                                 cardId,
                                                                 isOtp,
                                                                 title,
                                                                 description,
                                                                 users_detail,
                                                                 isActive,
                                                                 statusOtp,
                                                                 countCredit,
                                                                 help_text,
                                                                 key
                                                             }) => {
    // Hooks
    const {isLoading, addUserSmsSettings} = useAddUserSmsSettings();
    const {removeUserSmsSettings} = useRemoveUserSmsSettings();
    const {setCategoryStatus} = useSetCategoryStatus();
    const {setOtpStatus} = useSetOtpStatus();

    // Modal
    const [showModal, setShowModal] = useState(false);

    // Buyer List
    const [buyerList, setBuyerList] = useState(users_detail || []);

    // Refs
    const wrapperRef = useRef<HTMLDivElement>(null);

    // Buyer Search
    const [buyerOptions, setBuyerOptions] = useState<Buyer[]>([]);
    const [isSearching, setIsSearching] = useState(false);

    // User Search
    const [userOptions, setUserOptions] = useState<Buyer[]>([]);
    const [isSearchingUser, setIsSearchingUser] = useState(false);

    // Buyer Selection
    const [selectedBuyer, setSelectedBuyer] = useState<Buyer | null>(null);

    // Toast Notifications
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastVariant, setToastVariant] = useState("success");

    // Checkbox State
    const [isActiveState, setIsActiveState] = useState<boolean>(isActive ?? false);
    const [statusOtpState, setStatusOtpState] = useState<boolean>(statusOtp ?? false);
    const [hasError, setHasError] = useState(false);

    // Removing State
    const [isRemoving, setIsRemoving] = useState(false);

    const isBlocked = countCredit <= 0;


    useEffect(() => {
        if (users_detail && users_detail.length > 0) {
            const sortedBuyers = [...users_detail].sort((a, b) => a.name.localeCompare(b.name));
            setBuyerList(sortedBuyers);
        }
    }, [users_detail]);

    useEffect(() => {
        setIsActiveState(isActive ?? false);
    }, [isActive]);

    const handleCheckboxChange = async () => {
        if (cardId === undefined) {
            console.error("cardId is undefined");
            return;
        }

        try {
            const newStatus = isActiveState ? 0 : 1;
            const response = await setCategoryStatus(newStatus, cardId);

            console.log('Response from setCategoryStatus:', response);

            if (response.success === false) {
                console.error("Error from server:", response.error || 'Unknown error');
                setToastMessage(response.error || 'Failed to update category status. Please try again.');
                setToastVariant('danger');
            } else {
                setIsActiveState(!isActiveState);
                setToastMessage(`${title} ${newStatus === 1 ? 'activated' : 'deactivated'} successfully.`);
                setToastVariant('success');
            }
        } catch (error) {
            console.error('Error setting category status:', error);
            setToastMessage('Failed to update category status. Please try again.');
            setToastVariant('danger');
        } finally {
            setShowToast(true);
        }
    };


    const handleOtpChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const status = value === "on" ? 1 : 0;

        if (cardId !== undefined) {
            const response = await setOtpStatus(status, cardId);

            if (response.success === false) {
                console.error("Error from server:", response.error || 'Unknown error');
                setToastMessage(response.error || 'Failed to update OTP status. Please try again.');
                setToastVariant('danger');
            } else {
                setStatusOtpState(status === 1);
                setToastMessage(`OTP ${status === 1 ? 'enabled' : 'disabled'} successfully.`);
                setToastVariant('success');
            }

            setShowToast(true);
        } else {
            console.error("cardId is undefined");
        }
    };


    const handleAddUserSmsSettings = async () => {
        if (!selectedBuyer) {
            setHasError(true);
            return;
        }

        if (selectedBuyer.id && cardId) {
            const response = await addUserSmsSettings(cardId, selectedBuyer.id);

            if (response.success === false) {
                console.error("Error from server:", response.error || 'Unknown error');
                setToastMessage(response.error || 'Failed to add user. Please try again.');
                setToastVariant('danger');
            } else {
                setBuyerList(prevBuyerList => {
                    const updatedBuyerList = [...prevBuyerList, { id: selectedBuyer.id, name: selectedBuyer.label }];
                    return updatedBuyerList.sort((a, b) => a.name.localeCompare(b.name));
                });
                setToastMessage(`User ${selectedBuyer.label} added successfully.`);
                setToastVariant('success');
            }

            setShowToast(true);
            setHasError(false);
            setShowModal(false);
        }
    };




    const handleBuyerSelect = (selectedOption: SingleValue<Buyer>) => {
        if (selectedOption) {
            setSelectedBuyer(selectedOption);
            setHasError(false);
        }
    };

    const handleRemoveBuyer = async (buyerId: number) => {
        if (isRemoving) return;

        setIsRemoving(true);

        if (cardId && buyerId) {
            const response = await removeUserSmsSettings(cardId, 'buyer', buyerId);

            if (response.success === false) {
                console.error("Error from server:", response.error || 'Unknown error');
                setToastMessage(response.error || 'Failed to remove user. Please try again.');
                setToastVariant('danger');
            } else {
                const updatedBuyerList = buyerList.filter(buyer => buyer.id !== buyerId);
                const buyerName = buyerList.find(buyer => buyer.id === buyerId)?.name;
                setBuyerList(updatedBuyerList);
                setToastMessage(`User ${buyerName} removed successfully.`);
                setToastVariant('success');
            }

            setShowToast(true);
        }

        setIsRemoving(false);
    };


    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedBuyer(null);
    }

    const renderSelectComponent = () => {
        if (user_type === 'user') {
            return (
                <Select
                    options={userOptions}
                    placeholder="Select User"
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                    onChange={handleBuyerSelect}
                    onInputChange={handleInputChange}
                    isLoading={isSearchingUser}
                />
            );
        } else if (user_type === 'buyer') {
            return (
                <Select
                    options={buyerOptions}
                    placeholder="Select Buyer"
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                    onChange={handleBuyerSelect}
                    onInputChange={handleInputChange}
                    isLoading={isSearching}
                />
            );
        } else {
            return null;
        }
    };

    const filterOptions = (options: Buyer[], existingUsers: { id: number }[]) => {
        return options.filter(option => !existingUsers.some(user => user.id === option.id));
    };

    const searchBuyers = useCallback(
        debounce(async (inputValue: string) => {
            if (inputValue.length > 2) {
                setIsSearching(true);
                try {
                    const data = await smsSettings.searchBuyers(inputValue);
                    const options = data.map((buyer: any) => ({
                        id: buyer.id,
                        label: `${buyer.name} - ${buyer.company_name}`,
                    }));
                    const filteredOptions = filterOptions(options, buyerList);
                    setBuyerOptions(filteredOptions);
                } catch (error) {
                    console.error('Error fetching buyers:', error);
                } finally {
                    setIsSearching(false);
                }
            } else {
                setBuyerOptions([]);
            }
        }, 500),
        [buyerList]
    );

    const handleInputChange = (inputValue: string) => {
        if (user_type === 'user') {
            searchUsers(inputValue);
        } else if (user_type === 'buyer') {
            searchBuyers(inputValue);
        }
    };

    const searchUsers = useCallback(
        debounce(async (inputValue: string) => {
            if (inputValue.length >= 2) {
                setIsSearchingUser(true);
                try {
                    const data = await smsSettings.searchUsers(inputValue);
                    const options = data.map((user: any) => ({
                        id: user.id,
                        label: user.name,
                    }));
                    const filteredOptions = filterOptions(options, buyerList);
                    setUserOptions(filteredOptions);
                } catch (error) {
                    console.error('Error fetching users:', error);
                } finally {
                    setIsSearchingUser(false);
                }
            } else {
                setUserOptions([]);
            }
        }, 500),
        [buyerList]
    );
    useEffect(() => {
        console.log('Updated buyerList:', buyerList);
    }, [buyerList]);

    return (
        <div>
            <div className="sms-list-component">
                <div className="nav-header-sms">
                    <div
                        className="nav-links-sms d-flex justify-content-between align-items-center w-md-100 g-0 m-0 text-16">
                        <span className="navbar-link">{title}</span>
                        <div className="d-flex flex-column">
                            <p className="fw-light" style={{fontSize: "14px", margin: 0}}>
                                <TooltipWithLink
                                    description={help_text}
                                />
                            </p>
                        </div>
                    </div>
                    <div className='wrapperOtp'>
                        {isOtp && (
                            <div className="sendOtp">
                                <label className='sendOtp'>Send OTP:</label>
                                <div className="radio-container">
                                    <label className='labelOn'>
                                        On
                                        <input
                                            type="radio"
                                            name="otpOption"
                                            value="on"
                                            checked={statusOtpState}
                                            onChange={handleOtpChange}
                                        />
                                        <span
                                            className={`custom-radio-on ${statusOtpState ? 'active' : ''}`}></span>
                                    </label>
                                </div>
                                <div className="radio-container">
                                    <label className='labelOff'>
                                        Off
                                        <input
                                            type="radio"
                                            name="otpOption"
                                            value="off"
                                            checked={!statusOtpState}
                                            onChange={handleOtpChange}
                                        />
                                        <span
                                            className={`custom-radio-off ${!statusOtpState ? 'active' : ''}`}></span>
                                    </label>
                                </div>
                            </div>
                        )}
                        {isOtp && (<div className="vertical-bar"></div>)}
                        <label className={isBlocked ? 'switch' : 'switch disabled'}>
                            <input type="checkbox"
                                   checked={isActiveState}
                                   onChange={handleCheckboxChange}
                                   disabled={isBlocked}
                            />
                            <span className="slider"></span>
                        </label>
                    </div>
                </div>
                <div className="buyer-tag ptb">
                    <div ref={wrapperRef} className={`wrapper-buyer`}>
                        <BuyerList
                            users_detail={buyerList.map(buyer => ({
                                ...buyer,
                                key: buyer.id
                            }))}
                            user_type={user_type}
                            setShowModal={setShowModal}
                            handleRemoveBuyer={handleRemoveBuyer}
                        />
                    </div>
                </div>
            </div>

            <Modal
                show={showModal}
                onHide={handleCloseModal}
                backdrop="static"
                centered
                dialogClassName="custom-modal"
            >
                <div className="modal-content" style={{borderRadius: '12px'}}>
                    <Modal.Header
                        style={{
                            padding: "25px 20px 20px 20px",
                            borderBottom: "none",
                        }}
                        closeButton
                    >
                        <div
                            style={{
                                fontSize: "14px",
                                color: "#667085",
                            }}
                        >
                            {title}
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{paddingTop: 0}}>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                {renderSelectComponent()}
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <div className="modal-footer-sms">
                        {hasError && <div style={{
                            color: 'red',
                            fontSize: '14px',
                            marginBottom: '10px',
                            marginRight: 'auto',
                            paddingTop: '10px',
                        }}>Please select a buyer</div>}
                        <button
                            className='modal-footer-sms-button'
                            onClick={handleAddUserSmsSettings}
                        >
                            {isLoading ? (
                                <div className="loading-row newLoaderAnimation">
                                    <NewLoader cols={5}/>
                                </div>
                            ) : (
                                <>
                                    Save
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </Modal>
            <ToastContainer position="bottom-center" className="p-3">
                <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                    bg={toastVariant}
                    className="text-white animate__animated animate__fadeInDown toast-centered"
                >
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

        </div>
    );
};

export default SMSNotificationSettingsCard;
