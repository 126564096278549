import { smsSettings } from "@/api/sms-settings";
import { useState } from "react";

interface SetCategoryStatusResponse {
    success: boolean;
    error?: string;
}

export const useSetCategoryStatus = () => {
    const [data, setData] = useState<SetCategoryStatusResponse | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [errorCategoryStatusErr, setCategoryStatusErr] = useState<string | null>(null);

    const setCategoryStatus = async (status: 1 | 0, id: number): Promise<SetCategoryStatusResponse> => {
        setIsLoading(true);
        setCategoryStatusErr(null);
        try {
            const res = await smsSettings.setCategoryStatus(status, id);
            setData(res);
            return res;
        } catch (err: any) {
            const errorMessage = err.response?.data?.message || err.message || "An error occurred";
            setCategoryStatusErr(errorMessage);
            return { success: false, error: errorMessage };
        } finally {
            setIsLoading(false);
        }
    };

    return {
        data,
        isLoading,
        errorCategoryStatusErr,
        setCategoryStatus,
    };
};
