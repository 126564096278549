import React, { useEffect, useMemo, useState } from "react";
import UserBody from "./UserBody";

const Users: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMediumScreen = useMemo<boolean>(() => {
    return windowWidth <= 900;
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ paddingTop: isMediumScreen ? 3 : 0 }}>
      <UserBody />
    </div>
  );
};

export default Users;
