import React from "react";
import { SMSNotificationSettingsCard } from "@components/Settings/SMSNotificationSettingsCard";
import { useGetSmsCategories } from "@/api/fetchers/useSmsCategories";
import NewLoader from "@components/NewLoader";
import { useOutletContext } from "react-router-dom";
import { SettingsContextType } from "@pages/dashboard/settings/(SettingsWrapper)/layout";

const Sms: React.FC = () => {
    const { data, isLoading, error } = useGetSmsCategories();
    const { countCredit } = useOutletContext<SettingsContextType>();

    if (isLoading) return (
        <div className="d-flex justify-content-center mt-4">
            <div className="loading-row newLoaderAnimation">
                <NewLoader cols={5} />
            </div>
        </div>
    );

    if (error) return <div>Error loading SMS categories.</div>;

    const sortedData = data ? [...data].sort((a, b) => a.id - b.id) : [];

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '21px',
        }}>
            {sortedData.map((item: any) => (
                <SMSNotificationSettingsCard
                    key={item.id}
                    cardId={item.id}
                    isActive={item.is_active}
                    isOtp={item.have_otp}
                    statusOtp={item.otp_status}
                    title={item.exception_title}
                    exception_type={item.exception_type}
                    user_type={item.user_type}
                    users_detail={item.users_detail}
                    help_text={item.help_text}
                    countCredit={countCredit}
                    description="Send SMS notification to the buyer"
                />
            ))}
        </div>
    );
};

export default Sms;
