import { useState } from 'react';
import { smsSettings } from "@/api/sms-settings";

interface SetOtpStatusResponse {
    success: boolean;
    error?: string;
}

export const useSetOtpStatus = () => {
    const [data, setData] = useState<SetOtpStatusResponse | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const setOtpStatus = async (status: 1 | 0, id: number): Promise<SetOtpStatusResponse> => {
        setIsLoading(true);
        setError(null);
        try {
            const res = await smsSettings.setOtpStatus(status, id);
            setData(res);
            return res;
        } catch (err: any) {
            const errorMessage = err.response?.data?.message || err.message || "An error occurred";
            setError(errorMessage);
            console.error(errorMessage);
            return { success: false, error: errorMessage };
        } finally {
            setIsLoading(false);
        }
    };

    return {
        data,
        isLoading,
        error,
        setOtpStatus,
    };
};
