import { useState } from 'react';
import { smsSettings } from "@/api/sms-settings";

interface RemoveUserSmsSettingsResponse {
    success: boolean;
    error?: string;
}

export const useRemoveUserSmsSettings = () => {
    const [data, setData] = useState<RemoveUserSmsSettingsResponse | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const removeUserSmsSettings = async (id: number, user_type: string, users_ids: number): Promise<RemoveUserSmsSettingsResponse> => {
        setIsLoading(true);
        setError(null);
        try {
            const res = await smsSettings.removeUserSmsSettings(id, user_type, users_ids);
            setData(res);
            return res;
        } catch (err: any) {
            const errorMessage = err.response?.data?.message || err.message || "An error occurred";
            setError(errorMessage);
            console.error(errorMessage);
            return { success: false, error: errorMessage };
        } finally {
            setIsLoading(false);
        }
    };

    return {
        data,
        isLoading,
        error,
        removeUserSmsSettings,
    };
};
