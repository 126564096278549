import { API } from "./index";

class Delivery {
    checkOtpTemplate(buyer_id: number, type: string): Promise<any> {
        return API
            .post('/check_otp_template', null, {
                params: { buyer_id, type } // Упрощение параметров
            })
            .then((res) => res.data)
            .catch((error) => {
                console.error('Error checking OTP template:', error);
                throw error;
            });
    }
}

export const delivery = new Delivery();
