import React, { FC, useState, useRef, useEffect, useCallback } from 'react';
import './BuyerList.scss';

interface UserDetail {
  id: number;
  name: string;
}

interface BuyerListProps {
  users_detail?: UserDetail[];
  user_type?: string | null;
  setShowModal: (value: boolean) => void;
  handleRemoveBuyer: (buyerId: number) => void;
}

const BuyerList: FC<BuyerListProps> = ({ users_detail = [], user_type, setShowModal, handleRemoveBuyer }) => {
  const [visibleBuyers, setVisibleBuyers] = useState<UserDetail[]>([]);
  const [hiddenBuyers, setHiddenBuyers] = useState<UserDetail[]>([]);
  const [showHidden, setShowHidden] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const updateVisibleBuyers = useCallback(() => {
    if (!containerRef.current) return;

    const containerWidth = containerRef.current.offsetWidth;
    let currentWidth = 0;
    const visible: UserDetail[] = [];
    const hidden: UserDetail[] = [];
    const itemWidth = 300;

    for (const buyer of users_detail) {
      if (currentWidth + itemWidth <= containerWidth) {
        visible.push(buyer);
        currentWidth += itemWidth;
      } else {
        hidden.push(buyer);
      }
    }

    setVisibleBuyers(visible);
    setHiddenBuyers(hidden);
  }, [users_detail]);

  useEffect(() => {
    updateVisibleBuyers();
    window.addEventListener('resize', updateVisibleBuyers);

    return () => {
      window.removeEventListener('resize', updateVisibleBuyers);
    };
  }, [updateVisibleBuyers]);

  const getButtonLabel = () => {
    if (user_type) {
      const capitalizedUserType = user_type.charAt(0).toUpperCase() + user_type.slice(1);
      return `Add ${capitalizedUserType}`;
    } else {
      return 'Add';
    }
  };

  return (
      <div className="buyer-list-container" ref={containerRef}>
        <div className="buyer-list-row">
          <span className="titleExcept">Except</span>
          <button className='btn-add' onClick={() => setShowModal(true)}>
            <svg
                style={{
                  width: '15px',
                  height: '15px',
                }}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                fill="none">
              <path d="M4 8H12" stroke="#667085" strokeWidth="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M8 12V4" stroke="#667085" strokeWidth="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
            {getButtonLabel()}
          </button>
          {visibleBuyers.map((buyer) => (
              <div key={buyer.id} className="buyer-list-col">
                <div className="buyer-list-item">
                  {buyer.name}
                  <div style={{
                    cursor: 'pointer',
                  }} onClick={() => handleRemoveBuyer(buyer.id)}>
                    <svg style={{
                        width: '9px',
                        height: '9px',
                        marginLeft: '12px',
                    }} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.62402 1.25L1.12402 7.75M1.12402 1.25L7.62402 7.75"
                            stroke="#667085"
                            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                </div>
              </div>
          ))}
          {hiddenBuyers.length > 0 && !showHidden && (
              <button className="btn-show" onClick={() => setShowHidden(true)}>
                <svg
                    style={{
                      width: '15px',
                      height: '15px',
                    }}
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                    fill="none">
                  <path d="M4 8H12" stroke="#33AAFF" strokeWidth="1.5" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  <path d="M8 12V4" stroke="#33AAFF" strokeWidth="1.5" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
                {hiddenBuyers.length}
              </button>
          )}
          {showHidden && (
              <>
                {hiddenBuyers.map((buyer) => (
                    <div key={buyer.id} className="buyer-list-col">
                      <div className="buyer-list-item">
                        {buyer.name}
                        <div style={{
                          cursor: 'pointer',
                        }} onClick={() => handleRemoveBuyer(buyer.id)}>
                          <svg style={{
                            width: '9px',
                            height: '9px',
                            marginLeft: '12px',
                          }} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.62402 1.25L1.12402 7.75M1.12402 1.25L7.62402 7.75"
                                  stroke="#667085"
                                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                ))}
                <div
                    className="btn-show-all"
                    onClick={() => setShowHidden(false)}
                    aria-label="Show Less"
                >
                  <img
                      src="/assets/Icon/arrow-chip.svg"
                      alt="Copy Icon"
                      style={{
                        width: '26px',
                        height: '26px',
                        filter: 'invert(52%) sepia(80%) saturate(4086%) hue-rotate(181deg) brightness(100%) contrast(101%)',
                      }}
                  />
                  </div>
              </>
          )}
        </div>
      </div>
  );
};

export default BuyerList;


/*

import React, {useState, useEffect, useRef, useCallback, ChangeEvent} from "react";
import './SMSNotificationSettingsCard.scss';
import {Modal, Form, ToastContainer, Toast} from "react-bootstrap";
import {TooltipWithLink} from "@components/ui/TooltipWithLink";
import Select, {SingleValue} from "react-select";
import {components} from "react-select";
import {useAddUserSmsSettings} from "@/api/fetchers/useAddUserSmsSettings";
import {useRemoveUserSmsSettings} from "@/api/fetchers/useRemoveUserSmsSettings";
import {useSetOtpStatus} from "@/api/fetchers/useSetOtpStatus";
import {useSetCategoryStatus} from "@/api/fetchers/useSetCategoryStatus";
import {smsSettings} from "@/api/sms-settings";
import debounce from "lodash/debounce";
import NewLoader from "@components/NewLoader";

interface WrapperProps {
  isOtp: boolean,
  statusOtp: boolean,
  title: string,
  description: string,
  users_detail?: { id: number; name: string }[],
  isActive?: boolean,
  cardId?: number,
  onUpdate?: () => void,
  exception_type?: string,
  user_type?: string | null,
  key?: any
  countCredit: number
}

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    height: 10,
    border: state.isFocused ? '1px solid #E8EAED' : '1px solid #E8EAED',
    boxShadow: state.isFocused ? '0 0 0 0.25rem #b9d5ff' : '',
    fontSize: 14,
    '&:hover': {
      border: '1px solid #E8EAED;',
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    color: state.isSelected ? 'white' : '#667085',
    borderTop: '1px solid #E8EAED',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),

};

const DropdownIndicator = (props: any) => {
  return (
      <components.DropdownIndicator {...props}>
        <img src='/assets/Icon/arrow-down-gray.svg' alt="arrow" style={{
          width: '16px',
          height: '16px',
          filter: 'invert(49%) sepia(10%) saturate(294%) hue-rotate(178deg) brightness(90%) contrast(88%)'
        }}/>
      </components.DropdownIndicator>
  );
};

interface Buyer {
  id: number;
  label: string;
}

const SMSNotificationSettingsCard: React.FC<WrapperProps> = ({
                                                               user_type,
                                                               cardId,
                                                               isOtp,
                                                               title,
                                                               description,
                                                               users_detail,
                                                               isActive,
                                                               statusOtp,
                                                               countCredit,
                                                               key
                                                             }) => {
  // Hooks
  const {isLoading, addUserSmsSettings} = useAddUserSmsSettings();
  const {removeUserSmsSettings} = useRemoveUserSmsSettings();
  const {setCategoryStatus} = useSetCategoryStatus();
  const {setOtpStatus} = useSetOtpStatus();

  // Modal
  const [showModal, setShowModal] = useState(false);

  // Buyer List
  const [buyerList, setBuyerList] = useState(users_detail || []);
  const [showAll, setShowAll] = useState(false);
  const [hiddenBuyerCount, setHiddenBuyerCount] = useState(0);

  // Refs
  const wrapperRef = useRef<HTMLDivElement>(null);
  const chipRefs = useRef<(HTMLDivElement | null)[]>([]);

  // Buyer Search
  const [buyerOptions, setBuyerOptions] = useState<Buyer[]>([]);
  const [isSearching, setIsSearching] = useState(false);

  // User Search
  const [userOptions, setUserOptions] = useState<Buyer[]>([]);
  const [isSearchingUser, setIsSearchingUser] = useState(false);

  // Buyer Selection
  const [selectedBuyer, setSelectedBuyer] = useState<Buyer | null>(null);

  // Toast Notifications
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success");

  // Checkbox State
  const [isActiveState, setIsActiveState] = useState<boolean>(isActive ?? false);
  const [statusOtpState, setStatusOtpState] = useState<boolean>(statusOtp ?? false);
  const [hasError, setHasError] = useState(false);

  const isBlocked = true;
  const getButtonLabel = () => {
    if (user_type) {
      const capitalizedUserType = user_type.charAt(0).toUpperCase() + user_type.slice(1);
      return `Add ${capitalizedUserType}`;
    } else {
      return 'Add';
    }
  };

  useEffect(() => {
    if (users_detail && users_detail.length > 0) {
      const sortedBuyers = [...users_detail].sort((a, b) => a.name.localeCompare(b.name));
      setBuyerList(sortedBuyers);
    }
  }, [users_detail]);

  useEffect(() => {
    setIsActiveState(isActive ?? false);
  }, [isActive]);

  const handleCheckboxChange = () => {
    if (cardId === undefined) {
      console.error("cardId is undefined");
      return;
    }
    const newStatus = isActiveState ? 0 : 1;
    console.log('cardId', cardId)
    setCategoryStatus(newStatus, cardId);
    setIsActiveState(!isActiveState);
    setToastMessage(` ${title} ${isActiveState ? 'deactivated' : 'activated'} successfully.`);
    setShowToast(true);
  };

  const handleOtpChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    const status = value === "on" ? 1 : 0;
    setStatusOtpState(status === 1 ? true : false);

    if (cardId !== undefined) {
      setOtpStatus(status, cardId);
    } else {
      console.error("cardId is undefined");
    }
  };

  const handleAddUserSmsSettings = useCallback(async () => {
    if (!selectedBuyer) {
      setHasError(true);
      return;
    }

    if (selectedBuyer.id && cardId) {
      try {
        await addUserSmsSettings(cardId, selectedBuyer.id);

        const updatedBuyerList = [...buyerList, { id: selectedBuyer.id, name: selectedBuyer.label }];
        const sortedBuyerList = updatedBuyerList.sort((a, b) => a.name.localeCompare(b.name));

        setBuyerList(sortedBuyerList);
        setToastMessage(`User ${selectedBuyer.label} added successfully.`);
        setShowToast(true);
        setHasError(false);
        setShowModal(false);

        setTimeout(() => {
          updateVisibleBuyers();
        }, 0);
      } catch (error) {
        console.error('Error adding user:', error);
        setToastMessage('Failed to add user. Please try again.');
        setToastVariant('danger');
        setShowToast(true);
      }
    }
  }, [cardId, selectedBuyer, buyerList, addUserSmsSettings]);

  const handleBuyerSelect = (selectedOption: SingleValue<Buyer>) => {
    if (selectedOption) {
      setSelectedBuyer(selectedOption);
      setHasError(false);
    }
  };

  const handleRemoveBuyer = useCallback((buyerId: number) => {
    if (cardId && buyerId) {
      removeUserSmsSettings(cardId, 'buyer', buyerId);
      const updatedBuyerList = buyerList.filter(buyer => buyer.id !== buyerId);
      const buyerName = buyerList.find(buyer => buyer.id === buyerId)?.name;
      setBuyerList(updatedBuyerList);
      setToastMessage(`User ${buyerName} removed successfully.`);
      setShowToast(true);

      updateVisibleBuyers();
    }
  }, [cardId, buyerList, removeUserSmsSettings]);

  const updateVisibleBuyers = useCallback(() => {
    chipRefs.current.forEach(chip => {
      chip?.classList.remove('hidden');
    });

    let hiddenCount = 0;
    let lastVisibleIndex = -1;

    chipRefs.current.forEach((chip, index) => {
      if (chip) {
        const chipRect = chip.getBoundingClientRect();
        const wrapperRect = wrapperRef.current?.getBoundingClientRect();

        if (wrapperRect && chipRect.right > wrapperRect.right) {
          chip.classList.add('hidden');
          hiddenCount++;
        } else {
          lastVisibleIndex = index;
        }
      }
    });

    setHiddenBuyerCount(hiddenCount);

    const showMoreButton = wrapperRef.current?.querySelector('.btn-show') as HTMLElement;
    if (showMoreButton) {
      if (hiddenCount > 0) {
        showMoreButton.style.display = 'block';
      } else {
        showMoreButton.style.display = 'none';
      }
    }

    if (hiddenCount === 0 && lastVisibleIndex !== -1) {
      chipRefs.current[lastVisibleIndex]?.classList.remove('hidden');
    }
  }, [buyerList]);


  const toggleShowAll = () => {
    setShowAll(!showAll);

    if (!showAll) {
      chipRefs.current.forEach(chip => {
        chip?.classList.remove('hidden');
      });
    } else {
      setTimeout(() => {
        updateVisibleBuyers();
      }, 0);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateVisibleBuyers);

    updateVisibleBuyers();

    return () => {
      window.removeEventListener('resize', updateVisibleBuyers);
    };
  }, [buyerList, showAll]);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBuyer(null);
  }

  const renderSelectComponent = () => {
    if (user_type === 'user') {
      return (
          <Select
              options={userOptions}
              placeholder="Select User"
              styles={customStyles}
              components={{ DropdownIndicator }}
              onChange={handleBuyerSelect}
              onInputChange={handleInputChange}
              isLoading={isSearchingUser}
          />
      );
    } else if (user_type === 'buyer') {
      return (
          <Select
              options={buyerOptions}
              placeholder="Select Buyer"
              styles={customStyles}
              components={{ DropdownIndicator }}
              onChange={handleBuyerSelect}
              onInputChange={handleInputChange}
              isLoading={isSearching}
          />
      );
    } else {
      return null;
    }
  };

  const filterOptions = (options: Buyer[], existingUsers: { id: number }[]) => {
    return options.filter(option => !existingUsers.some(user => user.id === option.id));
  };

  const searchBuyers = useCallback(
      debounce(async (inputValue: string) => {
        if (inputValue.length > 2) {
          setIsSearching(true);
          try {
            const data = await smsSettings.searchBuyers(inputValue);
            const options = data.map((buyer: any) => ({
              id: buyer.id,
              label: buyer.name,
            }));
            const filteredOptions = filterOptions(options, buyerList);
            setBuyerOptions(filteredOptions);
          } catch (error) {
            console.error('Error fetching buyers:', error);
          } finally {
            setIsSearching(false);
          }
        } else {
          setBuyerOptions([]);
        }
      }, 500),
      [buyerList]
  );

  const handleInputChange = (inputValue: string) => {
    if (user_type === 'user') {
      searchUsers(inputValue);
    } else if (user_type === 'buyer') {
      searchBuyers(inputValue);
    }
  };

  const searchUsers = useCallback(
      debounce(async (inputValue: string) => {
        if (inputValue.length > 2) {
          setIsSearchingUser(true);
          try {
            const data = await smsSettings.searchUsers(inputValue);
            const options = data.map((user: any) => ({
              id: user.id,
              label: user.name,
            }));
            const filteredOptions = filterOptions(options, buyerList);
            setUserOptions(filteredOptions);
          } catch (error) {
            console.error('Error fetching users:', error);
          } finally {
            setIsSearchingUser(false);
          }
        } else {
          setUserOptions([]);
        }
      }, 500),
      [buyerList]
  );

  return (
      <div>
        <div className="sms-list-component">
          <div className="nav-header-sms">
            <div
                className="nav-links-sms d-flex justify-content-between align-items-center w-md-100 g-0 m-0 text-16">
              <span className="navbar-link">{title}</span>
              <div className="d-flex flex-column">
                <p className="fw-light" style={{fontSize: "14px", margin: 0}}>
                  <TooltipWithLink
                      description="You have been assigned to batch 120824-21. Please login to review."
                      link="https://somyasales.indeliv.com"
                      linkText="https://somyasales.indeliv.com"
                  />
                </p>
              </div>
            </div>
            <div className='wrapperOtp'>
              {isOtp && (
                  <div className="sendOtp">
                    <label className='sendOtp'>Send OTP:</label>
                    <div className="radio-container">
                      <label className='labelOn'>
                        On
                        <input
                            type="radio"
                            name="otpOption"
                            value="on"
                            checked={statusOtpState}
                            onChange={handleOtpChange}
                        />
                        <span
                            className={`custom-radio-on ${statusOtpState ? 'active' : ''}`}></span>
                      </label>
                    </div>
                    <div className="radio-container">
                      <label className='labelOff'>
                        Off
                        <input
                            type="radio"
                            name="otpOption"
                            value="off"
                            checked={!statusOtpState}
                            onChange={handleOtpChange}
                        />
                        <span
                            className={`custom-radio-off ${!statusOtpState ? 'active' : ''}`}></span>
                      </label>
                    </div>
                  </div>
              )}
              {isOtp && (<div className="vertical-bar"></div>)}
              <label className={isBlocked ? 'switch' : 'switch disabled'}>
                <input type="checkbox"
                       checked={isActiveState}
                       onChange={handleCheckboxChange}
                       disabled={isBlocked}
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>
          <div className="buyer-tag ptb">
            <div ref={wrapperRef} className={`wrapper-buyer ${showAll ? 'show-all' : ''}`}>
              <span className="titleExcept">Except</span>
              <button className='btn-add'  onClick={() => setShowModal(true)}>
                <svg
                    style={{
                      width: '15px',
                      height: '15px',
                    }}
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                    fill="none">
                  <path d="M4 8H12" stroke="#667085" strokeWidth="1.5" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  <path d="M8 12V4" stroke="#667085" strokeWidth="1.5" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
                {getButtonLabel()}
              </button>
              {buyerList.length > 0 ? (
                  buyerList.map((buyer, index) => (
                      <div
                          key={buyer.id}
                          data-index={index}
                          className="buyer-tag-item"
                          ref={el => chipRefs.current[index] = el}
                      >
                        {buyer.name}
                        <button className="btn btn-sm btn-link"
                                onClick={() => handleRemoveBuyer(buyer.id)}>
                          <svg viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.62402 1.25L1.12402 7.75M1.12402 1.25L7.62402 7.75"
                                  stroke="#667085"
                                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </button>
                      </div>
                  ))
              ) : null}
              {hiddenBuyerCount > 0 && !showAll && (
                  <>
                    <button className="btn-show" onClick={toggleShowAll}
                            data-index={buyerList.length}>
                      <svg
                          style={{
                            width: '15px',
                            height: '15px',
                          }}
                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                          fill="none">
                        <path d="M4 8H12" stroke="#33AAFF" strokeWidth="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M8 12V4" stroke="#33AAFF" strokeWidth="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                      </svg>
                      {hiddenBuyerCount}
                    </button>
                  </>
              )}
              {showAll && (
                  <div
                      className="btn-show-all"
                      onClick={toggleShowAll}
                      aria-label="Show Less"
                  >
                    <img
                        src="/assets/Icon/arrow-chip.svg"
                        alt="Copy Icon"
                        style={{
                          width: '26px',
                          height: '26px',
                          filter: 'invert(52%) sepia(80%) saturate(4086%) hue-rotate(181deg) brightness(100%) contrast(101%)',
                        }}
                    />
                  </div>
              )}

            </div>
          </div>
        </div>

        <Modal
            show={showModal}
            onHide={handleCloseModal}
            backdrop="static"
            centered
            dialogClassName="custom-modal"
        >
          <div className="modal-content" style={{borderRadius: '12px'}}>
            <Modal.Header
                style={{
                  padding: "25px 20px 20px 20px",
                  borderBottom: "none",
                }}
                closeButton
            >
              <div
                  style={{
                    fontSize: "14px",
                    color: "#667085",
                  }}
              >
                Batch assigned to Delivery Associate
              </div>
            </Modal.Header>
            <Modal.Body style={{paddingTop: 0}}>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  {renderSelectComponent()}
                </Form.Group>
              </Form>
            </Modal.Body>
            <div className="modal-footer-sms">
              {hasError && <div style={{
                color: 'red',
                fontSize: '14px',
                marginBottom: '10px',
                marginRight: 'auto',
                paddingTop: '10px',
              }}>Please select a buyer</div>}
              <button
                  className='modal-footer-sms-button'
                  onClick={handleAddUserSmsSettings}
              >
                {isLoading ? (
                    <div className="loading-row newLoaderAnimation">
                      <NewLoader cols={5}/>
                    </div>
                ) : (
                    <>
                      Save
                    </>
                )}
              </button>
            </div>
          </div>
        </Modal>
        <ToastContainer position="bottom-center" className="p-3">
          <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={3000}
              autohide
              bg={toastVariant}
              className="text-white animate__animated animate__fadeInDown toast-centered"
          >
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </ToastContainer>

      </div>
  );
};

export default SMSNotificationSettingsCard;
*/
